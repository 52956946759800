import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap-icons/font/bootstrap-icons.css';

type Event = {
    id: number;
    event_name: string;
    creation_date: string;
    weekday: string;
    time: string;
    ladder_id: number;
    ladder_name: string;
    activity: string;
    gametype: string;
    ladder_image: string;
    address_name: string;
    address_line_1: string;
    address_line_2: string | null;
    city: string;
    state: string;
    zip_code: number;
    latitude: number;
    longitude: number;
    distance: number;
    reoccurring: boolean;
    owner: number;
    user_count: number;
    nextDate?: string;
    nextDay?: string;
};

type DiscoverSlidesEventsProps = {
    eventsData: Event[] | null;
};

const DiscoverSlidesEvents: React.FC<DiscoverSlidesEventsProps> = ({ eventsData }) => {
    const [visibleEvents, setVisibleEvents] = useState<Event[]>([]);
    const [lastLoadedIndex, setLastLoadedIndex] = useState(0);
    const [dragging, setDragging] = useState(false);
    const [firstDrag, setFirstDrag] = useState(false);

    useEffect(() => {
        if (eventsData) {
            loadMoreEvents(0); // Initial load
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventsData]);

    const loadMoreEvents = (startIndex: number) => {
        const newIndex = startIndex + 25; // Should be 5 but this messes with carousel. Future fix.
        if (eventsData) {
            let processedEvents = eventsData.map(event => ({
                ...event,
                nextDate: getDisplayDate(event.weekday, event.creation_date, event.reoccurring),
                nextDay: getDisplayDay(event.weekday, event.creation_date, event.reoccurring)
            }));

            // Function to parse a date and time string into a Date object
            const parseDateTime = (dateStr: string, timeStr: string): Date => {
                const [hours, minutes] = timeStr.split(':').map(Number);
                const [month, day, year] = dateStr.split('/').map(Number);
                return new Date(year, month - 1, day, hours, minutes);
            };

            // Sort processedEvents by nextDate and time
            processedEvents = processedEvents.sort((a, b) => {
                const dateTimeA = parseDateTime(a.nextDate!, a.time);
                const dateTimeB = parseDateTime(b.nextDate!, b.time);
                return dateTimeA.getTime() - dateTimeB.getTime();
            });

            setVisibleEvents(processedEvents.slice(0, newIndex));
            setLastLoadedIndex(newIndex);
        }
    };

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        beforeChange: () => setDragging(true),
        afterChange: (currentSlide: number) => {
            setFirstDrag(true);
            setDragging(false);
            if (currentSlide + 4 >= lastLoadedIndex && lastLoadedIndex < (eventsData ? eventsData.length : 0)) {
                loadMoreEvents(lastLoadedIndex); // Load more items as needed
            }
        },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    // Convert to more friendly time hh:mm:ss -> hh:mm am/pm
    function convertToUserFriendlyTime(timeStr: string): string {
        // Parse hours, minutes, and seconds from the input string
        const [hours, minutes] = timeStr.split(':').map(Number);

        // Convert to 12-hour format and determine AM/PM
        const isPM = hours >= 12;
        const convertedHours = hours % 12 || 12; // To handle 00:xx:xx and 12:xx:xx

        // Construct the user-friendly time string
        return `${convertedHours}:${minutes.toString().padStart(2, '0')} ${isPM ? 'pm' : 'am'}`;
    }

    function getDisplayDate(weekday: string, creationDateStr: string, reoccurring: boolean): string {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Reset time to start of the day

        const nextWeek = new Date(today);
        nextWeek.setDate(nextWeek.getDate() + 6);

        creationDateStr = creationDateStr + 'T00:00:00'; // Append time component
        const creationDate = new Date(creationDateStr);
        creationDate.setHours(0, 0, 0, 0); // Reset time to start of the day

        // Format a date object into mm/dd/yyyy
        const formatDate = (date: Date): string => {
            const mm = (date.getMonth() + 1).toString();
            const dd = date.getDate().toString();
            const yyyy = date.getFullYear();
            return `${mm}/${dd}/${yyyy}`;
        };

        if (!reoccurring || creationDate.getTime() > nextWeek.getTime()) {
            // If not recurring or the date is more than a week out, return the creation date
            return formatDate(creationDate);
        }

        // If reoccurring and less than 1 week out
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const targetDayIndex = daysOfWeek.indexOf(weekday);
        const currentDayIndex = today.getDay();

        if (targetDayIndex === currentDayIndex) {
            return formatDate(today);
        } else {
            const daysToAdd = (targetDayIndex - currentDayIndex + 7) % 7;
            const targetDate = new Date(today);
            targetDate.setDate(today.getDate() + daysToAdd);
            return formatDate(targetDate);
        }
    }


    function getDisplayDay(weekday: string, creationDateStr: string, reoccurring: boolean): string {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Reset time to start of the day

        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);

        creationDateStr = creationDateStr + 'T00:00:00'; // Append time component
        const creationDate = new Date(creationDateStr);
        creationDate.setHours(0, 0, 0, 0); // Reset time to start of the day

        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        const nextWeek = new Date(today);
        nextWeek.setDate(nextWeek.getDate() + 6);

        if (creationDate.getTime() > nextWeek.getTime()) {
            reoccurring = false;
        }

        if (!reoccurring) {
            if (creationDate.getTime() === today.getTime()) {
                return "Today";
            } else if (creationDate.getTime() === tomorrow.getTime()) {
                return "Tomorrow";
            }
            // Return the weekday if the event is not today or tomorrow
            return weekday;
        }

        // For reoccurring events, return Today or Tomorrow if applicable, otherwise return the weekday
        const currentDay = daysOfWeek[today.getDay()];
        if (weekday === currentDay) {
            return "Today";
        } else if (daysOfWeek[(today.getDay() + 1) % 7] === weekday) {
            return "Tomorrow";
        }

        return weekday;
    }

    return (
        <div className="slider-container">
            <Slider {...settings} key={visibleEvents.length}>
                {visibleEvents.map((eventItem, index) => (
                    <div className="slide" key={index}>
                        <div className="card shadow-sm">
                            <Link to={`/event-page/${eventItem.id}`}
                                  onClick={(e) => {
                                      if (!firstDrag) setDragging(false);
                                      if (dragging) e.preventDefault();
                                  }}
                                  className={"custom-link"}>
                                <img
                                    src={eventItem.ladder_image}
                                    alt={`${eventItem.event_name} thumbnail`}
                                    className="slider-image-discover"
                                />
                                <div className="ladder-info">
                                    <h4>{eventItem.event_name}</h4>
                                    <p>{eventItem.nextDay}, {eventItem.nextDate}</p>
                                    <p>{convertToUserFriendlyTime(eventItem.time)}</p>
                                    <p>Activity: {eventItem.activity}</p>
                                    <p>Location: {eventItem.city}, {eventItem.state}</p>
                                    <p>Distance: {Math.round(eventItem.distance)}
                                        {Math.round(eventItem.distance) === 1 ? ' mile' : ' miles'}</p>
                                    <p>Going: {eventItem.user_count}</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default DiscoverSlidesEvents;
