import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap-icons/font/bootstrap-icons.css';

type Activity = {
    id: number;
    activity: string;
};

type Ladder = {
    id: number;
    name: string;
    activity: Activity;
    ladder_image: string;
    address_name: string;
    address_line_1: string;
    address_line_2: string | null;
    city: string;
    state: string;
    zip_code: number;
    latitude: number;
    longitude: number;
    distance: number;
    owner: number;
    user_count: number;
};

type DiscoverSlidesProps = {
    data: Ladder[] | null;
};

const DiscoverSlides: React.FC<DiscoverSlidesProps> = ({ data }) => {
    const [visibleLadders, setVisibleLadders] = useState<Ladder[]>([]);
    const [lastLoadedIndex, setLastLoadedIndex] = useState(0);
    const [dragging, setDragging] = useState(false);
    const [firstDrag, setFirstDrag] = useState(false);

    useEffect(() => {
        if (data) {
            loadMoreLadders(0); // Initial load
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const loadMoreLadders = (startIndex: number) => {
        const newIndex = startIndex + 25; // Should be 5 but this messes with carousel. Future fix.
        if (data) {
            setVisibleLadders(data.slice(0, newIndex));
            setLastLoadedIndex(newIndex);
        }
    };

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        beforeChange: () => setDragging(true),
        afterChange: (currentSlide: number) => {
            setFirstDrag(true);
            setDragging(false);
            if (currentSlide + 4 >= lastLoadedIndex && lastLoadedIndex < (data ? data.length : 0)) {
                loadMoreLadders(lastLoadedIndex); // Load more items as needed
            }
        },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>, id: number) => {
        if (!firstDrag) setDragging(false);
        if (dragging) {
            event.preventDefault();
        }
    };

    return (
        <div className="slider-container">
            <Slider {...settings} key={visibleLadders.length}>
                {visibleLadders.map((ladderItem, index) => (
                    <div className="slide" key={index}>
                        <div className="card shadow-sm">
                            <Link to={`/ladder-page/${ladderItem.id}`}
                                  onClick={(e) => handleClick(e, ladderItem.id)}
                                  className={"custom-link"}>
                                <img
                                    src={ladderItem.ladder_image}
                                    alt={`${ladderItem.name} thumbnail`}
                                    className="slider-image-discover"
                                />
                                <div className="ladder-info">
                                    <h4>{ladderItem.name}</h4>
                                    <p>Activity: {ladderItem.activity.activity}</p>
                                    <p>Members: {ladderItem.user_count}</p>
                                    <p>Location: {ladderItem.city}, {ladderItem.state}</p>
                                    <p>Distance: {Math.round(ladderItem.distance)} miles</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default DiscoverSlides;
