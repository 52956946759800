import React, { useState, useEffect, SyntheticEvent } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from "./UserContext";

// Define the types for the ratings and descriptions
type ActivityType = "Tennis" | "Pickleball";

const ratings: { [key in ActivityType]: string[] } = {
    Pickleball: [
        "I don't know my rating",
        "2.0",
        "2.5",
        "3.0",
        "3.5",
        "4.0",
        "4.5",
        "5.0",
        "5.5"
    ],
    Tennis: [
        "I don't know my rating",
        "1.0",
        "1.5",
        "2.0",
        "2.5",
        "3.0",
        "3.5",
        "4.0",
        "4.5",
        "5.0",
        "5.5",
        "6.0-7.0"
    ]
};

const descriptions: { [key in ActivityType]: { [key: string]: string } } = {
    Pickleball: {
        "2.0": "This player is just starting to play pickleball and has no other sports background. Minimal understanding of rules of the game.",
        "2.5": "This player has limited experience. Can sustain a short rally with players of equal ability. Basic ability to keep score.",
        "3.0": `FOREHAND:\nAbility to hit a medium paced shot. Lacks directional intent and consistency.\n\nBACKHAND:\nAvoids using a backhand. Lacks directional intent and consistency.\n\nSERVE / RETURN:\nAble to hit a medium paced shot. Lacks depth, direction, and consistency.\n\nDINK:\nNot able to consistently sustain a dink rally. Not yet developed the ability to control this shot.\n\n3RD SHOT:\nGenerally hits a medium paced ball with little direction.\n\nVOLLEY:\nAble to hit a medium paced shot. Lacks direction/inconsistent.\n\nSTRATEGY:\nUnderstands fundamentals. Is learning proper court positioning. Knows the fundamental rules and can keep score and is now playing tournaments.`,
        "3.5": `FOREHAND:\nImproved stroke development with moderate level of shot control.\n\nBACKHAND:\nLearning stroke form and starting to develop consistency but will avoid if possible.\n\nSERVE / RETURN:\nConsistently gets serve/return in play with limited ability to control depth.\n\nDINK:\nIncreased consistency, with limited ability to control height/depth. Sustains medium length rallies. Starting to understand variations of pace.\n\n3RD SHOT:\nDeveloping the drop shot in a way to get to the net.\n\nVOLLEY:\nIs able to volley medium paced shots thereby developing control.\n\nSTRATEGY:\nMoves quickly towards the non-volley zone (NVZ) when opportunity is there. Acknowledges difference between hard game and soft game and is starting to vary own game during recreation and tournament play. Can sustain short rallies. Is learning proper court positioning. Basic knowledge of stacking and understands situations where it can be effective.`,
        "4.0": `FOREHAND:\nConsistently hits with depth and control. Is still perfecting shot selection and timing.\n\nBACKHAND:\nHas improved stroke mechanics and has moderate success at hitting a backhand consistently.\n\nSERVE / RETURN:\nPlaces a high majority of serves/returns with varying depth and speed.\n\nDINK:\nIncreased consistency with moderate ability to control height/depth. May end dink rally too soon due to lack of patience. Is beginning to understand difference between attackable balls and those that are not.\n\n3RD SHOT:\nSelectively mixing up soft shots with power shots to create an advantage with inconsistent results.\n\nVOLLEY:\nAble to volley a variety of shots at different speeds. Is developing consistency and control. Starting to understand the block/re-set volley.\n\nSTRATEGY:\nAware of partner�s position on the court and is able to move as a team. Demonstrates ability to change direction in an offensive manner. Demonstrates a broad knowledge of the rules of the game. Has a moderate number of unforced errors per game. Solid understanding of stacking and when and how it could be used in match play. Beginning to identify opponents weaknesses and attempts to formulate game plan to attack weaknesses. Beginning to seek out more competitive play.`,
        "4.5": `FOREHAND:\nHigh level of consistency. Uses pace and depth to generate opponents' error or set up next shot.\n\nBACKHAND:\nCan effectively direct the ball with varying depth and paces with good consistency.\n\nSERVE / RETURN:\nServes with power, accuracy, and depth and can also vary the speed and spin of the serve.\n\nDINK:\nAbility to place ball with high success at changing shot types while playing both consistently and with offensive intent. Recognizes and attempts to hit attackable dinks.\n\n3RD SHOT:\nConsistently executes effective 3rd shot strategies that are not easily returned for advantage. Able to intentionally and consistently place the ball.\n\nVOLLEY:\nAble to block hard volleys directed at them and can consistently drop them into the NVZ. Comfortable hitting swinging volleys. Hits overhead shots consistently, often as putaways.\n\nSTRATEGY:\nHas good footwork and moves laterally, backward, and forward well. Uses weight transfer for more efficient footwork. Able to change direction with ease. Very comfortable playing at the non-volley zone. Communicates and moves well with partner � easily �stacks" court positions. Understands strategy and can adjust style of play and game plan according to the opponent�s strengths and weaknesses and court position. Limited number of unforced errors.`,
        "5.0": `FOREHAND | BACKHAND | SERVE / RETURN:\nHits all shot types at a high level of ability from both the forehand and backhand sides including: touch, spin, and pace with control to set up offensive situations. Has developed good touch from all court positions. Has developed a very high level of variety, depth, and pace of serves.\n\nDINK:\nMastered the dink and drop shots. Ability to move opponents with shot placements. Exhibits patience during rallies with the ability to create an opportunity to attack utilizing the dink. Increased ability to change the pace of dinks strategically.\n\n3RD SHOT:\nMastered the 3rd shot choices and strategies to create opportunities for winning points. Able to drop and drive ball from both the forehand and backhand side with high level of consistency.\n\nVOLLEY:\nAble to block hard volleys directed at them and consistently drop them into the NVZ. Places overheads with ease for winners. Able to volley shots toward opponents feet consistently. Comfortable with swinging volley in both initiating and ability to attack back or neutralize return.\n\nSTRATEGY:\nMastered pickleball strategies and can vary strategies and styles of play in competitive or tournament matches. Is successful at turning defensive shots into offensive shots. Has efficient footwork and effective use of weight transfer for improved quickness on the court. Easily and quickly adjusts style of play and game plan according to the opponent�s strengths and weaknesses and court position. Rarely makes unforced errors.`,
        "5.5": "This player is a top caliber player. Performance and tournament wins speak for this player's ability to consistently perform at a high level."
    },
    Tennis: {
        "1.0": "This player is just starting to play tennis.",
        "1.5": "This player has had limited experience with stroke development and is still working primarily on getting the ball into play. This player is not yet ready to compete.",
        "2.0": "This player needs on-court experience, with an emphasis on play. This player struggles to find an appropriate contact point, needs stroke development/lessons and is not yet familiar with basic positions for singles and doubles.",
        "2.5": `This player is learning to judge where the oncoming ball is going and how much swing is needed to return it consistently. Movement to the ball and recovery are often not efficient. Can sustain a backcourt rally of slow pace with other players of similar ability and is beginning to develop strokes. This player is becoming more familiar with the basic positions for singles and doubles, and is ready to play social matches, leagues and low-level tournaments.\nPotential limitations: grip weaknesses; limited swing and inconsistent toss on serve; limited transitions to the net.`,
        "3.0": `This player is fairly consistent when hitting medium-paced shots, but is not comfortable with all strokes and lacks accuracy when trying for directional control, depth, pace or altering distance of shots. Most common doubles formation is one up, one back.\nPotential limitations: inconsistency when applying or handling pace; difficulty handling shots outside of their strike zone; can be uncomfortable at the net.`,
        "3.5": `This player has achieved stroke dependability with directional control on moderate shots, but still lacks depth, variety and the ability to alter distance of shots. The effective use of lobs, Overheads, approach shots, and volleys is limited. This player is more comfortable at the net, has improved court awareness, and is developing teamwork in doubles.\nPotential strengths: Players can generally rally from the baseline r opposite a net player. Players at this level may start to utilize mental skills related to concentration, tactics and strategy.`,
        "4.0": `This player has dependable strokes with directional control and the ability to alter depth of shots on both forehand and backhand sides during moderately paced play. This player also has the ability to use lobs, overheads, approach shots, and volleys with success. This player occasionally forces errors when serving. Points may be lost due to impatience. Teamwork in doubles is evident.\nPotential strengths: dependable second serve; recognizes opportunities to finish points.`,
        "4.5": `This player can vary the use of pace and spins, has effective court coverage, can control depth of shots, and is able to develop game plans according to strengths and weaknesses. This player can hit the first serve with power and accuracy and can place the second serve. This player tends to Overhit on difficult shots. Aggressive net play is common in doubles.\nPotential strengths: points are frequently won off the serve or return of serve; able to offset weaknesses; may have a weapon around which their game can be built.`,
        "5.0": `This player has good shot anticipation and frequently has an outstanding shot or attribute around which his or her game can be structured. This player can regularly hit winners or force errors off of short balls and puts away volleys. He or she can successfully execute lobs, drop shots, half volleys, overheads, and has good depth and spin on most second serves.\nPotential strengths: covers and disguises weaknesses well; can hit offensive volleys and half-volleys from mid-court; can employ physical or mental fitness as a weapon.`,
        "5.5": `This player has developed pace and/or consistency as a major weapon. This player can vary strategies and styles of play in competitive situations and hit dependable shots in stress situations.\nStrengths: can hit offensively at any time; can vary strategies and styles of play in competitive situations; first and second serves can be depended upon in stress situations.`,
        "6.0-7.0": "The 6.0 player typically has had intensive training for national tournaments or top level collegiate competition, and has obtained a national ranking."
    }
};

export const SelfRate: React.FC = () => {
    const params = useParams<{ activity: ActivityType, ladderId: string }>();
    const activity = params.activity || "Tennis"; // Provide a default value or handle undefined separately
    const ladderId = params.ladderId
    const [selectedRating, setSelectedRating] = useState<string | null>("I don't know my rating");
    const [description, setDescription] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [success, setSuccess] = useState<string>('');
    const auth = useSelector((state: RootState) => state.auth.value);
    const user = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        if (selectedRating && selectedRating !== "I don't know my rating") {
            setDescription(descriptions[activity][selectedRating]);
        } else {
            setDescription('');
        }
    }, [selectedRating, activity]);

    const handleRatingChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedRating(e.target.value);
    };

    const submitRating = async (e: SyntheticEvent) => {
        e.preventDefault();
        if (!selectedRating) {
            setError("Please select a valid rating.");
            return;
        }

        try {
            const response = await axios.post('self-rate', {
                sport: activity.toLowerCase(),
                rating: selectedRating === "I don't know my rating" ? "2.0" : selectedRating
            });

            if (response.status === 200) {
                // Fill in the user context so that the value is not null, hence not allowing player to resubmit ranking
                if (user && activity === 'Tennis') {
                    user.tennis_ranking = Number(selectedRating)
                } else if (user && activity === 'Pickleball') {
                    user.pickleball_ranking = Number(selectedRating)
                }

                await handleJoinClick()
                setSuccess("Successfully joined the ladder");
                setError('');
            } else {
                setError("Failed to submit rating.");
                setSuccess('');
            }
        } catch (error) {
            setError("An error occurred while submitting the rating.");
            setSuccess('');
        }
    };

    const handleJoinClick = async () => {
        try {
            if (!user) {
                return
            }
            const response = await axios.post('add-user-to-ladder', {
                user_id: user.id,
                ladder_id: Number(ladderId)
            });

            if (response.status === 200) {
                // Call the API to recalculate the rank
                await axios.patch(`recalculate-rank/${ladderId}`);
                navigate(-1);
                return
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <div className="self-rate-container">
            {auth && (
                <main className="form-signin form-signin-width">
                    <form onSubmit={submitRating}>
                        <h4>What is your {activity} rating?</h4>
                        <div className="form-floating">
                            <select
                                id="rating"
                                className="form-select"
                                value={selectedRating || ""}
                                onChange={handleRatingChange}
                            >
                                {ratings[activity].map((rating: string) => (
                                    <option key={rating} value={rating}>
                                        {rating}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {error && <div className="alert alert-danger">{error}</div>}
                        {success && <div className="alert alert-success">{success}</div>}
                        <button type="submit" className="btn btn-primary self-rate-button">
                            Submit
                        </button>
                        {description && (
                            <div className="form-floating">
                                <pre style={{ whiteSpace: "pre-wrap" }}>{description}</pre>
                            </div>
                        )}
                    </form>
                </main>
            )}
        </div>
    );
};
