import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { UpdateEventInfo } from "./UpdateEventInfo";
import { DeleteEvent } from "./DeleteEvent";

export const UpdateEvent = () => {
    const navigate = useNavigate();

    // Function to navigate back
    const handleBackClick = () => {
        navigate(-1);
    };
    return (
        <div>
            <button onClick={handleBackClick} className="back-to-ladder-button">
                <FontAwesomeIcon icon={faArrowLeft} />
                <span>Back to Event</span>
            </button>
            <div className={''}>
                <UpdateEventInfo />
            </div>
            <div className={'update-event-container'}>
                <DeleteEvent />
            </div>
        </div>
    );
}
