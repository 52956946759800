import React, {useEffect} from 'react';

let map: google.maps.Map;

type Activity = {
    id: number;
    activity: string;
};

type Ladder = {
    id: number;
    name: string;
    activity: Activity;
    ladder_image: string;
    address_name: string;
    address_line_1: string;
    address_line_2: string | null;
    city: string;
    state: string;
    zip_code: number;
    latitude: number;
    longitude: number;
    distance: number;
    owner: number;
    user_count: number;
};

type Event = Ladder & {
    event_name: string;
    creation_date: string;
    weekday: string;
    time: string;
    reoccurring: boolean;
};

type Data = {
    data: (Ladder | Event)[] | null;
};

function onClick() {
    //links to Ladder page or event page
    console.log('Marker clicked')
}

async function initMap(position: { lat: number, lng: number }, data: Data): Promise<void> {
    const {Map} = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    const {AdvancedMarkerElement} = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

    // Initialize the map if it doesn't exist
    map = new Map(document.getElementById("map") as HTMLElement, {
        center: position,
        zoom: 8,
        mapId: 'DiscoverMap'
    });
    
    if (data) {
        // @ts-ignore
        data.forEach((item: Ladder | Event) => {

            const marker = new AdvancedMarkerElement({
                map,
                position: { lat: item.latitude, lng: item.longitude },
                title: item.name,
            });

            marker.addListener('click', onClick)
            
        });
    }


}

const MapComponent: React.FC<{ position: { lat: number, lng: number }, data: Data }> = ({position, data}) => {
    useEffect(() => {
        initMap(position, data);

    }, [position, data]);

    return (
        <div id="map" style={{height: '100%', width: '100%'}}>
            {/* This div is the container for the Google Map */}
        </div>
    );
};

export default MapComponent;
