import React, {SyntheticEvent, useEffect, useState} from "react";
import axios from "axios";
import {Navigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../redux/store";

const weekdayMap: { [key: string]: number } = {
    "Monday": 1,
    "Tuesday": 2,
    "Wednesday": 3,
    "Thursday": 4,
    "Friday": 5,
    "Saturday": 6,
    "Sunday": 7
};

export const UpdateEventInfo = () => {
    const [eventName, setEventName] = useState('');
    const [eventDate, setEventDate] = useState('');
    const [time, setTime] = useState<{ id: number; time: string }[]>([]);
    const [selectedTime, setSelectedTime] = useState<number | null>(null);
    const {eventId} = useParams();
    const [isRecurring, setIsRecurring] = useState(true);
    const [redirect, setRedirect] = useState(false);
    const [error, setError] = useState('');
    const auth = useSelector((state: RootState) => state.auth.value);
    const [weekday, setWeekday] = useState('');
    const [weekdayId, setWeekdayId] = useState<number | null>(null);
    const [maxParticipants, setMaxParticipants] = useState('');
    const [numHoursLockEvent, setNumHoursLockEvent] = useState('');
    const [courtFields, setCourtFields] = useState<{ id: string; value: string }[]>([]);

    // Get time data
    useEffect(() => {
        // Fetch time from the API
        axios.get('ladder-times')
            .then((response) => {
                setTime(response.data);
            })
            .catch((error) => {
                console.error("Error fetching time:", error);
            });
    }, []); // Run this effect once when the component mounts

    // Event Data
    useEffect(() => {
        const fetchEventData = async () => {
            if (eventId) {
                try {
                    const response = await axios.post(`retrieve-event`, {
                        event_id: eventId
                    });
                    if (response.status === 200) {
                        const {
                            event_name,
                            creation_date,
                            time: eventTime,
                            max_num_users,
                            num_hours_lock_event,
                            reoccurring,
                            court1, court2, court3, court4, court5,
                            court6, court7, court8, court9, court10
                        } = response.data;

                        setEventName(event_name);
                        setEventDate(creation_date);
                        updateWeekday(creation_date); // Assuming creation_date is the same format as eventDate
                        setMaxParticipants(max_num_users.toString());
                        setNumHoursLockEvent(num_hours_lock_event ? num_hours_lock_event.toString() : '');
                        setIsRecurring(reoccurring);

                        // Match the time id based on eventTime
                        const matchingTime = time.find(t => t.time === eventTime);
                        setSelectedTime(matchingTime ? matchingTime.id : null); // Use the id if found

                        const courts = [
                            {id: 'court1', value: court1 || ''},
                            {id: 'court2', value: court2 || ''},
                            {id: 'court3', value: court3 || ''},
                            {id: 'court4', value: court4 || ''},
                            {id: 'court5', value: court5 || ''},
                            {id: 'court6', value: court6 || ''},
                            {id: 'court7', value: court7 || ''},
                            {id: 'court8', value: court8 || ''},
                            {id: 'court9', value: court9 || ''},
                            {id: 'court10', value: court10 || ''}
                        ].filter(court => court.value);
                        setCourtFields(courts);
                    }
                } catch (error) {
                    console.error("Error fetching event data:", error);
                }
            }
        };

        // Now invoke the async function
        fetchEventData().then(() => {
            // Whatever you'd like to execute after fetchEventData completes
            console.log("Event data fetched successfully!");
        });

        // No return here unless you have a cleanup function.
    }, [eventId, time]);

    const handleTimeChange = (e: any) => {
        const selectedValue = e.target.value;
        setSelectedTime(selectedValue);
    };

    const handleDateChange = (e: any) => {
        const newDate = e.target.value;
        setEventDate(newDate);
        updateWeekday(newDate);
    };

    const updateWeekday = (dateString: string) => {
        if (dateString) {
            // Split the date string into its components
            const dateParts = dateString.split('-');
            if (dateParts.length === 3) {
                const year = parseInt(dateParts[0], 10);
                const month = parseInt(dateParts[1], 10) - 1; // Month is 0-indexed
                const day = parseInt(dateParts[2], 10);

                // Create a new Date object
                const date = new Date(year, month, day);

                // Get the string representation of the day of the week
                const dayOfWeek = date.toLocaleDateString('en-US', {weekday: 'long'});
                setWeekday(dayOfWeek);

                // Set the numeric ID for the day of the week
                const dayId = weekdayMap[dayOfWeek];
                setWeekdayId(dayId);
            } else {
                setWeekday('');
                setWeekdayId(null);
            }
        } else {
            setWeekday('');
            setWeekdayId(null);
        }
    };

    const addCourtField = () => {
        if (courtFields.length < 10) {
            const newCourtId = `court${courtFields.length + 1}`;
            setCourtFields([...courtFields, {id: newCourtId, value: ''}]);
        }
    };

    const removeCourtField = (index: number) => {
        setCourtFields(courtFields.filter((_, i) => i !== index));
    };

    // Submit button
    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        // Check event name
        if (!eventName) {
            setError('Please enter an event name.');
            return;
        }

        // Check time
        if (!selectedTime) {
            setError('Please select a time.');
            return;
        }

        if (!eventDate) {
            setError('Please select an event start date.');
            return;
        }

        // New validation for maxParticipants
        const maxParticipantsNumber = parseInt(maxParticipants, 10);
        if (!maxParticipants || isNaN(maxParticipantsNumber) || maxParticipantsNumber <= 0) {
            setError('Please enter a valid max number of participants (greater than 0).');
            return;
        }

        // New validation for numHoursLockEvent
        let lockEventHours: number | null = parseInt(numHoursLockEvent, 10);

        if (lockEventHours === 0) {
            lockEventHours = null; // Convert 0 to null
        } else if (lockEventHours < 0) {
            setError('Number of hours to lock the event must be greater than or equal to 0.');
            return;
        }

        if (eventId === undefined || selectedTime === null) {
            setError('All fields must be filled.');
            return;
        }

        const eventPayload: any = {
            event_name: eventName,
            creation_date: eventDate,
            weekday: weekdayId,
            time: Number(selectedTime),
            max_num_users: Number(maxParticipants),
            num_hours_lock_event: lockEventHours,
            reoccurring: isRecurring ? 1 : 0
        };

        courtFields.forEach((court) => {
            eventPayload[court.id] = court.value;
        });

        try {
            const response = await axios.put(`update-event/${eventId}`, eventPayload);

            if (response.status === 200) {
                setRedirect(true);
            } else {
                setError('Event name already in use');
            }
        } catch (error) {
            setError('There was an error while updating the event.');
        }
    }

    // Convert from military time to more friendly time hh:mm am/pm
    const convertTo12HrFormat = (time24: any) => {
        const [hours, minutes] = time24.split(':');
        const hrs = parseInt(hours, 10);
        const suffix = hrs >= 12 ? 'PM' : 'AM';
        const adjustedHrs = hrs > 12 ? hrs - 12 : hrs === 0 ? 12 : hrs;

        return `${adjustedHrs.toString().padStart(2, '0')}:${minutes} ${suffix}`;
    };

    if (redirect) {
        return <Navigate to={`/event-page/${eventId}`}/>
    }

    return <div>
        {auth && (
            <div className="">
                <main className="form-signin form-signin-width">
                    <form onSubmit={submit}>
                        <h1 className="h3 mb-3 fw-normal">Update your event</h1>

                        <div className="form-floating">
                            <input className="form-control"
                                   id="floatingLadderName"
                                   value={eventName}
                                   onChange={e => setEventName(e.target.value)}
                            />
                            <label htmlFor="floatingLadderName">Event Name</label>
                        </div>

                        <div className="form-floating">
                            <input
                                type="date"
                                className="form-control"
                                id="floatingDate"
                                placeholder="Event Date"
                                value={eventDate}
                                onChange={handleDateChange}
                            />
                            <label htmlFor="floatingDate">Start Date</label>
                        </div>

                        <div className="form-floating">
                            <select
                                className="form-select"
                                id="floatingTime"
                                value={selectedTime || ""}
                                onChange={handleTimeChange}
                            >
                                <option value="" disabled>Select a Time</option>
                                {time.map((timeItem) => (
                                    <option key={timeItem.id} value={timeItem.id}>
                                        {convertTo12HrFormat(timeItem.time)}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="form-floating">
                            <input
                                type="number"
                                className="form-control"
                                id="maxParticipants"
                                placeholder="Max Number of Participants"
                                value={maxParticipants}
                                onChange={e => setMaxParticipants(e.target.value)}
                            />
                            <label htmlFor="maxParticipants">Max Number of Participants</label>
                        </div>

                        <div className="form-floating">
                            <input
                                type="number"
                                className="form-control"
                                id="numHoursLockEvent"
                                placeholder="Lock event _ hours before it starts"
                                value={numHoursLockEvent}
                                onChange={e => setNumHoursLockEvent(e.target.value)}
                            />
                            <label htmlFor="numHoursLockEvent">
                                Lock event _ hours before it starts
                            </label>
                        </div>

                        <div className="form-check mb-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="recurringCheckbox"
                                checked={isRecurring}
                                onChange={e => setIsRecurring(e.target.checked)}
                            />
                            <label className="form-check-label" htmlFor="recurringCheckbox">
                                Recurring
                            </label>
                            {isRecurring && weekday && selectedTime && (
                                <div className="recurring-info">
                                    Recurs every {weekday}
                                </div>
                            )}
                        </div>

                        <div>
                            {courtFields.map((court, index) => (
                                <div key={court.id} style={{marginBottom: '20px'}}>
                                    <div className="form-floating mb-2">
                                        <input
                                            className="form-control"
                                            id={`floatingCourtName${index}`}
                                            value={court.value}
                                            name={court.id}
                                            onChange={e => {
                                                const updatedCourts = [...courtFields];
                                                updatedCourts[index].value = e.target.value;
                                                setCourtFields(updatedCourts);
                                            }}
                                        />
                                        <label htmlFor={`floatingCourtName${index}`}>Court</label>
                                    </div>

                                    <div className={'register-event-button-container'}>
                                        <button
                                            type="button"
                                            onClick={() => removeCourtField(index)}
                                            className={'remove-court-plus-button btn btn-danger'}
                                        >
                                            -
                                        </button>
                                        {index === courtFields.length - 1 && courtFields.length < 10 && (
                                            <button
                                                type="button"
                                                onClick={addCourtField}
                                                className={'add-court-plus-button btn btn-success'}
                                            >
                                                +
                                            </button>
                                        )}
                                    </div>
                                </div>
                            ))}
                            {courtFields.length === 0 && (
                                <button type="button" onClick={addCourtField}
                                        className={'add-court-button'}>
                                    Assign Court
                                </button>
                            )}
                        </div>

                        {error && <div className="alert alert-danger mb-3">{error}</div>}

                        <button className="registerButton btn btn-primary w-100 py-2" type="submit">Update Event
                        </button>
                    </form>
                </main>
            </div>
        )}
    </div>
}
