
import {useSelector} from "react-redux";
import {RootState} from "../redux/store";
import Discover from "./Discover";
import DiscoverLaddersLanding from "./DiscoverLaddersLanding";
import { Link } from "react-router-dom";
import { useUser } from "./UserContext";

export const Home = () => {
    const auth = useSelector((state: RootState) => state.auth.value);
    useUser();  // Sets auth and dispatch

    return <div>
        { !auth &&
            (
                <div className="landing-page">
                    <div className="landing-page-header">
                        <div className={'landing-page-left-container'}>
                            <div className={'landing-page-image'}/>
                        </ div>
                        <div className={'landing-page-right-container'}>
                            <div className="landing-page-content landing-page-image-mobile">
                                <h1 className="landing-page-title">Welcome to LadderUpSports</h1>
                                <p className="landing-page-description">
                                    Where competition meets friendship
                                </p>
                                <Link to={`/register`} className={"cta-button"}>Join Today</Link>
                            </div>
                        </div>
                    </div>
                    <DiscoverLaddersLanding/>
                </div>
            )
        }
        {auth &&
            <Discover/>
        }
    </div>
}