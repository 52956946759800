import React, { useState, FormEvent } from 'react';
import axios from "axios";

// Define a type for the form state
type ContactFormState = {
    name: string;
    email: string;
    message: string;
};

const ContactForm: React.FC = () => {
    const [formState, setFormState] = useState<ContactFormState>({
        name: '',
        email: '',
        message: ''
    });
    const [notify, setNotify] = useState({
        show: false,
        error: false,
        message: ''
    });

    // Handle input changes
    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormState({
            ...formState,
            [event.target.name]: event.target.value
        });
    };

    // Handle form submission
    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();

        // Update the form state with the email appended to the message
        const updatedFormState = {
            ...formState,
            message: `${formState.message}\n\nEmail: ${formState.email}`
        };

        try {
            const response = await axios.post('contact', updatedFormState, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                setNotify({
                    show: true,
                    error: false,
                    message: 'Message sent!'
                });
            }
        } catch (error) {
            console.error('Error submitting form:', error);

            setNotify({
                show: true,
                error: true,
                message: 'Sorry, an error occurred. Please try again!'
            });
            // Handle failed form submission
        }
    };

    let info;

    if (notify.show) {
        info = <div className={notify.error ? 'alert alert-danger' : 'alert alert-success'} role="alert">
            {notify.message}
        </div>
    }

    return (
        <div className="form-signin-content form-register-ladder-content">
            <main className="form-signin form-signin-width">
                {info}
                <form onSubmit={handleSubmit}>
                    <h1 className="h3 mb-3 fw-normal">Contact Us</h1>

                    <div className="form-floating">
                        <input className="form-control" id="floatingLadderName" placeholder="Name"
                            name="name" value={formState.name} onChange={handleChange}
                        />
                        <label htmlFor="floatingLadderName">Name</label>
                    </div>
                    <div className="form-floating">
                        <input className="form-control" id="floatingLadderName" placeholder="Email"
                            name="email" type="email" value={formState.email} onChange={handleChange}
                        />
                        <label htmlFor="floatingLadderName">Email</label>
                    </div>
                    <div className="form-floating">
                        <textarea className="form-control"
                            name="message"
                            id="floatingDescription"
                            placeholder="Message"
                            value={formState.message}
                            onChange={handleChange}
                        />
                        <label htmlFor="floatingDescription">Message</label>
                    </div>
                    <button className="registerButton btn btn-primary w-100 py-2" type="submit">Submit</button>
                </form>
            </main>
        </div>
    );
};

export default ContactForm;
