import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {AccountInfo} from "./AccountInfo";
import { AccountImage } from './AccountImage';
import { DeleteUser } from './DeleteUser';
import { useUser } from './UserContext';
import { UpdateVenmoImage } from './UpdateVenmoImage';
import { UpdateZelleImage } from './UpdateZelleImage';
import { useNavigate } from 'react-router-dom';

export const Account = () => {
    const user = useUser();
    const navigate = useNavigate();

    if (!user) {
        return <div>Loading...</div>;
    }

    const {
        first_name,
        last_name,
        email,
        user_image,
        address_line_1,
        address_line_2,
        city,
        state,
        zip_code
    } = user;

    const fullName = `${first_name} ${last_name}`;
    
    // Function to handle navigation
    const handleUnsubscribeClick = () => {
        navigate('/email-subscription');
    };
    
    return (
        <div className="ladder-page">
            <div className="ladder-page-container">
                <div className="account-info">
                    <div className="account-image-container">
                        <img
                            src={`${user_image}`}
                            alt={fullName}
                            className="account-img"
                        />
                    </div>
                    {/* Name */}
                    <div className="info-row">
                        <div className="icon-container">
                            {/* This empty container will provide the spacing to align the ladder name */}
                        </div>
                        <h2 className="ladder-name">{fullName}</h2>
                    </div>

                    {/* Email */}
                    <div className="info-row">
                        <div className="icon-container">
                            <FontAwesomeIcon icon={faEnvelope}/>
                        </div>
                        <p className="ladder-page-para">{email}</p>
                    </div>

                    {/* Address Info */}
                    <div className="info-row">
                        <div className="icon-container">
                            <FontAwesomeIcon icon={faMapMarkerAlt}/>
                        </div>
                        <div>
                            <p className={"ladder-page-para"}>
                                {address_line_1} &nbsp;
                                {address_line_2} <br/>
                                {city}, {state}, {zip_code}
                            </p>
                        </div>
                    </div>
                </div>
                <div>
                    <AccountInfo/>
                    <AccountImage userData={user}/>
                    <UpdateVenmoImage userData={user}/>
                    <UpdateZelleImage userData={user}/>
                    <div className={'account-manage-email'}>
                        <button
                            className="btn btn-primary" onClick={handleUnsubscribeClick}>Manage Email Subscriptions
                        </button>
                    </div>
                    <DeleteUser/>
                </div>
            </div>
        </div>
    );
}
