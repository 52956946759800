import React, {SyntheticEvent, useEffect, useState} from "react";
import axios from "axios";
import {Navigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../redux/store";

export const UpdateLadderInfo: React.FC = () => {
    const { ladderId } = useParams();
    const [ladderName, setLadderName] = useState('');
    const [activities, setActivities] = useState<{ id: number; activity: string }[]>([]);
    const [selectedActivity, setSelectedActivity] = useState<number | null>(null);
    const [gametypes, setGametypes] = useState<{ id: number; gametype: string }[]>([]);
    const [selectedGametype, setSelectedGametype] = useState<number | null>(null);
    const [addressName, setAddressName] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [description, setDescription] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [error, setError] = useState('');
    const auth = useSelector((state: RootState) => state.auth.value);

    const US_STATES = [
        "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA",
        "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD",
        "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ",
        "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC",
        "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
    ];

    // Ladder Data
    useEffect(() => {
        const fetchLadderData = async () => {
            if (ladderId) {
                try {
                    const response = await axios.post(`retrieve-ladder`, {
                        ladder_id: ladderId
                    });
                    if (response.status === 200) {
                        const ladder = response.data;
                        setLadderName(ladder.name);
                        setSelectedActivity(ladder.activity.id);
                        setSelectedGametype(ladder.gametype.id);
                        setAddressName(ladder.address_name);
                        setAddressLine1(ladder.address_line_1);
                        setAddressLine2(ladder.address_line_2 || '');
                        setCity(ladder.city);
                        setState(ladder.state);
                        setZipCode(ladder.zip_code);
                        setDescription(ladder.description);
                    }
                } catch (error) {
                    console.error("Error fetching ladder data:", error);
                }
            }
        };

        // Now invoke the async function
        fetchLadderData().then(() => {
            // Whatever you'd like to execute after fetchLadderData completes
            console.log("Ladder data fetched successfully!");
        });

        // No return here unless you have a cleanup function.
    }, [ladderId]);

    // Get activity data
    useEffect(() => {
        // Fetch activities from the API
        axios.get('activity')
            .then((response) => {
                setActivities(response.data);
            })
            .catch((error) => {
                console.error("Error fetching activities:", error);
            });
    }, []); // Run this effect once when the component mounts

    const handleActivityChange = (e: any) => {
        const selectedValue = e.target.value;
        setSelectedActivity(selectedValue);
    };

    // Get gametype data
    useEffect(() => {
        // Fetch gametypes from the API
        axios.get('gametype')
            .then((response) => {
                setGametypes(response.data);
            })
            .catch((error) => {
                console.error("Error fetching gametypes:", error);
            });
    }, []); // Run this effect once when the component mounts

    const handleGametypeChange = (e: any) => {
        const selectedValue = e.target.value;
        setSelectedGametype(selectedValue);
    };

    // Submit button
    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        // Check ladder name
        if (!ladderName) {
            setError('Please enter a ladder name.');
            return;
        }

        // Check activity
        if (!selectedActivity) {
            setError('Please select an activity.');
            return;
        }

        // Check gametype
        if (!selectedGametype) {
            setError('Please select a gametype.');
            return;
        }

        // Check address line 1
        if (!addressLine1) {
            setError('Please enter an address in Address Line 1.');
            return;
        }

        // Check city
        if (!city) {
            setError('Please enter a city.');
            return;
        }

        // Check state
        if (!state) {
            setError('Please select a state.');
            return;
        }

        // Check zip code
        if (!zipCode) {
            setError('Please enter a zip code.');
            return;
        }

        // Check zip
        if (!/^\d{5}$/.test(zipCode)) {
            setError('Zip code must be 5 digits');
            return;
        }

        // Check ladder description length
        if (description.length < 50) {
            setError('Description must be at least 50 characters.');
            return;
        }

        if (description.length > 3000) {
            setError('Description cannot exceed 3000 characters.');
            return;
        }

        const formData = new FormData();
        formData.append('name', ladderName);
        formData.append('activity', selectedActivity.toString());
        formData.append('gametype', selectedGametype.toString());
        formData.append('address_name', addressName);
        formData.append('address_line_1', addressLine1);
        formData.append('address_line_2', addressLine2);
        formData.append('city', city);
        formData.append('state', state);
        formData.append('zip_code', zipCode);
        formData.append('description', description);

        try {
            const response = await axios.put(`update-ladder/${ladderId}`, formData);

            if (response.status === 200) {
                setRedirect(true);
            } else {
                setError('Ladder name already in use');
            }
        } catch (error) {
            setError('There was an error while creating the ladder.');
        }
    }

    if (redirect) {
        return <Navigate to="/"/>
    }

    return <div>
        { auth &&
            (
                <div className="">
                    <main className="form-signin form-signin-width">
                        <form onSubmit={submit}>
                            <h1 className="h3 mb-3 fw-normal">Update your ladder</h1>

                            <div className="form-floating">
                                <input className="form-control" 
                                       id="floatingLadderName" 
                                       value={ladderName}
                                       onChange={e => setLadderName(e.target.value)}
                                />
                                <label htmlFor="floatingLadderName">Ladder Name</label>
                            </div>

                            <div className="form-floating">
                                <select
                                    className="form-select"
                                    id="floatingActivity"
                                    value={selectedActivity || ""}
                                    onChange={handleActivityChange}
                                >
                                    <option value="" disabled>Select an Activity</option>
                                    {activities.map((activity) => (
                                        <option key={activity.id} value={activity.id}>
                                            {activity.activity}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="form-floating">
                                <select
                                    className="form-select"
                                    id="floatingActivity"
                                    value={selectedGametype || ""}
                                    onChange={handleGametypeChange}
                                >
                                    <option value="" disabled>Select a Game Type</option>
                                    {gametypes.map((gametype) => (
                                        <option key={gametype.id} value={gametype.id}>
                                            {gametype.gametype}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* Divider */}
                            <div className="divider"></div>

                            <div className="form-floating">
                                <input className="form-control"
                                       id="floatingAddressName"
                                       value={addressName}
                                       onChange={e => setAddressName(e.target.value)}
                                />
                                <label htmlFor="floatingAddressName">Park/Facility Name</label>
                            </div>

                            <div className="form-floating">
                                <input className="form-control"
                                       id="floatingAddressLine1"
                                       value={addressLine1}
                                       onChange={e => setAddressLine1(e.target.value)}
                                />
                                <label htmlFor="floatingAddressLine1">Address Line 1</label>
                            </div>

                            <div className="form-floating">
                                <input className="form-control"
                                       id="floatingAddressLine2"
                                       value={addressLine2}
                                       onChange={e => setAddressLine2(e.target.value)}
                                />
                                <label htmlFor="floatingAddressLine2">Address Line 2</label>
                            </div>

                            <div className="form-floating">
                                <input className="form-control"
                                       id="floatingCity"
                                       value={city}
                                       onChange={e => setCity(e.target.value)}
                                />
                                <label htmlFor="floatingCity">City</label>
                            </div>

                            <div className="form-floating">
                                <select
                                    className="form-select"
                                    id="floatingState"
                                    value={state}
                                    onChange={e => setState(e.target.value)}
                                >
                                    <option value="" disabled>Select a State</option>
                                    {US_STATES.map(s => (
                                        <option key={s} value={s}>
                                            {s}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="form-floating">
                                <input className="form-control"
                                       id="floatingZipCode"
                                       value={zipCode}
                                       onChange={e => setZipCode(e.target.value)}
                                />
                                <label htmlFor="floatingZipCode">Zip Code</label>
                            </div>

                            <div className="form-floating">
                                <textarea className="form-control"
                                       id="floatingDescription"
                                       value={description}
                                       onChange={e => setDescription(e.target.value)}
                                />
                                <label htmlFor="floatingDescription">Ladder Description</label>
                            </div>

                            {error && <div className="alert alert-danger mb-3">{error}</div>}

                            <button className="registerButton btn btn-primary w-100 py-2" type="submit">Update</button>
                        </form>
                    </main>
                </div>
            )
        }
    </div>
}