import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faSync, faMapMarkerAlt, faRunning } from '@fortawesome/free-solid-svg-icons';
import EventSlides from "./EventSlides";
import LadderMembers from './LadderMembers';
import { Link } from 'react-router-dom';
import { useUser } from './UserContext';

type RouteParams = {
    ladderId: string;
}

type Activity = {
    id: number;
    activity: string;
};

type Gametype = {
    id: number;
    gametype: string;
};

type Ladder = {
    id: number;
    name: string;
    activity: Activity;
    gametype: Gametype;
    ladder_image: string;
    address_name: string;
    address_line_1: string;
    address_line_2: string | null;
    city: string;
    state: string;
    zip_code: number;
    latitude: number;
    longitude: number;
    distance: number;
    owner: number;
    user_count: number;
    description: string;
};

type Event = {
    id: number;
    event_name: string;
    creation_date: string;
    weekday: string;
    time: string;
    address_name: string;
    address_line_1: string;
    address_line_2: string | null;
    city: string;
    state: string;
    zip_code: number;
    latitude: number;
    longitude: number;
    reoccurring: boolean;
    user_count: number;
};

export const LadderPage: React.FC = () => {
    const { ladderId = "" } = useParams<RouteParams>();
    const [userId, setUserId] = useState<number | null>(null);
    const [ownerData, setOwnerData] = useState<any | null>(null);
    const [ladderItem, setLadderItem] = useState<Ladder | null>(null);
    const [member, setMember] = useState<boolean | null>(null);
    const [eventsData, setEventsData] = useState<any | null>(null);
    const [snackbar, setSnackbar] = useState<{ show: boolean; message: string }>({
        show: false,
        message: ''
    });
    const [submitCounter, setSubmitCounter] = useState(0);
    const user = useUser();
    const [showVenmoQr, setShowVenmoQr] = useState(false);
    const [showZelleQr, setShowZelleQr] = useState(false);
    const navigate = useNavigate();

    // User Id
    useEffect(() => {
        const fetchUserId = async () => {
            // If userId is not null, return early to avoid refetching
            if (user) {
                setUserId(user.id);
                localStorage.setItem('preLoginUrl', window.location.pathname)
            }
        };
        fetchUserId();
    }); // Now we depend on userId as well

    // Ladder Data
    useEffect(() => {
        const fetchLadderData = async () => {
            if (ladderId) {
                try {
                    const response = await axios.post(`retrieve-ladder`, {
                        ladder_id: ladderId
                    });
                    if (response.status === 200) {
                        setLadderItem(response.data);
                    }
                } catch (error) {
                    console.error("Error fetching ladder data:", error);
                }
            }
        };

        // Now invoke the async function
        fetchLadderData().then(() => {
            // Whatever you'd like to execute after fetchLadderData completes
            console.log("Ladder data fetched successfully!");
        });

        // No return here unless you have a cleanup function.
    }, [ladderId]);

    // Check membership
    useEffect(() => {
        const checkMembership = async () => {
            if (userId && ladderItem) {
                try {
                    const response = await axios.post('check-ladder-membership', {
                        user_id: userId,
                        ladder_id: ladderItem.id
                    });
                    if (response.status === 200) {
                        setMember(response.data.membership);
                    }
                } catch (error) {
                    console.error("Error checking membership:", error);
                }
            }
        };

        checkMembership().then(() => {
            console.log("Membership data fetched successfully!");
        });
    }, [userId, ladderItem]);

    // Get events within ladder
    useEffect(() => {
        const fetchEventsWithinLadder = async () => {
            if (ladderId) {
                try {
                    const requestData = {
                        ladder_id: ladderId,
                    };
                    const response = await axios.post('events-within-ladder', requestData);

                    // Filter out non-reoccurring events with a past creation date
                    const filteredEvents = response.data.filter((event: Event) => {
                        if (!event.reoccurring) {
                            const creationDateString = event.creation_date + 'T00:00:00'; // Append time component
                            const creationDate = new Date(creationDateString);
                            const today = new Date();
                            today.setHours(0, 0, 0, 0); // Normalize today's date for comparison
                            return creationDate >= today;
                        }
                        return true;
                    });

                    setEventsData(filteredEvents);
                } catch (error) {
                    console.error("Error fetching events:", error);
                }
            }
        };

        fetchEventsWithinLadder().catch(err => {
            console.error("Error in fetchEventsWithinLadder useEffect:", err);
        });
    }, [ladderId]);
    
    // User Data
    useEffect(() => {
        if (ladderItem) {
            const fetchUserData = async () => {
                try {
                    const response = await axios.get(`user-basic-info/${ladderItem.owner}`);
                    if (response.status === 200) {
                        setOwnerData(response.data)
                    }
                }
                catch (e) {
                    console.log(e)
                }
            };
            // Now invoke the async function
            fetchUserData().then(() => {
                // Whatever you'd like to execute after fetchEventData completes
                console.log("User data fetched successfully!")
            });
        }
    }, [ladderItem]);


    if (!ladderItem) return <p>Loading...</p>;

    // Function to handle the button click
    const handleJoinClick = async () => {
        if (!userId || !ladderItem || !user) return;

        // Define the endpoint URL based on whether the user is already a member
        const endpoint = member ?
            'remove-user-from-ladder' :
            'add-user-to-ladder';
        
        // Navigate unranked user to self rate
        if (ladderItem.activity.activity === "Tennis" && 
            user && 
            user.tennis_ranking === null &&
            endpoint === 'add-user-to-ladder') {
            console.log('entered tennis')
            navigate(`/self-rate/${ladderItem.activity.activity}/${ladderItem.id}`);
            return
        }

        // Navigate unranked user to self rate
        if (ladderItem.activity.activity === "Pickleball" &&
            user &&
            user.pickleball_ranking === null &&
            endpoint === 'add-user-to-ladder') {
            navigate(`/self-rate/${ladderItem.activity.activity}/${ladderItem.id}`);
            return
        }

        try {
            const response = await axios.post(endpoint, {
                user_id: userId,
                ladder_id: ladderItem.id
            });

            if (response.status === 200) {

                // Determine the snackbar message based on whether the user is already a member
                const snackbarMessage = member
                    ? "You've been removed from the ladder."
                    : "You've successfully joined the ladder!";

                // Set the snackbar message and display it
                setSnackbar({
                    show: true,
                    message: snackbarMessage
                });

                // Hide the snackbar after 3 seconds
                setTimeout(() => {
                    setSnackbar({ show: false, message: '' });
                }, 3000);

                setMember(!member);

                // Call the API to recalculate the rank
                await axios.patch(`recalculate-rank/${ladderItem.id}`);
                onScoreSubmit()
                console.log('Rank recalculated successfully');
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const onScoreSubmit = () => {
        setSubmitCounter(prevCount => prevCount + 1);
    };

    const toggleVenmoQr = () => {
        setShowVenmoQr(!showVenmoQr);
    };
    
    const toggleZelleQr = () => {
        setShowZelleQr(!showZelleQr);
    };

    return (
        <div className="ladder-page">
            <div className="ladder-page-container">
                <img
                    src={ladderItem.ladder_image}
                    alt={ladderItem.name}
                    className="ladder-page-image"
                />
                <div className="ladder-page-info">
                    {/* Ladder Name */}
                    <div className="info-row">
                        <div className="icon-container">
                            {/* This empty container will provide the spacing to align the ladder name */}
                        </div>
                        <h2 className="ladder-name">{ladderItem.name}</h2>
                    </div>

                    {/* Event/Sport */}
                    <div className="info-row">
                        <div className="icon-container">
                            <FontAwesomeIcon icon={faRunning}/>
                        </div>
                        <p className="ladder-page-para">{ladderItem.activity.activity}</p>
                    </div>

                    {/* Game Type */}
                    <div className="info-row">
                        <div className="icon-container">
                            <FontAwesomeIcon icon={faSync}/>
                        </div>
                        <p className="ladder-page-para">{ladderItem.gametype.gametype}</p>
                    </div>

                    {/* Address Info */}
                    <div className="info-row">
                        <div className="icon-container">
                            <FontAwesomeIcon icon={faMapMarkerAlt}/>
                        </div>
                        <div>
                            <p className={"ladder-page-para"}>
                                {ladderItem.address_name} <br/>
                                {ladderItem.address_line_1} &nbsp;
                                {ladderItem.address_line_2} <br/>
                                {ladderItem.city}, {ladderItem.state}, {ladderItem.zip_code}
                            </p>
                        </div>
                    </div>

                    {/* User Count Info */}
                    <div className="info-row">
                        <div className="icon-container">
                            <FontAwesomeIcon icon={faUsers}/>
                        </div>
                        <p className="ladder-page-para">{ladderItem.user_count} Members</p>
                    </div>

                    {/* Join/Joined Button */}
                    <div className="info-row">
                        <div className="info-row">
                            {member !== null && (
                                <button className={member ? "joined-button" : "join-button"}
                                        onClick={handleJoinClick}
                                >
                                    {member ? "Joined" : "Join"}
                                </button>
                            )}
                        </div>
                        {member && (
                            <Link to={`/message/ladder/${ladderItem?.id}/${encodeURIComponent(ladderItem?.name)}`}
                                  className="ladder-page-update-ladder ladder-page-go-to-chat">
                                Go to Chat
                            </Link>
                        )}
                    </div>
                    <div className="info-row">
                        {ownerData && member && (
                            <div>
                                <h4>Host</h4>
                                <div className="ladder_members_host-pill">
                                    {showVenmoQr ? (
                                        <div className="venmo-qr-container">
                                            <a href={`https://venmo.com/u/${ownerData.venmo_handle}`}
                                               target="_blank"
                                               rel="noopener noreferrer">
                                                <img src={`${ownerData.venmo_qr}`}
                                                     alt={ownerData.venmo_qr}
                                                     className="venmo-qr-container"/>
                                            </a>
                                            <button className="close-qr" onClick={toggleVenmoQr}>×</button>
                                        </div>
                                        ) : showZelleQr ? (
                                        <div className="venmo-qr-container">
                                            <img src={`${ownerData.zelle_qr}`}
                                                 alt={ownerData.zelle_qr}
                                                 className="venmo-qr-container"/>
                                            <button className="close-qr" onClick={toggleZelleQr}>×</button>
                                        </div>
                                    ) : (
                                        <div className="ladder-page-container-host">
                                            <div>
                                                <div className="ladder_members_name">
                                                    <Link to={`/user-profile/${ownerData.id}`}
                                                          className={"profile-custom-link"}>
                                                        <img src={`${ownerData.user_image}`}
                                                             alt={`${ownerData.first_name} ${ownerData.last_name}`}
                                                             className="ladder_members_user-image"/>
                                                        <span>{ownerData.first_name} {ownerData.last_name}</span>
                                                    </Link>
                                                </div>
                                                <Link
                                                    to={`/message/user/${userId}/${ladderItem?.owner}/${encodeURIComponent(ownerData.first_name + ' ' + ownerData.last_name)}`}
                                                    className="ladder-page-update-ladder ladder-page-go-to-chat ladder-page-go-to-chat-host">
                                                    Message Host
                                                </Link>
                                            </div>
                                            <div>
                                                <div className="venmo-link-container" onClick={toggleVenmoQr}>
                                                    <div className={`venmo-icon`}>
                                                        {/* Image handled by CSS */}
                                                    </div>
                                                </div>
                                                <div className="zelle-link-container" onClick={toggleZelleQr}>
                                                    <div className={`zelle-icon`}>
                                                        {/* Image handled by CSS */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Update Ladder */}
                    {
                        userId === ladderItem.owner && (
                            <div>
                                <div className="info-row ladder-page-host-options">
                                    <div className="info-row">
                                        <h4 className={""}>Host Options</h4>
                                    </div>
                                </div>
                                <div className="info-row ladder-page-host-options">
                                    <div className="info-row">
                                        <Link to={`/create-event/${ladderItem.id}`} className="ladder-page-update-ladder">
                                            Create Event
                                        </Link>
                                        <Link to={`/update-ladder/${ladderItem.id}`} className="ladder-page-update-ladder">
                                            Update Ladder
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="ladder-page-info">
                </div>
            </div>

            <div className="ladder-page-container2">
                <div className="ladder-page-description">
                    <h4>Events</h4>
                    <EventSlides eventsData={eventsData}/>
                    <h4 style={{marginTop: '40px'}}>Ladder Members</h4>
                    <LadderMembers ladderId={ladderId} submitCounter={submitCounter}/>
                </div>
                <div className="ladder-page-description">
                    <h4>Description</h4>
                    {ladderItem.description}
                </div>
            </div>

            {/*Snackbar*/}
            {snackbar.show && (
                <div className="snackbar">
                    {snackbar.message}
                </div>
            )}
        </div>
    );
};