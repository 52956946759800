import React, { SyntheticEvent, useEffect, useState } from "react";
import axios from "axios";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const weekdayMap: { [key: string]: number } = {
    "Monday": 1,
    "Tuesday": 2,
    "Wednesday": 3,
    "Thursday": 4,
    "Friday": 5,
    "Saturday": 6,
    "Sunday": 7
};

export const RegisterEvent = () => {
    const navigate = useNavigate();
    const [eventName, setEventName] = useState<string>('');
    const [eventDate, setEventDate] = useState<string>('');
    const [time, setTime] = useState<{ id: number; time: string }[]>([]);
    const [selectedTime, setSelectedTime] = useState<number | null>(null);
    const { ladderId } = useParams<{ ladderId: string }>();
    const [isRecurring, setIsRecurring] = useState<boolean>(true);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const auth = useSelector((state: RootState) => state.auth.value);
    const [weekday, setWeekday] = useState<string>('');
    const [weekdayId, setWeekdayId] = useState<number | null>(null);
    const [maxParticipants, setMaxParticipants] = useState<string>('');
    const [numHoursLockEvent, setNumHoursLockEvent] = useState<string>('');

    const [courtFields, setCourtFields] = useState<string[]>([]);

    // Get time data
    useEffect(() => {
        axios.get('/ladder-times')
            .then((response) => {
                setTime(response.data);
            })
            .catch((error) => {
                console.error("Error fetching time:", error);
            });
    }, []); // Run this effect once when the component mounts

    const handleTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = e.target.value;
        setSelectedTime(Number(selectedValue));
    };

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newDate = e.target.value;
        setEventDate(newDate);
        updateWeekday(newDate);
    };

    const updateWeekday = (dateString: string) => {
        if (dateString) {
            const dateParts = dateString.split('-');
            if (dateParts.length === 3) {
                const year = parseInt(dateParts[0], 10);
                const month = parseInt(dateParts[1], 10) - 1; // Month is 0-indexed
                const day = parseInt(dateParts[2], 10);

                const date = new Date(year, month, day);
                const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'long' });
                setWeekday(dayOfWeek);
                const dayId = weekdayMap[dayOfWeek];
                setWeekdayId(dayId);
            } else {
                setWeekday('');
                setWeekdayId(null);
            }
        } else {
            setWeekday('');
            setWeekdayId(null);
        }
    };

    const addCourtField = () => {
        if (courtFields.length < 10) {
            setCourtFields([...courtFields, `court${courtFields.length + 1}`]);
        }
    };

    const removeCourtField = (index: number) => {
        setCourtFields(courtFields.filter((_, i) => i !== index));
    };

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        if (!eventName) {
            setError('Please enter an event name.');
            return;
        }

        if (!selectedTime) {
            setError('Please select a time.');
            return;
        }

        if (!eventDate) {
            setError('Please select an event start date.');
            return;
        }

        const maxParticipantsNumber = parseInt(maxParticipants, 10);
        if (!maxParticipants || isNaN(maxParticipantsNumber) || maxParticipantsNumber < 0) {
            setError('Please enter a valid max number of participants');
            return;
        }

        const lockEventHours = parseInt(numHoursLockEvent, 10);
        if (!numHoursLockEvent || isNaN(lockEventHours) || lockEventHours < 0) {
            setError('Please enter a valid number of hours to lock event before it starts');
            return;
        }

        const finalLockEventHours = lockEventHours === 0 ? null : lockEventHours;

        if (ladderId === undefined || selectedTime === null) {
            setError('All fields must be filled.');
            return;
        }

        const eventPayload: any = {
            event_name: eventName,
            creation_date: eventDate,
            weekday: weekdayId,
            time: Number(selectedTime),
            ladder: Number(ladderId),
            max_num_users: Number(maxParticipants),
            num_hours_lock_event: finalLockEventHours,
            reoccurring: isRecurring ? 1 : 0
        };

        courtFields.forEach((court) => {
            const courtElement = document.getElementsByName(court)[0] as HTMLInputElement;
            if (courtElement) {
                eventPayload[court] = courtElement.value;
            }
        });

        try {
            const response = await axios.post('/register-event', eventPayload);

            if (response.status === 200) {
                setRedirect(true);
            } else {
                setError('Ladder name already in use');
            }
        } catch (error) {
            setError('There was an error while creating the event.');
        }
    };

    const convertTo12HrFormat = (time24: string) => {
        const [hours, minutes] = time24.split(':');
        const hrs = parseInt(hours, 10);
        const suffix = hrs >= 12 ? 'PM' : 'AM';
        const adjustedHrs = hrs > 12 ? hrs - 12 : hrs === 0 ? 12 : hrs;

        return `${adjustedHrs.toString().padStart(2, '0')}:${minutes} ${suffix}`;
    };

    if (redirect) {
        return <Navigate to={`/ladder-page/${ladderId}`} />;
    }

    const handleBackClick = () => {
        navigate(-1);
    };

    return (
        <div>
            {auth && (
                <div>
                    <button onClick={handleBackClick} className="back-to-ladder-button">
                        <FontAwesomeIcon icon={faArrowLeft} />
                        <span>Back to Ladder</span>
                    </button>
                    <div className={'update-ladder-container4'}>
                        <main className="form-signin form-signin-width">
                            <form onSubmit={submit}>
                                <h1 className="h3 mb-3 fw-normal">Create your event</h1>

                                <div className="form-floating">
                                    <input
                                        className="form-control"
                                        id="floatingLadderName"
                                        placeholder="Ladder Name"
                                        onChange={e => setEventName(e.target.value)}
                                    />
                                    <label htmlFor="floatingLadderName">Event Name</label>
                                </div>

                                <div className="form-floating">
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="floatingDate"
                                        placeholder="Event Date"
                                        value={eventDate}
                                        onChange={handleDateChange}
                                    />
                                    <label htmlFor="floatingDate">Start Date</label>
                                </div>

                                <div className="form-floating">
                                    <select
                                        className="form-select"
                                        id="floatingTime"
                                        value={selectedTime || ""}
                                        onChange={handleTimeChange}
                                    >
                                        <option value="" disabled>Select a Time</option>
                                        {time.map((timeItem) => (
                                            <option key={timeItem.id} value={timeItem.id}>
                                                {convertTo12HrFormat(timeItem.time)}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-floating">
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="maxParticipants"
                                        placeholder="Max Number of Participants"
                                        value={maxParticipants}
                                        onChange={e => setMaxParticipants(e.target.value)}
                                    />
                                    <label htmlFor="maxParticipants">Max Number of Participants</label>
                                </div>

                                <div className="form-floating">
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="numHoursLockEvent"
                                        placeholder="Lock event _ hours before it starts"
                                        value={numHoursLockEvent}
                                        onChange={e => setNumHoursLockEvent(e.target.value)}
                                    />
                                    <label htmlFor="numHoursLockEvent">
                                        Lock event _ hours before it starts
                                    </label>
                                </div>

                                <div className="form-check mb-3">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="recurringCheckbox"
                                        checked={isRecurring}
                                        onChange={e => setIsRecurring(e.target.checked)}
                                    />
                                    <label className="form-check-label" htmlFor="recurringCheckbox">
                                        Recurring
                                    </label>
                                    {isRecurring && weekday && selectedTime && (
                                        <div className="recurring-info">
                                            Recurs every {weekday}
                                        </div>
                                    )}
                                </div>

                                <div>
                                    {courtFields.map((court, index) => (
                                        <div key={court} style={{marginBottom: '20px'}}>
                                            <div className="form-floating mb-2">
                                                <input
                                                    className="form-control"
                                                    id={`floatingLadderName${index}`}
                                                    placeholder={`Court`}
                                                    name={`court${index + 1}`}
                                                />
                                                <label htmlFor={`floatingLadderName${index}`}>Court</label>
                                            </div>

                                            <div className={'register-event-button-container'}>
                                                <button
                                                    type="button"
                                                    onClick={() => removeCourtField(index)}
                                                    className={'remove-court-plus-button btn btn-danger'}
                                                >
                                                    -
                                                </button>
                                                {index === courtFields.length - 1 && courtFields.length < 10 && (
                                                    <button
                                                        type="button"
                                                        onClick={addCourtField}
                                                        className={'add-court-plus-button btn btn-success'}
                                                    >
                                                        +
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                    {courtFields.length === 0 && (
                                        <button type="button" onClick={addCourtField}
                                                className={'add-court-button'}>
                                            Assign Court
                                        </button>
                                    )}
                                </div>

                                {error && <div className="alert alert-danger mb-3">{error}</div>}

                                <button className="registerButton btn btn-primary w-100 py-2" type="submit">Register
                                </button>
                            </form>
                        </main>
                    </div>
                </div>
            )}
        </div>
    );
};
