import React, {useEffect, useState, SyntheticEvent, KeyboardEvent} from 'react';
import DiscoverSlides from "./DiscoverSlides";
import axios from "axios";
import { useSelector } from "react-redux";
import MapComponent from './GoogleMap';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../redux/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useUser } from './UserContext';

export const Discover = () => {
    const navigate = useNavigate();
    const auth = useSelector((state: RootState) => state.auth.value);
    const [userId, setUserId] = useState<number | null>(null);
    const [userPosition, setUserPosition] = useState<{ lat: number; lng: number }>({ lat: 0, lng: 0 })
    const [selectedRadius, setSelectedRadius] = useState<number | null>(60);
    const [hasInteractedWithRadius, setHasInteractedWithRadius] = useState<boolean>(false);
    const [laddersData, setLaddersData] = useState<any | null>(null);
    const [activities, setActivities] = useState<{ id: number; activity: string }[]>([]);
    const [selectedActivity, setSelectedActivity] = useState<number | null>(null);
    const [newAddress, setNewAddress] = useState<string>("");
    const [lastGeocodedAddress, setLastGeocodedAddress] = useState<string>("");
    const [selectedFilter, setSelectedFilter] = useState<string | null>(null); // Possible values: null, 'filterOnUserId', 'filterHostedLadders'
    const user = useUser();

    // Determine if user is authenticated
    useEffect(() => {
        (async () => {
            try {
                if (auth && user) {
                    const initlatitude = Number(user.latitude);
                    const initlongitude = Number(user.longitude);
                    if (!isNaN(initlatitude) && !isNaN(initlongitude)) {
                        setUserId(user.id);
                        setUserPosition({ lat: initlatitude, lng: initlongitude });
                    } else {
                        console.error("Invalid latitude or longitude values");
                    }
                }
            } catch (e) {
                console.log(e)
                // Handle error
            }
        })();
    }, [user, auth]);

    // Get ladders within selected radius
    useEffect(() => {
        const filter  = window.sessionStorage.getItem('filter');

        if (filter) {
            setSelectedFilter(filter);
        }

        const fetchLaddersWithinRadius = async () => {
            if (userId) {
                try {
                    const requestData = {
                        user_id: userId,
                        radius: selectedRadius,
                        activity_id: selectedActivity,
                        new_address: newAddress,
                        filter_on_user_id: selectedFilter === 'filterOnUserId',
                        hosted_ladders: selectedFilter === 'filterHostedLadders'
                    };
                    const response = await axios.post('ladders-within-radius', requestData);
                    setLaddersData(response.data);
                } catch (error) {
                    console.error("Error fetching ladders:", error);
                }
            }
        }
        fetchLaddersWithinRadius();
        // eslint-disable-next-line
    }, [userId, selectedRadius, selectedActivity, selectedFilter]);

    // Get activity data
    useEffect(() => {
        // Fetch activities from the API
        axios.get('activity')
            .then((response) => {
                setActivities(response.data);

            })
            .catch((error) => {
                console.error("Error fetching activities:", error);
            });


    }, []); // Run this effect once when the component mounts

    // Handle radius change
    const handleRadiusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setHasInteractedWithRadius(true);
        const value = event.target.value;
        setSelectedRadius(value ? parseInt(value) : null);
    };

    // Handle activity change
    const handleActivityChange = (e: any) => {
        const selectedValue = e.target.value;
        setSelectedActivity(selectedValue === "all" ? null : parseInt(selectedValue));
    };

    // Handle address change by updating typed address in search box
    const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewAddress(event.target.value)
    };

    // Keyboard event handler
    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
        handleFormSubmit(e);
        }
    };

    const geocodeNewAddress = async () => {
        // Checking that newAddress changes to avoid making excess API calls
        if (lastGeocodedAddress !== newAddress) {
            if (newAddress === '' && user) {
                setUserPosition({ lat: Number(user.latitude), lng: Number(user.longitude) });
                setLastGeocodedAddress(newAddress)
            } else {
                try {
                    const requestData = {
                        new_address: newAddress
                    };
                    const response = await axios.post('geocode-address', requestData);
                    setUserPosition({
                        lat: parseFloat(response.data.latitude),
                        lng: parseFloat(response.data.longitude)
                    });
                    setLastGeocodedAddress(newAddress)
                } catch (error) {
                    console.error("Error geocoding the new address:", error);
                    // Optionally, you can add more specific error handling here
                }
            }
        }
    };

    // Submit button
    const handleFormSubmit = async (e: SyntheticEvent | KeyboardEvent) => {
        e.preventDefault();

        // Directly call geocodeNewAddress when the form is submitted
        await geocodeNewAddress();

        const ladderPayload = {
            user_id: userId,
            radius: selectedRadius,
            activity_id: selectedActivity,
            new_address: newAddress,
            filter_on_user_id: selectedFilter === 'filterOnUserId',
            hosted_ladders: selectedFilter === 'filterHostedLadders'
        };

        try {
            const response = await axios.post('ladders-within-radius', ladderPayload);
            setLaddersData(response.data);
        } catch (error) {
            console.error("Error fetching ladders:", error);
        }
    }

    const handleCheckboxChange = (filterName: any) => {
        setSelectedFilter(prevFilter => {
            const newFilter = prevFilter === filterName ? null : filterName;
            if (newFilter === null) {
                window.sessionStorage.removeItem('filter');
            } else {
                window.sessionStorage.setItem('filter', newFilter);
            }
            return newFilter;
        });
    };

    const handleCreateLadderClick = () => {
        navigate('/register-ladder'); // Use navigate to navigate
    };

    return (
        <div>
            {/* Content will be added here in the future if needed */}
            <h3 className={'discover-header'}>Ladders</h3>
            <div className={'discover-header-container discover-ladder-margin'}>
                <main className="form-signin container-signin-discover">
                    <form className={"form-signin-discover"} onSubmit={handleFormSubmit} onKeyDown={handleKeyDown}>
                        {/*Radius*/}
                        <div className="form-floating dropdown-discover filters-container">
                            <select
                                className="form-select"
                                id="floatingRadius"
                                value={hasInteractedWithRadius && selectedRadius !== null ? selectedRadius : ""}
                                onChange={handleRadiusChange}
                            >
                                <option value="" disabled>Select Radius</option>
                                {[
                                    1, 2, 5, 10, 15, 20, 25, 30, 40, 50, 60
                                ].map((radius) => (
                                    <option key={radius} value={radius}>
                                        {radius} mile{radius > 1 ? 's' : ''}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/*Activity*/}
                        <div className="form-floating dropdown-discover filters-container">
                            <select
                                className="form-select"
                                id="floatingActivity"
                                value={selectedActivity === null ? "all" : selectedActivity}
                                onChange={handleActivityChange}
                            >
                                <option value="" disabled>Select Activity</option>
                                <option value="all">All Activities</option>
                                {activities.map((activity) => (
                                    <option key={activity.id} value={activity.id}>
                                        {activity.activity}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* New Address Input */}
                        <div className="form-floating dropdown-discover filters-container">
                            <input
                                type="text"
                                className="form-control filters-container"
                                id="newAddress"
                                placeholder="Enter new address"
                                value={newAddress}
                                onChange={handleAddressChange}
                            />
                            <label htmlFor="newAddress">New Address</label>
                        </div>

                        <div className="discover-checkbox-container">
                            {/*Filter on User Id*/}
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="filterOnUserIdCheckbox"
                                    checked={selectedFilter === 'filterOnUserId'}
                                    onChange={() => handleCheckboxChange('filterOnUserId')}
                                />
                                <label className="form-check-label" htmlFor="filterOnUserIdCheckbox">
                                    My Ladders
                                </label>
                            </div>

                            {/* Hosted Ladders Checkbox */}
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="filterHostedLaddersCheckbox"
                                    checked={selectedFilter === 'filterHostedLadders'}
                                    onChange={() => handleCheckboxChange('filterHostedLadders')}
                                />
                                <label className="form-check-label" htmlFor="filterHostedLaddersCheckbox">
                                    Hosted Ladders
                                </label>
                            </div>
                        </div>
                        <button className="registerButton btn btn-primary w-100 py-2 form-floating
                        landing-page-search-button filter-button"
                                type="submit"><FontAwesomeIcon icon={faSearch} /></button>
                    </form>
                </main>
                <div className="ladders-heading">
                    <button className="ladder-page-update-ladder create-ladder-button host-ladder-button" onClick={handleCreateLadderClick}>
                        Host a ladder
                    </button>
                </div>
            </div>
            <div className= 'ladder-page-container2'>
                <div className='discover-left-square'>
                    <DiscoverSlides data={laddersData} />
                </div>
                <div className = 'discover-right-square'>
                    <MapComponent key={userPosition.toString()} position={userPosition} data={laddersData} />
                </div>
            </div>
        </div>
    );
};

export default Discover;