import React, { createContext, useContext, useEffect, ReactNode, useState } from 'react';
import axios from 'axios';
import {useDispatch, useSelector} from "react-redux";
import {setAuth} from "../redux/authSlice";
import { RootState } from '../redux/store';

// Your UserData interface remains the same
interface UserData {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    user_image: string;
    address_line_1: string;
    address_line_2: string | null;
    city: string;
    state: string;
    zip_code: number;
    latitude: number;
    longitude: number;
    num_free_trials: number;
    about_me: string | null;
    tennis_ranking: number;
    pickleball_ranking: number;
}

// Update the context to directly use UserData | null | undefined
const UserContext = createContext<UserData | null | undefined>(undefined);

export const useUser = () => {
    const user = useContext(UserContext);
    if (user === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return user; // Directly return the user now
};

interface UserProviderProps {
    children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
    const [user, setUser] = useState<UserData | null>(null);
    const dispatch = useDispatch();
    const auth = useSelector((state: RootState) => state.auth.value);

    useEffect(() => {
        (async () => {
            try {
                const response = await axios.get('user');
                if (response.status === 200) {
                    setUser(response.data)
                    dispatch(setAuth(true));
                } else {
                    dispatch(setAuth(false));
                }
            } catch (e) {
                dispatch(setAuth(false));
            }
        })();
    }, [dispatch, auth]);

    return (
        <UserContext.Provider value={user}>
            {children}
        </UserContext.Provider>
    );
};
