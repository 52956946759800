import React, {SyntheticEvent, useState} from "react";
import axios from "axios";

type user = {
    first_name: string;
    last_name: string;
};

type AccountImageProps = {
    userData: user;
};

export const AccountImage: React.FC<AccountImageProps> = ({ userData }) => {
    const [userImage, setUserImage] = useState<File | null>(null);
    const [redirect, setRedirect] = useState(false);
    const [error, setError] = useState('');

    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {
            if (file.size > 33554432) { // 32 MB in bytes
                setError('Image size too large');
                return;
            }
            setUserImage(file);
            setError(''); // Clear any previous errors
        }
    };

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        // Check first name
        if (!userImage) {
            setError('Please upload a profile picture');
            return;
        }

        // Send post request
        const formData = new FormData();

        // Get the file extension
        const fileExtension = userImage.name.split('.').pop();

        // Rename the file with the ladder name and its original extension
        const newFileName = `${userData.first_name}_${userData.last_name}.${fileExtension}`;
        const renamedFile = new File([userImage], newFileName, {
            type: userImage.type,
        });
        formData.append("user_image", renamedFile);

        try {
            const response = await axios.post('update-user-image', formData);

            if (response.status === 200) {
                setRedirect(true);
            } else {
                setError('An error occurred while updating the image');
            }
        } catch (error) {
            setError('There was an error while updating the image.');
        }
    }

    if (redirect) {
        // Use window.location.reload to refresh the page
        window.location.reload();
    }

    return (
        <div className="account-change-picture">
            <main className="form-signin form-signin-width">
                <form onSubmit={submit}>
                    <h1 className="h3 mb-3 fw-normal">Profile Picture</h1>
                    {/* Image upload field */}
                    <div className="mb-3">
                        <input
                            type="file"
                            accept="image/*"
                            className="form-control form-control-image"
                            id="imageUpload"
                            onChange={handleImageUpload}
                        />
                    </div>

                    {error && <div className="alert alert-danger mb-3">{error}</div>}

                    <button className="registerButton btn btn-primary w-100 py-2" type="submit">Update</button>
                </form>
            </main>
        </div>
    )
}