import React, { SyntheticEvent, useState } from "react";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAuth } from "../redux/authSlice"; // Adjust the import path according to your project structure

export const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [error, setError] = useState('');
    const [redirectTo, setRedirectTo] = useState('');

    const dispatch = useDispatch();

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        // Retrieve and immediately store the URL to redirect to
        const preLoginUrl = localStorage.getItem('preLoginUrl');
        setRedirectTo(preLoginUrl || '/');  // Default to home if no URL was saved
        localStorage.removeItem('preLoginUrl'); // Remove the URL from local storage

        try {
            const {data} = await axios.post('login', {
                email,
                password,
            }, {withCredentials: true});

            axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
            localStorage.setItem('token', data.token);

            dispatch(setAuth(true)); // Dispatch action to update auth state to true
            setRedirect(true);
        } catch (error) {
            setError('Incorrect username or password');
        }
    };

    if (redirect) {
        return <Navigate to={redirectTo}/>;
    }

    return (
        <div className="form-signin-content">
            <main className="form-signin form-signin-width w-100 m-auto">
                <form onSubmit={submit}>
                    <h1 className="h3 mb-3 fw-normal">Sign In</h1>

                    <div className="form-floating">
                        <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com"
                               onChange={e => setEmail(e.target.value)}
                        />
                        <label htmlFor="floatingInput">Email Address</label>
                    </div>

                    <div className="form-floating">
                        <input type="password" className="form-control" id="floatingPassword" placeholder="Password"
                               onChange={e => setPassword(e.target.value)}
                        />
                        <label htmlFor="floatingPassword">Password</label>
                    </div>

                    <div className={"mb-3"}>
                        <Link to={"/forgot"}>Forgot password?</Link>
                    </div>

                    {error && <div className="alert alert-danger mb-3">{error}</div>}
                    <button className="btn btn-primary w-100 py-2" type="submit">Sign In</button>
                </form>
            </main>
        </div>
    );
};
