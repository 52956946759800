import React, {SyntheticEvent, useEffect, useState} from "react";
import axios from "axios";
import {Navigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../redux/store";
import { useUser } from "./UserContext";

export const RegisterLadder = () => {
    const [ladderName, setLadderName] = useState('');
    const [activities, setActivities] = useState<{ id: number; activity: string }[]>([]);
    const [selectedActivity, setSelectedActivity] = useState<number | null>(null);
    const [gametypes, setGametypes] = useState<{ id: number; gametype: string }[]>([]);
    const [selectedGametype, setSelectedGametype] = useState<number | null>(null);
    const [backgroundImage, setBackgroundImage] = useState<File | null>(null);
    const [addressName, setAddressName] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [description, setDescription] = useState('');
    const [ownerId, setOwnerId] = useState<number>(0);
    const [redirect, setRedirect] = useState(false);
    const [error, setError] = useState('');

    const auth = useSelector((state: RootState) => state.auth.value);
    const user = useUser();

    useEffect(() => {
        (async () => {
            if (user) {
                setOwnerId(user.id)
            }
        })();
    }, [user]);

    const US_STATES = [
        "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA",
        "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD",
        "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ",
        "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC",
        "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
    ];

    // Get activity data
    useEffect(() => {
        // Fetch activities from the API
        axios.get('activity')
            .then((response) => {
                setActivities(response.data);
            })
            .catch((error) => {
                console.error("Error fetching activities:", error);
            });
    }, []); // Run this effect once when the component mounts

    const handleActivityChange = (e: any) => {
        const selectedValue = e.target.value;
        setSelectedActivity(selectedValue);
    };

    // Get gametype data
    useEffect(() => {
        // Fetch gametypes from the API
        axios.get('gametype')
            .then((response) => {
                setGametypes(response.data);
            })
            .catch((error) => {
                console.error("Error fetching gametypes:", error);
            });
    }, []); // Run this effect once when the component mounts

    const handleGametypeChange = (e: any) => {
        const selectedValue = e.target.value;
        setSelectedGametype(selectedValue);
    };

    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {
            if (file.size > 33554432) { // 32 MB in bytes
                setError('Image size too large');
                return;
            }
            setBackgroundImage(file);
            setError(''); // Clear any previous errors
        }
    };


    // Submit button
    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        // Check ladder name
        if (!ladderName) {
            setError('Please enter a ladder name.');
            return;
        }

        // Check activity
        if (!selectedActivity) {
            setError('Please select an activity.');
            return;
        }

        // Check gametype
        if (!selectedGametype) {
            setError('Please select a gametype.');
            return;
        }

        // Check ladder image
        if (!backgroundImage) {
            setError('Please select a background image');
            return;
        }

        // Check address line 1
        if (!addressLine1) {
            setError('Please enter an address in Address Line 1.');
            return;
        }

        // Check city
        if (!city) {
            setError('Please enter a city.');
            return;
        }

        // Check state
        if (!state) {
            setError('Please select a state.');
            return;
        }

        // Check zip code
        if (!zipCode) {
            setError('Please enter a zip code.');
            return;
        }

        // Check zip
        if (!/^\d{5}$/.test(zipCode)) {
            setError('Zip code must be 5 digits');
            return;
        }

        // Check ladder description length
        if (description.length < 50) {
            setError('Description must be at least 50 characters.');
            return;
        }

        if (description.length > 3000) {
            setError('Description cannot exceed 3000 characters.');
            return;
        }

        const formData = new FormData();
        formData.append('name', ladderName);
        formData.append('activity', selectedActivity.toString());
        formData.append('gametype', selectedGametype.toString());
        if (backgroundImage) {
            // Get the file extension
            const fileExtension = backgroundImage.name.split('.').pop();

            // Rename the file with the ladder name and its original extension
            const newFileName = `${ladderName}.${fileExtension}`;
            const renamedFile = new File([backgroundImage], newFileName, {
                type: backgroundImage.type,
            });
            formData.append("ladder_image", renamedFile);
        }
        formData.append('address_name', addressName);
        formData.append('address_line_1', addressLine1);
        formData.append('address_line_2', addressLine2);
        formData.append('city', city);
        formData.append('state', state);
        formData.append('zip_code', zipCode);
        formData.append('description', description);
        formData.append('owner', ownerId.toString());

        try {
            const response = await axios.post('register-ladder', formData);

            if (response.status === 200) {
                setRedirect(true);
            } else {
                setError('Ladder name already in use');
            }
        } catch (error) {
            setError('There was an error while creating the ladder.');
        }
    }

    if (redirect) {
        return <Navigate to="/"/>
    }

    return <div>
        { auth &&
            (
                <div className="form-signin-content form-register-ladder-content">
                    <main className="form-signin form-signin-width">
                        <form onSubmit={submit}>
                            <h1 className="h3 mb-3 fw-normal">Create your ladder</h1>

                            <div className="form-floating">
                                <input className="form-control" id="floatingLadderName" placeholder="Ladder Name"
                                       onChange={e => setLadderName(e.target.value)}
                                />
                                <label htmlFor="floatingLadderName">Ladder Name</label>
                            </div>

                            <div className="form-floating">
                                <select
                                    className="form-select"
                                    id="floatingActivity"
                                    value={selectedActivity || ""}
                                    onChange={handleActivityChange}
                                >
                                    <option value="" disabled>Select an Activity</option>
                                    {activities.map((activity) => (
                                        <option key={activity.id} value={activity.id}>
                                            {activity.activity}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="form-floating">
                                <select
                                    className="form-select"
                                    id="floatingActivity"
                                    value={selectedGametype || ""}
                                    onChange={handleGametypeChange}
                                >
                                    <option value="" disabled>Select a Game Type</option>
                                    {gametypes.map((gametype) => (
                                        <option key={gametype.id} value={gametype.id}>
                                            {gametype.gametype}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* Image upload field */}
                            <div className="mb-3">
                                <label htmlFor="imageUpload" className="form-label">
                                    Background Image
                                </label>
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="form-control form-control-image"
                                    id="imageUpload"
                                    onChange={handleImageUpload}
                                />
                            </div>

                            {/* Divider */}
                            <div className="divider"></div>

                            <div className="form-floating">
                                <input className="form-control" id="floatingAddressName" placeholder="Park/Facility Name"
                                       onChange={e => setAddressName(e.target.value)}
                                />
                                <label htmlFor="floatingAddressName">Park/Facility Name</label>
                            </div>

                            <div className="form-floating">
                                <input className="form-control" id="floatingAddressLine1" placeholder="Address Line 1"
                                       onChange={e => setAddressLine1(e.target.value)}
                                />
                                <label htmlFor="floatingAddressLine1">Address Line 1</label>
                            </div>

                            <div className="form-floating">
                                <input className="form-control" id="floatingAddressLine2" placeholder="Address Line 2"
                                       onChange={e => setAddressLine2(e.target.value)}
                                />
                                <label htmlFor="floatingAddressLine2">Address Line 2</label>
                            </div>

                            <div className="form-floating">
                                <input className="form-control" id="floatingCity" placeholder="City"
                                       onChange={e => setCity(e.target.value)}
                                />
                                <label htmlFor="floatingCity">City</label>
                            </div>

                            <div className="form-floating">
                                <select
                                    className="form-select"
                                    id="floatingState"
                                    value={state}
                                    onChange={e => setState(e.target.value)}
                                >
                                    <option value="" disabled>Select a State</option>
                                    {US_STATES.map(s => (
                                        <option key={s} value={s}>
                                            {s}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="form-floating">
                                <input className="form-control" id="floatingZipCode" placeholder="Zip Code"
                                       onChange={e => setZipCode(e.target.value)}
                                />
                                <label htmlFor="floatingZipCode">Zip Code</label>
                            </div>

                            <div className="form-floating">
                                <textarea className="form-control"
                                       id="floatingDescription"
                                       placeholder="Ladder Description"
                                       onChange={e => setDescription(e.target.value)}
                                />
                                <label htmlFor="floatingDescription">Ladder Description</label>
                            </div>

                            {error && <div className="alert alert-danger mb-3">{error}</div>}

                            <button className="registerButton btn btn-primary w-100 py-2" type="submit">Register</button>
                        </form>
                    </main>
                </div>
            )
        }
    </div>
}