import React, { useState, useEffect, useRef, KeyboardEvent, SyntheticEvent } from 'react';
import { useUser } from './UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faClock, faBars } from '@fortawesome/free-solid-svg-icons';
import { useUnreadMessages } from './UnreadMessageProvider';
import { useLocation } from 'react-router-dom';

interface RoomProps {
    chatName: string;
    roomName: string;
    chatSocket: WebSocket;
    toggleSidebar: () => void; // Add the toggleSidebar prop
}

interface ChatMessage {
    message: string;
    first_name: string;
    last_name: string;
    timestamp: string;
}

const Room: React.FC<RoomProps> = ({ chatSocket, roomName, chatName, toggleSidebar }) => {
    const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
    const [message, setMessage] = useState<string>('');
    const [showTimestamps, setShowTimestamps] = useState<boolean>(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true); // State to manage sidebar visibility
    const user = useUser();
    const chatContentRef = useRef<HTMLDivElement>(null);
    const { fetchUnreadMessages } = useUnreadMessages(); // Destructure to include fetchUnreadMessages
    const location = useLocation();

    useEffect(() => {
        setChatMessages([]);
        if ((roomName !== 'General') && (window.innerWidth <= 550)) {
            toggleSidebar();
            setIsSidebarOpen(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomName]);

    const sendMessage = async (e: SyntheticEvent | KeyboardEvent) => {
        e.preventDefault();

        if (message && user) {
            const newMessage = {
                message,
                first_name: user.first_name,
                last_name: user.last_name,
                user_id: user.id,
                timestamp: new Date().toISOString(),
            };
            chatSocket.send(JSON.stringify({ ...newMessage, type: 'chat_message' }));
            setMessage('');
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            sendMessage(e);
        }
    };

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            const { message, first_name, last_name, type, timestamp, silent_message } = JSON.parse(event.data);
            if (type === 'silent_message') {
                console.log(silent_message);
            } else {
                setChatMessages((prevMessages) => [
                    ...prevMessages,
                    { message, first_name, last_name, timestamp },
                ]);
            }
        };

        chatSocket.addEventListener('message', handleMessage);

        return () => {
            chatSocket.removeEventListener('message', handleMessage);
        };
    }, [chatSocket]);

    useEffect(() => {
        if (chatContentRef.current) {
            chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
        }
    }, [chatMessages, showTimestamps]);

    useEffect(() => {
        if (user && user.id) {
            fetchUnreadMessages(user.id);  // Pass the user ID to fetch messages
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const formatLocalTime = (utcTime: string) => {
        if (!utcTime.endsWith('+00:00')) {
            utcTime += '+00:00';
        }
        const localTime = new Date(utcTime);
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
        };
        const formattedLocalTime = localTime.toLocaleString('en-US', options).replace(/(\d+)\/(\d+)\/(\d+)/, '$1/$2/$3');
        return formattedLocalTime
    };

    const handleToggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        toggleSidebar();
    };

    return (
        <div className={`messages-chat-area`}>
            <div className="messages-header">
                <button onClick={handleToggleSidebar} className={`messages-hamburger-button ${isSidebarOpen ? 'open' : ''}`}>
                    <FontAwesomeIcon icon={faBars} />
                </button>
                <h3 className={`messages-header-h3 ${isSidebarOpen ? 'open' : ''}`}>{chatName}</h3>
                <button onClick={() => setShowTimestamps(!showTimestamps)}
                        className={`toggle-timestamp-button toggle-timestamp-button ${isSidebarOpen ? 'open' : ''}`}>
                    <FontAwesomeIcon icon={faClock} />
                </button>
            </div>
            <div className={`messages-chat-content messages-chat-content ${isSidebarOpen ? 'open' : ''}`} ref={chatContentRef}>
                {chatMessages.map((msg, index) => (
                    <div
                        key={index}
                        className={`chat-message ${
                            msg.first_name === user?.first_name && msg.last_name === user?.last_name
                                ? 'my-message'
                                : 'other-message'
                        }`}
                        title={formatLocalTime(msg.timestamp)}
                        data-timestamp={formatLocalTime(msg.timestamp)}
                    >
                        <strong>{msg.first_name} {msg.last_name}:&nbsp;</strong>{msg.message}
                        {showTimestamps && <div className="message-timestamp">{formatLocalTime(msg.timestamp)}</div>}
                    </div>
                ))}
            </div>

            <div className={`messages-input-area messages-input-area ${isSidebarOpen ? 'open' : ''}`}>
                <input
                    type="text"
                    className="form-control messages-input"
                    placeholder="Type a message..."
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <button className="messages-send-button" onClick={sendMessage}>
                    <FontAwesomeIcon icon={faPaperPlane} />
                </button>
            </div>
        </div>
    );
};

export default Room;
