import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

type User = {
    user_id: number;
    rank: number;
    first_name: string;
    last_name: string;
    user_image: string;
    last_match_won: string;
};

const EventMembers: React.FC<{ eventId: string, submitCounter: number }> = ({ eventId, submitCounter }) => {
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(false);

    const USERS_API_ENDPOINT = `event-waitlist-users/${eventId}`;

    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            try {
                const response = await axios.get(USERS_API_ENDPOINT);
                setUsers(response.data);
            } catch (error) {
                console.error("Error fetching users:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, [eventId, USERS_API_ENDPOINT, submitCounter]);

    // Check if there are no users and if so return null
    if (users.length === 0 && !loading) {
        return null;
    }

    return (
        <div className="event_waitlist_members_container">
            <h4 className={'event-page-waitlist-header'}>Waitlist</h4>
            <ul className="ladder_members_ul">
                {users.map(user => (
                    <li key={user.user_id} className="ladder_members_user-pill">
                        <div className="ladder_members_name">
                            <img
                                src={`${user.user_image}`}
                                alt={`${user.first_name} ${user.last_name}`}
                                className="ladder_members_user-image"/>
                            <span>{user.first_name} {user.last_name}</span>
                        </div>
                        <div className="ladder_members_rank-container">
                            {user.last_match_won && <FontAwesomeIcon icon={faArrowUp}
                                                                     className="ladder_members_rank-arrow"/>}
                            {user.rank}
                        </div>
                    </li>
                ))}
            </ul>
            {loading && <p>Loading...</p>}
        </div>
    );
};

export default EventMembers;
