import React, { useState } from 'react';
import axios from 'axios';
import { Navigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

export const DeleteEvent: React.FC = () => {
    const { eventId } = useParams();
    const [deletePhrase, setDeletePhrase] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [error, setError] = useState('');
    const auth = useSelector((state: RootState) => state.auth.value);

    const canDelete = deletePhrase === 'Delete this event';

    const handleDelete = async () => {
        if (!canDelete) {
            setError('You must type the correct phrase to delete this event.');
            return;
        }

        try {
            const response = await axios.delete(`delete-event/${eventId}`);
            if (response.status === 200) {
                setRedirect(true);
            } else {
                setError('Error deleting event.');
            }
        } catch (error) {
            setError('There was an error while deleting the event.');
        }
    };

    if (redirect) {
        return <Navigate to="/" />;
    }

    return <div>
        {auth &&
            <div className="update-ladder-delete">
                <main className="form-signin form-signin-width">
                    <form onSubmit={(e) => e.preventDefault()}>
                        <h1 className="h3 mb-3 fw-normal">Delete Event</h1>

                        <div className="form-floating">
                            <input
                                className="form-control"
                                id="floatingDeletePhrase"
                                placeholder="Delete this event"
                                value={deletePhrase}
                                onChange={e => setDeletePhrase(e.target.value)}
                            />
                            <label htmlFor="floatingDeletePhrase">Type 'Delete this event' to confirm</label>
                        </div>

                        {error && <div className="alert alert-danger mb-3">{error}</div>}

                        <button
                            className="deleteAccountButton btn btn-danger w-100 py-2"
                            type="submit"
                            onClick={handleDelete}
                            disabled={!canDelete}
                        >
                            Delete Event
                        </button>
                    </form>
                </main>
            </div>
        }
    </div>;
};
