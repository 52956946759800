import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faSync, faMapMarkerAlt, faRunning,
    faClock, faArrowLeft, faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import EventMembers from './EventMembers';
import EventMatchups from './EventMatchups';
import PlayerMatchup from './PlayerMatchup';
import TennisScoreboard from './TennisScoreboard';
import { useUser } from './UserContext';
import EventWaitlistMembers from './EventWaitlistMembers';
import TennisScoreboardDoubles from './TennisScoreboardDoubles';

type RouteParams = {
    eventId: string;
}

type Event = {
    id: number;
    event_name: string;
    creation_date: string;
    activity: string;
    gametype: string;
    ladder_id: number;
    ladder_name: string;
    ladder_image: string;
    address_name: string;
    address_line_1: string;
    address_line_2: string | null;
    city: string;
    state: string;
    zip_code: number;
    latitude: number;
    longitude: number;
    distance: number;
    owner: number;
    user_count: number;
    description: string;
    weekday: string;
    reoccurring: boolean;
    time: string;
    max_num_users: number;
    num_hours_lock_event: number;
    court1: string;
    court2: string;
    court3: string;
    court4: string;
    court5: string;
    court6: string;
    court7: string;
    court8: string;
    court9: string;
    court10: string;
};

// Define a type for the days of the week
type Weekdays = "Sunday" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday";

// Define a mapping from weekdays to their corresponding numbers
const daysOfWeek: Record<Weekdays, number> = {
    "Sunday": 0,
    "Monday": 1,
    "Tuesday": 2,
    "Wednesday": 3,
    "Thursday": 4,
    "Friday": 5,
    "Saturday": 6
};

export const EventPage: React.FC = () => {
    const navigate = useNavigate(); // Initialize the useNavigate hook
    const { eventId = "" } = useParams<RouteParams>();
    const [userId, setUserId] = useState<number | null>(null);
    const [eventItem, setEventItem] = useState<Event | null>(null);
    const [member, setMember] = useState<boolean | null>(null);
    const [snackbar, setSnackbar] = useState<{ show: boolean; message: string }>({
        show: false,
        message: ''
    });
    const [matchInProgress, setMatchInProgress] = useState<boolean>(false);
    const [submitCounter, setSubmitCounter] = useState(0);
    const [matchHistory, setMatchHistory] = useState([]);
    const [ownerData, setOwnerData] = useState<any | null>(null);
    const user = useUser();
    const [showVenmoQr, setShowVenmoQr] = useState(false);
    const [showZelleQr, setShowZelleQr] = useState(false);

    // User Id
    useEffect(() => {
        (async () => {
            if (user) {
                setUserId(user.id)
            }
        })();
    }, [user]);

    // Event Data
    useEffect(() => {
        const fetchEventData = async () => {
            if (eventId) {
                try {
                    const response = await axios.post(`retrieve-event`, {
                        event_id: eventId
                    });
                    if (response.status === 200) {
                        setEventItem(response.data);
                    }
                } catch (error) {
                    console.error("Error fetching event data:", error);
                }
            }
        };

        // Now invoke the async function
        fetchEventData().then(() => {
            // Whatever you'd like to execute after fetchEventData completes
            console.log("Event data fetched successfully!");
        });

        // No return here unless you have a cleanup function.
    }, [eventId]);

    // User Data
    useEffect(() => {
        if (eventItem) {
            const fetchUserData = async () => {
                try {
                    const response = await axios.get(`user-basic-info/${eventItem.owner}`);
                    if (response.status === 200) {
                        setOwnerData(response.data)
                    }
                }
                catch (e) {
                    console.log(e)
                }
            };
            // Now invoke the async function
            fetchUserData().then(() => {
                // Whatever you'd like to execute after fetchEventData completes
                console.log("User data fetched successfully!")
            });
        }
    }, [eventItem]);

    // Check membership
    useEffect(() => {
        const checkMembership = async () => {
            if (userId && eventItem) {
                try {
                    const response = await axios.post('check-event-membership', {
                        user_id: userId,
                        event_id: eventItem.id
                    });
                    if (response.status === 200) {
                        setMember(response.data.membership);
                    }
                } catch (error) {
                    console.error("Error checking membership:", error);
                }
            }
        };

        checkMembership().then(() => {
            console.log("Membership data fetched successfully!");
        });
    }, [userId, eventItem, member]);


    // Check if match is in progress
    useEffect(() => {
        const checkMatchInProgress = async () => {
            if (eventId) {
                try {
                    const response = await axios.get(`event-in-progress/${eventId}`);
                    if (response.status === 200 && response.data.is_ongoing !== undefined) {
                        setMatchInProgress(response.data.is_ongoing);
                    } else {
                        setMatchInProgress(false);
                    }
                } catch (error) {
                    console.error("Error checking if match is in progress:", error);
                    setMatchInProgress(false);
                }
            }
        };

        checkMatchInProgress();
    }, [eventId]);

    // New useEffect for fetching match history
    useEffect(() => {
        const fetchMatchHistory = async () => {
            if (!eventItem) {
                return
            }

            const matchDate = convertDateFormat(
                calculateEventDate(eventItem.weekday as Weekdays, eventItem.creation_date, eventItem.reoccurring)
            );

            if (eventId && matchDate) {
                try {
                    const response = await axios.get(`match-history-by-event/${eventId}/${matchDate}`);
                    if (response.status === 200) {
                        setMatchHistory(response.data);
                    } else {
                        console.error('Failed to fetch match history', response.status);
                    }
                } catch (error) {
                    console.error('Error fetching match history:', error);
                }
            }
        };

        fetchMatchHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitCounter, eventItem]);

    if (!eventItem) return <p>Loading...</p>;

    // Function to handle the button click
    const handleJoinClick = async () => {
        if (!userId || !eventItem) return;

        // Define the endpoint URL based on whether the user is already a member
        const endpoint = member ?
            'remove-user-from-event' :
            'add-user-to-event';

        try {
            const response = await axios.post(endpoint, {
                user_id: userId,
                event_id: eventItem.id
            });

            if (response.status === 200) {
                // Determine the snackbar message based on whether the user is already a member
                const snackbarMessage = member
                    ? "You've been removed from the event."
                    : "You've successfully joined the event!";

                // Set the snackbar message and display it
                setSnackbar({
                    show: true,
                    message: snackbarMessage
                });

                // Hide the snackbar after 3 seconds
                setTimeout(() => {
                    setSnackbar({ show: false, message: '' });
                }, 3000);

                setMember(!member);

                // Call the API to recalculate the rank
                await axios.patch(`recalculate-rank/${eventItem.ladder_id}`);
                onScoreSubmit()
            }
            else {
                setSnackbar({ show: true, message: 'You must first join a ladder before signing up for an event.' });
                setTimeout(() => setSnackbar({ show: false, message: '' }), 3000);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };
    
    // Helper function to format the date
    const formatDate = (date: Date): string => {
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    };

    // Function to calculate the event date
    const calculateEventDate = (weekday: Weekdays, creation_date: string, reoccurring: boolean): string => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0); // Ignore time component for comparison

        creation_date = creation_date + 'T00:00:00'; // Append time component
        const creationDate = new Date(creation_date);

        // If the event is not reoccurring and the creation date is in the future
        if (!reoccurring && currentDate <= creationDate) {
            return formatDate(creationDate); // Use the creation date
        }

        // For reoccurring events, calculate the next occurrence
        const currentDayOfWeek = currentDate.getDay();
        const eventDayOfWeek = daysOfWeek[weekday];

        let daysToAdd = eventDayOfWeek - currentDayOfWeek;
        if (daysToAdd < 0) {
            daysToAdd += 7; // Ensure it's always a future date or the next occurrence for reoccurring events
        }

        const eventDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + daysToAdd);
        return formatDate(eventDate);
    };

    // Convert to more friendly time hh:mm:ss -> hh:mm am/pm
    function convertToUserFriendlyTime(timeStr: string): string {
        // Parse hours, minutes, and seconds from the input string
        const [hours, minutes] = timeStr.split(':').map(Number);

        // Convert to 12-hour format and determine AM/PM
        const isPM = hours >= 12;
        const convertedHours = hours % 12 || 12; // To handle 00:xx:xx and 12:xx:xx

        // Construct the user-friendly time string
        return `${convertedHours}:${minutes.toString().padStart(2, '0')} ${isPM ? 'pm' : 'am'}`;
    }

    // Display Today or Tomorrow if the weekday is Today or Tomorrow
    function getDisplayDay(weekday: string, creationDateStr: string, reoccurring: boolean): string {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Reset time to start of the day

        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);

        creationDateStr = creationDateStr + 'T00:00:00'; // Append time component
        const creationDate = new Date(creationDateStr);
        creationDate.setHours(0, 0, 0, 0); // Reset time to start of the day

        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        const nextWeek = new Date(today);
        nextWeek.setDate(nextWeek.getDate() + 6);

        if (creationDate.getTime() > nextWeek.getTime()) {
            reoccurring = false;
        }

        if (!reoccurring) {
            if (creationDate.getTime() === today.getTime()) {
                return "today";
            } else if (creationDate.getTime() === tomorrow.getTime()) {
                return "tomorrow";
            }
            // Return the weekday if the event is not today or tomorrow
            return weekday;
        }

        // For reoccurring events, return today or tomorrow if applicable, otherwise return the weekday
        const currentDay = daysOfWeek[today.getDay()];
        if (weekday === currentDay) {
            return "today";
        } else if (daysOfWeek[(today.getDay() + 1) % 7] === weekday) {
            return "tomorrow";
        }

        return weekday;
    }

    // Helper function to convert date from "June 1, 2024" to "2024-6-1"
    const convertDateFormat = (dateStr:any) => {
        const dateParts = dateStr.split(' '); // Split the date string by spaces
        const month = new Date(dateParts[0] + " 1, 2000").getMonth() + 1; // Get month number
        const day = dateParts[1].replace(',', ''); // Remove comma from day
        const year = dateParts[2]; // Year is the third part

        return `${year}-${month}-${day}`; // Format and return the new date string
    };

    // Function to navigate back
    const handleBackClick = () => {
        navigate(`/ladder-page/${eventItem.ladder_id}`);
    };

    // Inside EventPage component

    const onScoreSubmit = () => {
        setSubmitCounter(prevCount => prevCount + 1);
    };

    const handleNavLinkClick = (event:any) => {
        event.preventDefault();
        const targetId = event.target.getAttribute('href').slice(1); // Get the ID from href
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    function getDisplayDate(weekday: string, creationDateStr: string, reoccurring: boolean): string {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Reset time to start of the day

        const nextWeek = new Date(today);
        nextWeek.setDate(nextWeek.getDate() + 6);

        creationDateStr = creationDateStr + 'T00:00:00'; // Append time component
        const creationDate = new Date(creationDateStr);
        creationDate.setHours(0, 0, 0, 0); // Reset time to start of the day

        // Format a date object into mm/dd/yyyy
        const formatDate = (date: Date): string => {
            const mm = (date.getMonth() + 1).toString();
            const dd = date.getDate().toString();
            const yyyy = date.getFullYear();
            return `${mm}/${dd}/${yyyy}`;
        };

        if (!reoccurring || creationDate.getTime() > nextWeek.getTime()) {
            // If not recurring or the date is more than a week out, return the creation date
            return formatDate(creationDate);
        }

        // If reoccurring and less than 1 week out
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const targetDayIndex = daysOfWeek.indexOf(weekday);
        const currentDayIndex = today.getDay();

        if (targetDayIndex === currentDayIndex) {
            return formatDate(today);
        } else {
            const daysToAdd = (targetDayIndex - currentDayIndex + 7) % 7;
            const targetDate = new Date(today);
            targetDate.setDate(today.getDate() + daysToAdd);
            return formatDate(targetDate);
        }
    }

    const toggleVenmoQr = () => {
        setShowVenmoQr(!showVenmoQr);
    };

    const toggleZelleQr = () => {
        setShowZelleQr(!showZelleQr);
    };

    // Modify the nav links to use onClick in React
    <a href="#event-page-going" className="event-page-nav-link" onClick={handleNavLinkClick}>Going</a>

    return (
        <div className="event-page">
            {/* Back Button */}
            <button onClick={handleBackClick} className="back-to-ladder-button">
                <FontAwesomeIcon icon={faArrowLeft}/>
                <span>Go to Ladder</span>
            </button>

            <div className="ladder-page-container">
                <img
                    src={eventItem.ladder_image}
                    alt={eventItem.event_name}
                    className="ladder-page-image"
                />

                <div className="event-page-navigation">
                    <a href="#event-page-going" className="event-page-nav-link nav-items">Going</a>
                    <a href="#event-page-matches" className="event-page-nav-link nav-items">Matches</a>
                    <a href="#event-page-your-match" className="event-page-nav-link nav-items">Your Match</a>
                </div>

                <div className="ladder-page-info">
                    {/* Event Name */}
                    <div className="info-row">
                        <div className="icon-container">
                            {/* This empty container will provide the spacing to align the ladder name */}
                        </div>
                        <h2 className="ladder-name">{eventItem.event_name}</h2>
                    </div>

                    {/* Event/Sport */}
                    <div className="info-row">
                        <div className="icon-container">
                            <FontAwesomeIcon icon={faRunning}/>
                        </div>
                        <p className="ladder-page-para">{eventItem.activity}</p>
                    </div>

                    {/* Game Type */}
                    <div className="info-row">
                        <div className="icon-container">
                            <FontAwesomeIcon icon={faSync}/>
                        </div>
                        <p className="ladder-page-para">{eventItem.gametype}</p>
                    </div>

                    {/* Address Info */}
                    <div className="info-row">
                        <div className="icon-container">
                            <FontAwesomeIcon icon={faMapMarkerAlt}/>
                        </div>
                        <div>
                            <p className={"ladder-page-para"}>
                                {eventItem.address_name} <br/>
                                {eventItem.address_line_1} &nbsp;
                                {eventItem.address_line_2} <br/>
                                {eventItem.city}, {eventItem.state}, {eventItem.zip_code}
                            </p>
                        </div>
                    </div>

                    {/* Time */}
                    <div className="info-row">
                        <div className="icon-container">
                            <FontAwesomeIcon icon={faClock}/>
                        </div>
                        <div>
                            <p className={"ladder-page-para"}>
                                {eventItem.weekday}, {getDisplayDate(eventItem.weekday,
                                eventItem.creation_date, eventItem.reoccurring)
                            } <br/>
                                {convertToUserFriendlyTime(eventItem.time)} <br/>
                            </p>
                        </div>
                    </div>

                    {/* User Count Info */}
                    <div className="info-row">
                        <div className="icon-container">
                            <FontAwesomeIcon icon={faUsers}/>
                        </div>
                        <p className="ladder-page-para">{eventItem.user_count} going</p>
                    </div>

                    {/* Number of spots */}
                    <div className="info-row">
                        <div className="icon-container">
                            <FontAwesomeIcon icon={faTicketAlt}/>
                        </div>
                        <p className="ladder-page-para">
                            {eventItem.max_num_users ? ` ${eventItem.max_num_users} spots` : '20+'}
                        </p>
                    </div>

                    {/* Join/Joined Button */}
                    <div className="info-row">
                        <div className="info-row">
                            {
                                matchInProgress ?
                                    <div className="joined-button locked-button">
                                        Locked
                                    </div>
                                    :
                                    (
                                        member !== null && (
                                            <button className={member ? "joined-button" : "join-button"}
                                                    onClick={handleJoinClick}>
                                                {member ? "Going" : "Sign-up"}
                                            </button>
                                        )
                                    )
                            }
                            {member !== null && (
                                <Link
                                    to={`/message/ladder/${eventItem?.ladder_id}/${encodeURIComponent(eventItem?.ladder_name)}`}
                                    className="ladder-page-update-ladder ladder-page-go-to-chat">
                                    Go to Chat
                                </Link>
                            )}
                        </div>
                    </div>
                    <div className="info-row">
                        <div className="info-row">
                            {
                                matchInProgress ?
                                    <p className="event_page-note-to-players">
                                        Matchups are locked. Good luck, have fun!
                                    </p>
                                    :
                                    eventItem.num_hours_lock_event === null ? (
                                        <p className="event_page-note-to-players">
                                            Matchups will
                                            lock {getDisplayDay(eventItem.weekday, eventItem.creation_date, eventItem.reoccurring)} at {" "}
                                            {convertToUserFriendlyTime(eventItem.time)}
                                        </p>
                                    ) : (
                                        <p className="event_page-note-to-players">
                                            Matchups will
                                            lock {eventItem.num_hours_lock_event} {eventItem.num_hours_lock_event === 1 ? 'hour' : 'hours'} before
                                            event starts
                                        </p>
                                    )
                            }
                        </div>
                    </div>

                    <div className="info-row">
                        {ownerData && member && (
                            <div>
                                <h4>Host</h4>
                                <div className="ladder_members_host-pill">
                                    {showVenmoQr ? (
                                        <div className="venmo-qr-container">
                                            <a href={`https://venmo.com/u/${ownerData.venmo_handle}`}
                                               target="_blank"
                                               rel="noopener noreferrer">
                                                    <img src={`${ownerData.venmo_qr}`}
                                                         alt={ownerData.venmo_qr}
                                                         className="venmo-qr-container"/>
                                            </a>
                                                <button className="close-qr" onClick={toggleVenmoQr}>×</button>
                                        </div>
                                        ) : showZelleQr ? (
                                        <div className="venmo-qr-container">
                                        <img src={`${ownerData.zelle_qr}`}
                                                 alt={ownerData.zelle_qr}
                                                 className="venmo-qr-container"/>
                                            <button className="close-qr" onClick={toggleZelleQr}>×</button>
                                        </div>
                                    ) : (
                                        <div className="ladder-page-container-host">
                                            <div>
                                                <div className="ladder_members_name">
                                                    <Link to={`/user-profile/${ownerData.id}`}
                                                          className={"profile-custom-link"}>
                                                        <img src={`${ownerData.user_image}`}
                                                             alt={`${ownerData.first_name} ${ownerData.last_name}`}
                                                             className="ladder_members_user-image"/>
                                                        <span>{ownerData.first_name} {ownerData.last_name}</span>
                                                    </Link>
                                                </div>
                                                <Link
                                                    to={`/message/user/${userId}/${eventItem?.owner}/${encodeURIComponent(ownerData.first_name + ' ' + ownerData.last_name)}`}
                                                    className="ladder-page-update-ladder ladder-page-go-to-chat ladder-page-go-to-chat-host">
                                                    Message Host
                                                </Link>
                                            </div>
                                            <div>
                                                <div className="venmo-link-container" onClick={toggleVenmoQr}>
                                                    <div className={`venmo-icon`}>
                                                        {/* Image handled by CSS */}
                                                    </div>
                                                </div>
                                                <div className="zelle-link-container" onClick={toggleZelleQr}>
                                                    <div className={`zelle-icon`}>
                                                        {/* Image handled by CSS */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Update Event */}
                    {
                        userId === eventItem.owner && (
                            <div>
                                <div className="info-row ladder-page-host-options">
                                    <div className="info-row">
                                        <h4 className={""}>Host Options</h4>
                                    </div>
                                </div>
                                <div className="info-row ladder-page-host-options">
                                    <div className="info-row">
                                        <Link to={`/update-event/${eventItem.id}`}
                                              className="ladder-page-update-ladder">
                                            Update Event
                                        </Link>
                                    </div>
                                    <div className="info-row">
                                        <Link to={`/event-host-options/${eventItem.id}/${eventItem.ladder_id}`}
                                              className="ladder-page-update-ladder">
                                            Host Options
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>

            <div className="ladder-page-container2">
                <div className="ladder-page-description" id="event-page-going">
                    <EventMembers eventId={eventId} submitCounter={submitCounter}/>
                    <EventWaitlistMembers eventId={eventId} submitCounter={submitCounter}/>
                </div>
                <div className="ladder-page-description" id="event-page-matches">
                    <EventMatchups eventId={eventId}
                                   gametype={eventItem.gametype}
                                   submitCounter={submitCounter}
                                   eventItem={eventItem}
                                   matchHistory={matchHistory}/>
                </div>
                <div className="ladder-page-description" id="event-page-your-match">
                    {
                        userId != null ? (
                            <PlayerMatchup eventId={eventId}
                                           gametype={eventItem.gametype}
                                           userId={userId.toString()}
                                           submitCounter={submitCounter}
                                           eventItem={eventItem}
                                           matchHistory={matchHistory}/>
                        ) : (
                            <p className={'event_page-note-to-players'}>You must be logged in to view your match up</p>
                        )
                    }

                    {userId != null && eventItem && (
                        eventItem.gametype === "Singles" ? (
                            <TennisScoreboard
                                eventId={eventId}
                                userId={userId.toString()}
                                eventItem={eventItem}
                                onScoreSubmit={onScoreSubmit} // Passing the callback function as a prop
                                submitCounter={submitCounter}
                            />
                        ) : (
                            <TennisScoreboardDoubles
                                eventId={eventId}
                                userId={userId.toString()}
                                eventItem={eventItem}
                                onScoreSubmit={onScoreSubmit} // Passing the callback function as a prop
                                submitCounter={submitCounter}
                            />
                        )
                    )}
                </div>
            </div>

            {/*Snackbar*/}
            {snackbar.show && (
                <div className="snackbar">
                    {snackbar.message}
                </div>
            )}
        </div>
    );
};
