import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Room from './MessageRoom';
import { useUser } from './UserContext';
import { useLocation } from 'react-router-dom';
import { useUnreadMessages } from './UnreadMessageProvider';

type Ladder = {
    id: number;
    name: string;
};

type User = {
    id: number;
    first_name: string;
    last_name: string;
};

export const Messages = () => {
    const [ladderNames, setLadderNames] = useState<{ id: number; name: string; formatted_name: string; }[]>([]);
    const [userNames, setUserNames] = useState<{ id: number; name: string; formatted_name: string; }[]>([]);
    const [recentRooms, setRecentRooms] = useState<string[]>([]);
    const [filteredRecentRooms, setFilteredRecentRooms] = useState<{ id: number; name: string; formatted_name: string; type: string; }[]>([]);
    const [chatName, setChatName] = useState('');
    const [selectedChat, setSelectedChat] = useState<string | null>(null);
    const [chatSocket, setChatSocket] = useState<WebSocket | null>(null);
    const [selectedRoom, setSelectedRoom] = useState('');
    const [showSidebar, setShowSidebar] = useState<boolean>(true); // State to manage sidebar visibility
    const user = useUser();
    const { unreadMessages, fetchUnreadMessages } = useUnreadMessages(); // Destructure to include fetchUnreadMessages
    const location = useLocation();

    const WS_URL = `${process.env.REACT_APP_API_BASE_WS}/ws/`;

    const formatLadderName = (id: number) => `ladder_${id}`;

    const formatUserName = (userId1: number, userId2: number) => {
        const minId = Math.min(userId1, userId2);
        const maxId = Math.max(userId1, userId2);
        return `user_${minId}_${maxId}`;
    };

    useEffect(() => {
        if (user) {
            axios.get(`user-rooms/${user.id}`)
                .then(response => {
                    setRecentRooms(response.data);
                })
                .catch(error => {
                    console.error("Error fetching recent rooms:", error);
                });
        }
    }, [user]);

    useEffect(() => {
        if (selectedRoom && (selectedRoom === "Ladders" || selectedRoom === "Direct Messages" || selectedRoom === "Recent") && user) {
            const fetchData = async () => {
                try {
                    const laddersResponse = await axios.post('ladders-within-radius', {
                        user_id: user.id,
                        radius: 3000,
                        activity_id: null,
                        filter_on_user_id: 1,
                        hosted_ladders: 0
                    });

                    const ladders = laddersResponse.data.map((ladder: Ladder) => ({
                        id: ladder.id,
                        name: ladder.name,
                        formatted_name: formatLadderName(ladder.id)
                    }));
                    setLadderNames(ladders);

                    const users = await fetchUsersFromLadders(ladders);

                    if (selectedRoom === "Recent") {
                        filterRecentRooms(recentRooms, ladders, users);
                    }
                } catch (error) {
                    console.error("Error fetching ladders:", error);
                }
            };

            fetchData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRoom, user, recentRooms]);

    useEffect(() => {
        if (user && user.id) {
            fetchUnreadMessages(user.id);  // Pass the user ID to fetch messages
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, chatName]);

    const fetchUsersFromLadders = async (ladders: Ladder[]) => {
        if (!user) return [];
        const userRequests = ladders.map(ladder => axios.get(`ladder-users/${ladder.id}`));

        try {
            const responses = await Promise.all(userRequests);
            const usersMap: { [key: number]: string; } = {};

            responses.forEach(response => {
                response.data.forEach((ladderUser: User) => {
                    if (ladderUser.id !== user.id) { // Exclude the logged-in user
                        usersMap[ladderUser.id] = `${ladderUser.first_name} ${ladderUser.last_name}`;
                    }
                });
            });

            const uniqueUsers = Object.keys(usersMap).map(id => ({
                id: Number(id),
                name: usersMap[Number(id)],
                formatted_name: formatUserName(user.id, Number(id))
            }));

            setUserNames(uniqueUsers);
            return uniqueUsers;
        } catch (error) {
            console.error("Error fetching users:", error);
            return [];
        }
    };

    const filterRecentRooms = (recentRooms: string[], ladders: { id: number; name: string; formatted_name: string; }[], users: { id: number; name: string; formatted_name: string; }[]) => {
        const matchedRooms = recentRooms.reduce((acc: { id: number; name: string; formatted_name: string; type: string; }[], room) => {
            const matchedLadder = ladders.find(ladder => ladder.formatted_name === room);
            if (matchedLadder) {
                acc.push({ ...matchedLadder, type: 'ladder' });
            } else {
                const matchedUser = users.find(user => user.formatted_name === room);
                if (matchedUser) {
                    acc.push({ ...matchedUser, type: 'user' });
                }
            }
            return acc;
        }, []);
        setFilteredRecentRooms(matchedRooms);
    };

    useEffect(() => {
        if (selectedChat && WS_URL) {
            const newSocket = new WebSocket(`${WS_URL}messages/${selectedChat}/`);
            newSocket.onopen = () => {
                const token = localStorage.getItem("token");
                if (token) {
                    newSocket.send(JSON.stringify({ type: 'authenticate', token: token }));
                }
            };
            newSocket.onclose = () => {
                console.log("WebSocket connection closed unexpectedly.");
            };
            setChatSocket(newSocket);

            return () => {
                if (newSocket.readyState === WebSocket.OPEN) {
                    newSocket.close();
                }
            };
        }
    }, [selectedChat, WS_URL]);

    const handleRoomSelection = (room: string, chatName?: string, id?: number, formatted_name?: string) => {
        setSelectedRoom(room);
        if (room === 'General') {
            setChatName('General');
            setSelectedChat('General');
        } else if (room === 'Ladders' && chatName && id) {
            const formattedLadderName = formatLadderName(id);
            setChatName(chatName);
            setSelectedChat(formattedLadderName);
        } else if (room === 'Direct Messages' && chatName && id && user) {
            const formattedUserName = formatUserName(user.id, id);
            setChatName(chatName);
            setSelectedChat(formattedUserName);
        } else if (room === 'Recent' && formatted_name) {
            setChatName(chatName || '');
            setSelectedChat(formatted_name);
        } else {
            setChatName('');
            setSelectedChat(null);
        }
    };

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    return (
        <div className="messages-container">
            {showSidebar && (
                <div className="messages-sidebar">
                    <div className="messages-tab" onClick={() => handleRoomSelection('General')}>General</div>
                    <div className="messages-tab" onClick={() => handleRoomSelection('Recent')}>
                        Recent
                        {Object.keys(unreadMessages).some(room => unreadMessages[room] > 0) ? <span className="unread-indicator"></span> : null}
                    </div>
                    <div className="messages-tab" onClick={() => handleRoomSelection('Ladders')}>
                        Ladders
                    </div>
                    <div className="messages-tab" onClick={() => handleRoomSelection('Direct Messages')}>
                        Direct Messages
                    </div>
                    <div className="messages-rooms-list">
                        {selectedRoom === 'Ladders' && ladderNames.map((ladder) => (
                            <div key={`ladder-${ladder.id}`} className="messages-room" onClick={() => handleRoomSelection('Ladders', ladder.name, ladder.id)}>
                                {ladder.name}
                            </div>
                        ))}
                        {selectedRoom === 'Direct Messages' && userNames.map((user) => (
                            <div key={`user-${user.id}`} className="messages-room" onClick={() => handleRoomSelection('Direct Messages', user.name, user.id)}>
                                {user.name}
                            </div>
                        ))}
                        {selectedRoom === 'Recent' && filteredRecentRooms.map((room) => (
                            <div key={`${room.type}-${room.id}`} className="messages-room" onClick={() => handleRoomSelection('Recent', room.name, room.id, room.formatted_name)}>
                                {room.name}
                                {unreadMessages[room.formatted_name] > 0 && <span className="unread-indicator"></span>}
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {chatSocket && selectedChat && 
                <Room chatSocket={chatSocket} 
                      roomName={selectedChat} 
                      chatName={chatName} 
                      toggleSidebar={toggleSidebar}
                />}
        </div>
    );
};

export default Messages;
