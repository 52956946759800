import React, {SyntheticEvent, useEffect, useState} from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useUser } from "./UserContext";
import {setAuth} from "../redux/authSlice";
import { useDispatch } from "react-redux";

export const AccountInfo = () => {
    const user = useUser();

    // Initialize state with default empty values
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [error, setError] = useState('');
    const dispatch = useDispatch();

    // Effect to update state when user object changes
    useEffect(() => {
        if (user) {
            setFirstName(user.first_name);
            setLastName(user.last_name);
            setAddressLine1(user.address_line_1);
            setAddressLine2(user.address_line_2 || '');
            setCity(user.city);
            setState(user.state);
            setZipCode(user.zip_code.toString());
        }
    }, [user]); // Only re-run the effect if user changes

    const US_STATES = [
        "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA",
        "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD",
        "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ",
        "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC",
        "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
    ];

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        // Check first name
        if (!firstName) {
            setError('Please enter your first name.');
            return;
        }

        // Check last name
        if (!lastName) {
            setError('Please enter your last name.');
            return;
        }

        // Check address line 1
        if (!addressLine1) {
            setError('Please enter an address in Address Line 1.');
            return;
        }

        // Check city
        if (!city) {
            setError('Please enter a city.');
            return;
        }

        // Check state
        if (!state) {
            setError('Please select a state.');
            return;
        }

        // Check zip code
        if (!zipCode) {
            setError('Please enter a zip code.');
            return;
        }
        if (!/^\d{5}(-\d{4})?$/.test(zipCode)) {
            setError('Please enter a valid zip code. Format: 12345');
            return;
        }

        const userData = {
            first_name: firstName,
            last_name: lastName,
            address_line_1: addressLine1,
            address_line_2: addressLine2,
            city: city,
            state: state,
            zip_code: zipCode,
            // user_image: userImage // Handle image separately if needed
        };

        try {
            const response = await axios.put('update-user', userData);
            if (response.status === 200) {
                dispatch(setAuth(false));
            } else {
                setError('Ladder name already in use');
            }
        } catch (error) {
            setError('There was an error while creating the ladder.');
        }
    }

    return (
        <div className="form-account-content">
            <main className="form-signin form-signin-width form-signin-min-width">
                <form onSubmit={submit}>
                    <h1 className="h3 mb-3 fw-normal">Account Info</h1>

                    <div className="form-floating">
                        <input className="form-control"
                               id="floatingFirstName"
                               value={firstName}
                               onChange={e => setFirstName(e.target.value)}
                        />
                        <label htmlFor="floatingFirstName">First Name</label>
                    </div>

                    <div className="form-floating">
                        <input className="form-control"
                               id="floatingLastName"
                               value={lastName}
                               onChange={e => setLastName(e.target.value)}
                        />
                        <label htmlFor="floatingLastName">Last Name</label>
                    </div>

                    <div className="form-floating">
                        <input className="form-control"
                               id="floatingAddressLine1"
                               value={addressLine1}
                               onChange={e => setAddressLine1(e.target.value)}
                        />
                        <label htmlFor="floatingAddressLine1">Address Line 1</label>
                    </div>

                    <div className="form-floating">
                        <input className="form-control"
                               id="floatingAddressLine2"
                               value={addressLine2}
                               onChange={e => setAddressLine2(e.target.value)}
                        />
                        <label htmlFor="floatingAddressLine2">Address Line 2</label>
                    </div>

                    <div className="form-floating">
                        <input className="form-control"
                               id="floatingCity"
                               value={city}
                               onChange={e => setCity(e.target.value)}
                        />
                        <label htmlFor="floatingCity">City</label>
                    </div>

                    <div className="form-floating">
                        <select
                            className="form-select"
                            id="floatingState"
                            value={state}
                            onChange={e => setState(e.target.value)}
                        >
                            <option value="" disabled>Select a State</option>
                            {US_STATES.map(s => (
                                <option key={s} value={s}>
                                    {s}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="form-floating">
                        <input className="form-control"
                               id="floatingZipCode"
                               value={zipCode}
                               onChange={e => setZipCode(e.target.value)}
                        />
                        <label htmlFor="floatingZipCode">Zip Code</label>
                    </div>

                    {error && <div className="alert alert-danger mb-3">{error}</div>}

                    <button className="registerButton btn btn-primary w-100 py-2" type="submit">Update</button>

                    <div className={"mb-3"}>
                        <Link to={"/forgot"}>Change Password</Link>
                    </div>
                </form>
            </main>
        </div>
    )
}