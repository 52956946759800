import React from 'react';
export const Footer = () => {
    return (
        <footer className="footer-p-3 bg-dark text-white text-center footer-container">
            <div className="footer-content">
                <p>&copy; {new Date().getFullYear()} LadderUpSports</p>
                {/*<a href="https://www.facebook.com" className="footer-text-white me-2 footer-social-icon">*/}
                {/*    <i className="bi bi-facebook"></i>*/}
                {/*</a>*/}
                <a href="https://www.instagram.com/ladderupsports/" className="footer-text-white me-2 footer-social-icon">
                    <i className="bi bi-instagram"></i>
                </a>
                {/*<a href="https://www.twitter.com" className="footer-text-white me-2 footer-social-icon">*/}
                {/*    <i className="bi bi-twitter"></i>*/}
                {/*</a>*/}
                <p>
                    Contact us at
                    <a href="mailto:chengs.ladderupsports@gmail.com" className="footer-text-white">
                        chengs.ladderupsports@gmail.com
                    </a>
                </p>
            </div>
        </footer>
    );
}
