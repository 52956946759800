import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

type User = {
    id: number;
    rank: number;
    first_name: string;
    last_name: string;
    user_image: string;
    last_match_won: boolean;
};

const LadderMembers: React.FC<{ ladderId: string, submitCounter: number }> = ({ ladderId, submitCounter }) => {
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(false);

    const USERS_API_ENDPOINT = `ladder-users/${ladderId}`;

    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            try {
                const response = await axios.get(USERS_API_ENDPOINT);
                setUsers(response.data);
            } catch (error) {
                console.error("Error fetching users:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, [ladderId, USERS_API_ENDPOINT, submitCounter]);

    return (
        <div className="ladder_members_container">
            <ul className="ladder_members_ul ladder_members_ul_height">
                {users.map(user => (
                    <li key={user.id} className="ladder_members_user-pill">
                        <div className="ladder_members_name">
                            <Link to={`/user-profile/${user.id}`} className={"profile-custom-link"}>
                                <img src={`${user.user_image}`} alt={`${user.first_name} ${user.last_name}`} className="ladder_members_user-image"/>
                                <span>{user.first_name} {user.last_name}</span>
                            </Link>
                        </div>
                        <div className="ladder_members_rank-container">
                            {user.last_match_won && <FontAwesomeIcon icon={faArrowUp} className="ladder_members_rank-arrow" />}
                            {user.rank}
                        </div>
                    </li>
                ))}
            </ul>
            {loading && <p>Loading...</p>}
        </div>
    );
};

export default LadderMembers;
