import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown } from '@fortawesome/free-solid-svg-icons';

type Player = {
    user_id: number;
    first_name: string;
    last_name: string;
    user_image: string;
    rank: number;
    last_match_won: boolean;
    isWinner?: boolean;
};

type Event = {
    id: number;
    event_name: string;
    activity: string;
    gametype: string;
    ladder_id: number;
    ladder_image: string;
    address_name: string;
    address_line_1: string;
    address_line_2: string | null;
    city: string;
    state: string;
    zip_code: number;
    latitude: number;
    longitude: number;
    distance: number;
    owner: number;
    user_count: number;
    description: string;
    weekday: string;
    time: string;
    creation_date: string;
    reoccurring: boolean;
};

type Scores = {
    [set: string]: [string, string, string?];
};

type TennisScoreboardProps = {
    eventId: string;
    userId: string;
    eventItem: Event;
    onScoreSubmit: () => void;
    submitCounter: number;
};

const TennisScoreboard: React.FC<TennisScoreboardProps> = (
    { eventId, userId, eventItem, onScoreSubmit, submitCounter }) => {
    const [players, setPlayers] = useState<Player[]>([]);
    const [scores, setScores] = useState<Scores>({
        set1: ['', '', ''],
        set2: ['', '', ''],
        set3: ['', '', '']
    });
    const [winnerUserId, setWinnerUserId] = useState<number | null>(null);
    const [matchInProgress, setMatchInProgress] = useState<boolean>(false);
    const [lockStatus, setLockStatus] = useState<boolean | null>(null);
    const [snackbar, setSnackbar] = useState<{ show: boolean; message: string }>({
        show: false,
        message: ''
    });

    // Check if for saved scores in existing sessions
    useEffect(() => {
        const filter = window.sessionStorage.getItem(eventId + "set10");
    
        if (filter) {
            const set1_team1 = window.sessionStorage.getItem(eventId + "set10") || '';
            const set1_team2 = window.sessionStorage.getItem(eventId + "set11") || '';
            const set2_team1 = window.sessionStorage.getItem(eventId + "set20") || '';
            const set2_team2 = window.sessionStorage.getItem(eventId + "set21") || '';
            const set3_team1 = window.sessionStorage.getItem(eventId + "set30") || '';
            const set3_team2 = window.sessionStorage.getItem(eventId + "set31") || '';
            const set_winner = window.sessionStorage.getItem(eventId + "winner");
    
            setScores({
                set1: [set1_team1, set1_team2],
                set2: [set2_team1, set2_team2],
                set3: [set3_team1, set3_team2]
            });
    
            setWinnerUserId(set_winner !== null ? parseInt(set_winner) : null);
        }
    }, [eventId]);
    

    useEffect(() => {
        axios.get(`player-matchup/${eventId}/${userId}`)
            .then(response => {
                setPlayers(response.data.match);
            })
            .catch(error => {
                console.error('Error fetching player data:', error);
            });
    }, [eventId, userId, submitCounter]);

    const handleWinnerChange = (userId: number) => {
        setWinnerUserId(prevWinnerUserId => {
            const newWinner = prevWinnerUserId === userId ? null : userId;
            // Update session storage based on the new winner
            window.sessionStorage.setItem(eventId + "winner", newWinner !== null ? newWinner.toString() : '');
            return newWinner;
        });
    };

    const handleScoreChange = (set: string, playerIndex: number, score: string) => {
        const setIndex = parseInt(set.replace('set', ''), 10) - 1;
        if (players.length === 3 && playerIndex === (2 - setIndex)) {
            return; // Do not update score if it's part of the uneditable diagonal
        }
        setScores(prevScores => {
            const updatedScores = [...prevScores[set]];
            updatedScores[playerIndex] = score;
            return {
                ...prevScores,
                [set]: updatedScores as [string, string, string?]
            };
        });
        window.sessionStorage.setItem(eventId+set+playerIndex.toString(), score)
    };

    function getDisplayDay(weekday: string, creationDateStr: string, reoccurring: boolean): string {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Reset time to start of the day

        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);

        creationDateStr = creationDateStr + 'T00:00:00'; // Append time component
        const creationDate = new Date(creationDateStr);
        creationDate.setHours(0, 0, 0, 0); // Reset time to start of the day

        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        const nextWeek = new Date(today);
        nextWeek.setDate(nextWeek.getDate() + 6);

        if (creationDate.getTime() > nextWeek.getTime()) {
            reoccurring = false;
        }

        if (!reoccurring) {
            if (creationDate.getTime() === today.getTime()) {
                return "today";
            } else if (creationDate.getTime() === tomorrow.getTime()) {
                return "tomorrow";
            }
            // Return the weekday if the event is not today or tomorrow
            return weekday;
        }

        // For reoccurring events, return today or tomorrow if applicable, otherwise return the weekday
        const currentDay = daysOfWeek[today.getDay()];
        if (weekday === currentDay) {
            return "today";
        } else if (daysOfWeek[(today.getDay() + 1) % 7] === weekday) {
            return "tomorrow";
        }

        return weekday;
    }

    useEffect(() => {
        const checkMatchInProgress = (): void => {
            if (userId && eventItem) {
                const today = new Date();
                const currentHours = today.getHours();
                const currentMinutes = today.getMinutes();

                const eventDay = getDisplayDay(eventItem.weekday, eventItem.creation_date, eventItem.reoccurring);
                if (eventDay !== 'today') {
                    setMatchInProgress(false);
                    return;
                }

                const [eventHours, eventMinutes] = eventItem.time.split(':').map(Number);

                const isInProgress = currentHours > eventHours || (currentHours === eventHours && currentMinutes >= eventMinutes);
                setMatchInProgress(isInProgress);
            }
        };

        checkMatchInProgress();
    }, [userId, eventItem, matchInProgress]);

    // Fetch the current lock status
    useEffect(() => {
        const fetchLockStatus = async () => {
            if (eventId) {
                try {
                    const response = await axios.get(`lock-event/${eventId}`);
                    if (response.status === 200) {
                        setLockStatus(response.data.lock_event);
                    }
                } catch (error) {
                    console.error("Error fetching lock status:", error);
                }
            }
        };

        fetchLockStatus();
    }, [eventId]);

    const handleSubmitScore = async () => {
        if (!winnerUserId) {
            showSnackbar('Please select a winner');
            return;
        }

        const parseScore = (scoreStr: string | null | undefined): number | null => {
            return scoreStr ? parseInt(scoreStr, 10) : null;
        };

        const payload = {
            event: parseInt(eventId),
            match_date: new Date().toLocaleDateString('en-CA'),
            player1: players[0]?.user_id,
            player2: players[1]?.user_id,
            player3: players[2]?.user_id || null,
            set1_player1: parseScore(scores.set1[0]),
            set1_player2: parseScore(scores.set1[1]),
            set1_player3: parseScore(scores.set1[2]),
            set2_player1: parseScore(scores.set2[0]),
            set2_player2: parseScore(scores.set2[1]),
            set2_player3: parseScore(scores.set2[2]),
            set3_player1: parseScore(scores.set3[0]),
            set3_player2: parseScore(scores.set3[1]),
            set3_player3: parseScore(scores.set3[2]),
            winner: winnerUserId,
        };

        window.sessionStorage.removeItem(eventId + "set10");
        window.sessionStorage.removeItem(eventId + "set11");
        window.sessionStorage.removeItem(eventId + "set20");
        window.sessionStorage.removeItem(eventId + "set21");
        window.sessionStorage.removeItem(eventId + "set30");
        window.sessionStorage.removeItem(eventId + "set31");
        window.sessionStorage.removeItem(eventId + "winner");


        try {
            await axios.post('post-match-history', payload);
            showSnackbar('Score submitted successfully');
            onScoreSubmit();
        } catch (error) {
            console.error('Error submitting score:', error);
            showSnackbar('Error submitting score');
        }
    };

    // Function to handle the dynamic scoring label based on the event activity
    const getScoringLabel = () => {
        switch (eventItem.activity) {
            case "Pickleball":
                return ["Game 1", "Game 2", "Game 3"];
            default: // Default to tennis
                return ["Set 1", "Set 2", "Set 3"];
        }
    };
    const scoringLabels = getScoringLabel(); // Get labels depending on the activity

    // Function to handle the dynamic scoring instructions based on the event activity
    const getScoringInstructions = () => {
        switch (eventItem.activity) {
            case "Pickleball":
                return [
                    <li key="1">Count games</li>,
                    <li key="2">If tie, count points</li>,
                    <li key="3">If tie, highest ranked player is the winner</li>
                ];
            default: // Default to Tennis
                return [
                    <li key="1">Count sets</li>,
                    <li key="2">If tie, count games</li>,
                    <li key="3">If tie, highest ranked player is the winner</li>
                ];
        }
    };
    const scoringInstructions = getScoringInstructions(); // Get instructions depending on the activity

    const showSnackbar = (message: string) => {
        setSnackbar({ show: true, message });
        setTimeout(() => setSnackbar({ show: false, message: '' }), 3000);
    };

    const scoreSubmissionLockStatus = lockStatus === null ? !matchInProgress : lockStatus;

    return (
        <div>
            <table className="tennis-scoreboard-table">
                <thead>
                    <tr>
                        <th className="player-name">Player</th>
                        {scoringLabels.map((label, index) => (
                            <th key={index}>{label}</th>
                        ))}
                        <th className="winner-column">Winner</th>
                    </tr>
                </thead>
                <tbody>
                    {players.map((player, playerIndex) => (
                        <tr key={player.user_id}>
                            <td className="player-name">{player.first_name} {player.last_name}</td>
                            {['set1', 'set2', 'set3'].map((set, index) => (
                                <td className="set-score" key={set}>
                                    {players.length === 3 && playerIndex === 2 - index ? (
                                        <span>-</span> // Display a dash for uneditable fields
                                    ) : (
                                        <input
                                            type="number"
                                            value={scores[set][playerIndex]}
                                            onChange={(e) => handleScoreChange(set, playerIndex, e.target.value)}
                                            min="0"
                                            disabled={players.length === 3 && playerIndex === 2 - index}
                                        />
                                    )}
                                </td>
                            ))}
                            <td className="winner-checkbox">
                                <input
                                    type="checkbox"
                                    checked={winnerUserId === player.user_id}
                                    onChange={() => handleWinnerChange(player.user_id)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="score-submission-container">
                {
                    scoreSubmissionLockStatus === false ?
                        <p className="event_page-note-to-players2">
                            Players have until midnight to submit scores
                        </p>
                        :
                        <p className="event_page-note-to-players2">
                            Score submission will be enabled once event starts
                        </p>
                }

                {
                    scoreSubmissionLockStatus === true ? (
                        <div className="joined-button locked-button score-submit-button">
                            Locked
                        </div>
                    ) : (
                        (scoreSubmissionLockStatus === false) && players.length > 0 ?
                            <div>
                                <button className={"join-button score-submit-button"}
                                        onClick={handleSubmitScore}>
                                    {"Submit"}
                                </button>
                                <h5>Scoring</h5>
                                <ol>{scoringInstructions}</ol>
                                <h5>Key</h5>
                                <div>
                                    <FontAwesomeIcon icon={faCrown}/> - Winner
                                </div>
                            </div>
                            :
                            <div className="joined-button locked-button score-submit-button">
                                Locked
                            </div>
                    )
                }
            </div>
            {/* Snackbar */}
            {snackbar.show && (
                <div className="snackbar">
                    {snackbar.message}
                </div>
            )}
        </div>
    );
};

export default TennisScoreboard;
