import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';
import { useUser } from './UserContext';

// Define the shape of the context state
interface UnreadMessagesState {
    unreadMessages: { [key: string]: number };
    fetchUnreadMessages: (userId: number) => void;
}

// Initialize context with undefined which will be set later
const UnreadMessagesContext = createContext<UnreadMessagesState | undefined>(undefined);

export const useUnreadMessages = () => {
    const context = useContext(UnreadMessagesContext);
    if (context === undefined) {
        throw new Error('useUnreadMessages must be used within a UnreadMessagesProvider');
    }
    return context;
};

interface UserProviderProps {
    children: ReactNode;
}

export const UnreadMessagesProvider: React.FC<UserProviderProps> = ({ children }) => {
    const [unreadMessages, setUnreadMessages] = useState<{ [key: string]: number }>({});
    const user = useUser();

    const fetchUnreadMessages = async (userId: number) => {
        try {
            const response = await axios.get(`unread-message-count/${String(userId)}`);
            response.data['General'] = 0
            setUnreadMessages(response.data);
        } catch (error) {
            console.error('Failed to fetch unread messages', error);
        }
    };

    // Fetch unread messages when user changes and is not null
    useEffect(() => {
        if (user?.id) { // Ensure user and user.id are not null or undefined
            fetchUnreadMessages(user.id);  // Convert number ID to string if necessary
        }
    }, [user?.id]); // Depend on user.id to refetch when it changes

    return (
        <UnreadMessagesContext.Provider value={{ unreadMessages, fetchUnreadMessages }}>
            {children}
        </UnreadMessagesContext.Provider>
    );
};
