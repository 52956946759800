import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown, faMedal } from '@fortawesome/free-solid-svg-icons';

type User = {
    id: number;
    first_name: string;
    last_name: string;
};

interface Match {
    event: number;
    event_name: string;
    ladder: string;
    match_date: string;
    player1: string;
    player2: string;
    player3?: string;
    player4?: string;
    activity: string;
    winner: string;
    second: string;
    player1_sets: number[];
    player2_sets: number[];
    player3_sets?: number[];
    player4_sets?: number[];
};

export const MatchHistory = ({ user }: { user: User }) => {
    const [matchHistory, setMatchHistory] = useState<Match[]>([]);

    useEffect(() => {
        const fetchMatchHistory = async () => {
            try {
                const response = await axios.get(`match-history/${user.id}`);
                if (response.status === 200) {
                    setMatchHistory(response.data);
                }
            } catch (error) {
                console.error('Error fetching match history:', error);
            }
        };

        fetchMatchHistory();
    }, [user]);

    return (
        <div>
            <div className="matchhistory-custom-table-container">
                <table className='matchhistory-custom-table'>
                    <tbody>
                        {matchHistory.map((match, matchIndex) => {
                            const players = ([
                                // Creates an array of player objects with their set scores. Removes player3 if player3 does not exist.
                                { name: match.player1, sets: match.player1_sets },
                                { name: match.player2, sets: match.player2_sets },
                                match.player3 ? { name: match.player3, sets: match.player3_sets || [] } : null,
                                match.player4 ? { name: match.player4, sets: match.player4_sets || [] } : null
                            ].filter(Boolean) as Array<{ name: string; sets: number[] }>);

                            return players.map((player, index) => (
                                /*
                                Iterates through players array and returns a table row for each player. For items that are shared, span the number of players so that the shared item only appears once.
                                */
                                <React.Fragment key={`${matchIndex}-${index}`}>
                                    <tr key={`${matchIndex}-${index}`}>
                                        {index === 0 && <td className={'matchhistory-date-padding'}
                                                            rowSpan={players.length}>{match.match_date}</td>}
                                        <td>{player.name}{" "}
                                            {match.winner === player.name ?
                                                <FontAwesomeIcon icon={faCrown}/> :
                                                (match.second === player.name ?
                                                        <FontAwesomeIcon icon={faMedal}/> :
                                                        null
                                                )}
                                        </td>
                                        <td>{player.sets[0] || '-'}</td>
                                        <td>{player.sets[1] || '-'}</td>
                                        <td className={'matchhistory-set-padding'}>{player.sets[2] || '-'}</td>
                                        {index === 0 && <td rowSpan={players.length}>{match.activity}</td>}
                                        {index === 0 && <td rowSpan={players.length}>{match.ladder}</td>}
                                    </tr>
                                    {index === players.length - 1 && <tr className='spacer'><td></td></tr> /*spacer row*/}
                                </React.Fragment>
                            ));
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
};



export default MatchHistory;