import React, { useState } from 'react';
import axios from 'axios';
import { RootState } from '../redux/store';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

export const EmailSubscription = () => {
    const [message, setMessage] = useState('');
    const auth = useSelector((state: RootState) => state.auth.value);

    localStorage.setItem('preLoginUrl', window.location.pathname)

    const handleSubscribe = async () => {
        try {
            const response = await axios.post('email-subscribe');
            if (response.status === 200) {
                setMessage('Successfully subscribed to emails!');
            } else {
                setMessage('Failed to subscribe to emails.');
            }
        } catch (error) {
            setMessage('An error occurred while subscribing.');
        }
    };

    const handleUnsubscribe = async () => {
        try {
            const response = await axios.post('email-unsubscribe');
            if (response.status === 200) {
                setMessage('Successfully unsubscribed from emails!');
            } else {
                setMessage('Failed to unsubscribe from emails.');
            }
        } catch (error) {
            setMessage('An error occurred while unsubscribing.');
        }
    };

    if (!auth) {
        return <Navigate to={'/login'}/>;
    }

    return (
        <div className="email-subscription-container">
            <h3>Email subscription</h3>
            <div>
                <button className="btn btn-success email-subscription-buttons" 
                        onClick={handleSubscribe}>Subscribe</button>
            </div>
            <div>
                <button className="btn btn-danger email-subscription-buttons" 
                        onClick={handleUnsubscribe}>Unsubscribe</button>
            </div>
            {message && <div className="alert alert-info email-subscription-message">{message}</div>}
        </div>
    );
};

export default EmailSubscription;
