import React, {useEffect, useState, SyntheticEvent, KeyboardEvent} from 'react';
import axios from "axios";
import MapComponent from './GoogleMap';
import DiscoverSlidesEvents from './DiscoverSlidesEvents';
import MyCalendar from './Calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useUser } from './UserContext';
import { RootState } from '../redux/store';
import { useSelector } from 'react-redux';

export const DiscoverEvents = () => {
    const [userId, setUserId] = useState<number | null>(null);
    const [userPosition, setUserPosition] = useState<{ lat: number; lng: number }>({ lat: 0, lng: 0 })
    const [selectedRadius, setSelectedRadius] = useState<number | null>(60);
    const [hasInteractedWithRadius, setHasInteractedWithRadius] = useState<boolean>(false);
    const [eventsData, setEventsData] = useState<any | null>(null);
    const [activities, setActivities] = useState<{ id: number; activity: string }[]>([]);
    const [selectedActivity, setSelectedActivity] = useState<number | null>(null);
    const [newAddress, setNewAddress] = useState<string>("");
    const [lastGeocodedAddress, setLastGeocodedAddress] = useState<string>("");
    const [selectedFilter, setSelectedFilter] = useState<string | null>(null); // Possible values: null, 'filterOnUserId', 'filterHostedEvents'
    const [eventsCalendar, setEventsCalendar] = useState<[]>([]);
    const [formattedDate, setFormattedDate] = useState<string>("");
    const user = useUser();
    const auth = useSelector((state: RootState) => state.auth.value);

    // Determine if user is authenticated
    useEffect(() => {
        (async () => {
            try {
                if (auth && user) {
                    const initlatitude = Number(user.latitude);
                    const initlongitude = Number(user.longitude);
                    if (!isNaN(initlatitude) && !isNaN(initlongitude)) {
                        setUserId(user.id);
                        setUserPosition({ lat: initlatitude, lng: initlongitude });
                    } else {
                        console.error("Invalid latitude or longitude values");
                        // Handle invalid lat/lng
                    }
                }
            } catch (e) {
                console.log(e)
            }
        })();
    }, [auth, user]);

    // Calendar events
    useEffect(() => {
        if (userId) {
            const fetchEventData = async () => {
                try {
                    const response = await axios.get(`event-users/user/${userId}`);
                    if (response.status === 200) {
                        setEventsCalendar(response.data)
                    }
                }
                catch (e) {
                    console.log(e)
                }
            };
            // Now invoke the async function
            fetchEventData().then(() => {
                // Whatever you'd like to execute after fetchEventData completes
                console.log("Event data fetched successfully!")
            });
        }
    }, [userId]);

    // Get events within selected radius
    useEffect(() => {
        const filter = window.sessionStorage.getItem('filter_events');

        if (filter) {
            setSelectedFilter(filter);
        }

        const fetchEventsWithinRadius = async () => {
            if (userId) {
                try {
                    const requestData = {
                        user_id: userId,
                        radius: selectedRadius,
                        activity_id: selectedActivity,
                        new_address: newAddress,
                        filter_on_user_id: selectedFilter === 'filterOnUserId',
                        hosted_events: selectedFilter === 'filterHostedEvents',
                        date: formattedDate
                    };
                    const response = await axios.post('events-within-radius', requestData);
                    setEventsData(response.data);
                } catch (error) {
                    console.error("Error fetching events:", error);
                }
            }
        }
        fetchEventsWithinRadius();
        // eslint-disable-next-line
    }, [userId, selectedRadius, selectedActivity, selectedFilter, formattedDate]);

    // Get activity data
    useEffect(() => {
        // Fetch activities from the API
        axios.get('activity')
            .then((response) => {
                setActivities(response.data);

            })
            .catch((error) => {
                console.error("Error fetching activities:", error);
            });
    }, []); // Run this effect once when the component mounts

    // Handle radius change
    const handleRadiusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setHasInteractedWithRadius(true);
        const value = event.target.value;
        setSelectedRadius(value ? parseInt(value) : null);
    };

    // Handle activity change
    const handleActivityChange = (e: any) => {
        const selectedValue = e.target.value;
        setSelectedActivity(selectedValue === "all" ? null : parseInt(selectedValue));
    };

    // Handle address change by updating typed address in search box
    const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewAddress(event.target.value)
    };

    // Keyboard event handler
    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
        handleFormSubmit(e);
        }
    };    

    const geocodeNewAddress = async () => {
        // Checking that newAddress changes to avoid making excess API calls
        if (lastGeocodedAddress !== newAddress) {
            if (newAddress === '' && user) {
                setUserPosition({ lat: Number(user.latitude), lng: Number(user.longitude) });
                setLastGeocodedAddress(newAddress)
            } else {
                try {
                    const requestData = {
                        new_address: newAddress
                    };
                    const response = await axios.post('geocode-address', requestData);
                    setUserPosition({
                        lat: parseFloat(response.data.latitude),
                        lng: parseFloat(response.data.longitude)
                    });
                    setLastGeocodedAddress(newAddress)
                } catch (error) {
                    console.error("Error geocoding the new address:", error);
                    // Optionally, you can add more specific error handling here
                }
            }
        }
    };

    // Submit button
    const handleFormSubmit = async (e: SyntheticEvent | KeyboardEvent) => {
        e.preventDefault();

        // Directly call geocodeNewAddress when the form is submitted
        await geocodeNewAddress();

        const ladderPayload = {
            user_id: userId,
            radius: selectedRadius,
            activity_id: selectedActivity,
            new_address: newAddress,
            filter_on_user_id: selectedFilter === 'filterOnUserId',
            hosted_events: selectedFilter === 'filterHostedEvents'
        };

        try {
            const response = await axios.post('events-within-radius', ladderPayload);
            setEventsData(response.data);
        } catch (error) {
            console.error("Error fetching events:", error);
        }
    }

    const handleCheckboxChange = (filterName: any) => {
        setSelectedFilter(prevFilter => {
            const newFilter = prevFilter === filterName ? null : filterName;
            if (newFilter === null) {
                window.sessionStorage.removeItem('filter_events');
            } else {
                window.sessionStorage.setItem('filter_events', newFilter);
            }
            return newFilter;
        });
    };

    // Inside DiscoverEvents component
    const onDayClick = (clickedDate: Date) => {
        const year = clickedDate.getFullYear();
        const month = clickedDate.getMonth() + 1; // getMonth() returns 0-11
        const day = clickedDate.getDate();
        const newFormattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

        setFormattedDate(newFormattedDate); // Update state
    };

    return (
        <div>
            <h3 className={'discover-header'}>Events</h3>
            <div className= 'ladder-page-container2 ladder-page-container2-no-top-padding'>
                <div className='discover-left-square'>
                    {/* Content will be added here in the future if needed */}
                    <div className={'discover-header-container'}>
                        <main className="form-signin container-signin-discover">
                            <form className={"form-signin-discover"} onSubmit={handleFormSubmit} onKeyDown={handleKeyDown}>
                                {/*Radius*/}
                                <div className="form-floating dropdown-discover filters-container">
                                    <select
                                        className="form-select"
                                        id="floatingRadius"
                                        value={hasInteractedWithRadius && selectedRadius !== null ? selectedRadius : ""}
                                        onChange={handleRadiusChange}
                                    >
                                        <option value="" disabled>Select Radius</option>
                                        {[
                                            1, 2, 5, 10, 15, 20, 25, 30, 40, 50, 60
                                        ].map((radius) => (
                                            <option key={radius} value={radius}>
                                                {radius} mile{radius > 1 ? 's' : ''}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {/*Activity*/}
                                <div className="form-floating dropdown-discover filters-container">
                                    <select
                                        className="form-select"
                                        id="floatingActivity"
                                        value={selectedActivity === null ? "all" : selectedActivity}
                                        onChange={handleActivityChange}
                                    >
                                        <option value="" disabled>Select Activity</option>
                                        <option value="all">All Activities</option>
                                        {activities.map((activity) => (
                                            <option key={activity.id} value={activity.id}>
                                                {activity.activity}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {/* New Address Input */}
                                <div className="form-floating dropdown-discover filters-container">
                                    <input
                                        type="text"
                                        className="form-control filters-container"
                                        id="newAddress"
                                        placeholder="Enter new address"
                                        value={newAddress}
                                        onChange={handleAddressChange}
                                    />
                                    <label htmlFor="newAddress">New Address</label>
                                </div>

                                <div className="discover-checkbox-container">
                                    {/*Filter on User Id*/}
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="filterOnUserIdCheckbox"
                                            checked={selectedFilter === 'filterOnUserId'}
                                            onChange={() => handleCheckboxChange('filterOnUserId')}
                                        />
                                        <label className="form-check-label" htmlFor="filterOnUserIdCheckbox">
                                            My Events
                                        </label>
                                    </div>

                                    {/* Hosted Events Checkbox */}
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="filterHostedEventsCheckbox"
                                            checked={selectedFilter === 'filterHostedEvents'}
                                            onChange={() => handleCheckboxChange('filterHostedEvents')}
                                        />
                                        <label className="form-check-label" htmlFor="filterHostedEventsCheckbox">
                                            Hosted Events
                                        </label>
                                    </div>
                                </div>
                                <button className="registerButton btn btn-primary w-100 py-2 form-floating
                                landing-page-search-button filter-button"
                                        type="submit"><FontAwesomeIcon icon={faSearch} /></button>
                            </form>
                        </main>
                    </div>
                    <DiscoverSlidesEvents eventsData={eventsData} />
                </div>
                <div className={'discover-right-square-container'}>
                    <div className = 'discover-right-square-top'>
                        <MyCalendar events={eventsCalendar} onDayClick={onDayClick}/>
                    </div>
                    <div className={'discover-events-reset-calendar'} onClick={() => setFormattedDate("")}>
                        Reset Calendar Filter
                    </div>
                    <div className = 'discover-right-square'>
                        <MapComponent key={userPosition.toString()} position={userPosition} data={eventsData}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DiscoverEvents;