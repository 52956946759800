import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import MatchHistory from './MatchHistory';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { useUser } from './UserContext';

interface LadderData {
    id: number;
    rank: number;
    last_match_won: boolean;
    ladder_id: number;
    ladder_name: string;
    ladder_image: string;
    first_name: string;
    last_name: string;
    user_image: string;
}

type RouteParams = {
    userId: string;
}

export const ProfileOtherUser = () => {
    const [userData, setUserData] = useState<any | null>(null);
    const [myUserData, setMyUserData] = useState<any | null>(null);
    const [ladderData, setLadderData] = useState<LadderData[]>([]);
    const { userId = "" } = useParams<RouteParams>();
    const auth = useSelector((state: RootState) => state.auth.value);
    const myUser = useUser();

    useEffect(() => {
        (async () => {
            if (myUser && auth) {
                setMyUserData(myUser);
            }
        })();
    }, [myUser, auth]);


    // User Data
    useEffect(() => {
        if (userId) {
            const fetchUserData = async () => {
                try {
                    const response = await axios.get(`user-basic-info/${userId}`);
                    if (response.status === 200) {
                        setUserData(response.data)
                    }
                }
                catch (e) {
                    console.log(e)
                }
            };
            // Now invoke the async function
            fetchUserData().then(() => {
                // Whatever you'd like to execute after fetchEventData completes
                console.log("User data fetched successfully!")
            });
        }
    }, [userId]);

    // User Ladder Data
    useEffect(() => {
        if (userData && userData.id) {
            const fetchEventData = async () => {
                try {
                    const response = await axios.get(`ladder-users/user/${userData.id}`);
                    if (response.status === 200) {
                        setLadderData(response.data)
                    }
                }
                catch (e) {
                    console.log(e)
                }
            };
            // Now invoke the async function
            fetchEventData().then(() => {
                // Whatever you'd like to execute after fetchEventData completes
                console.log("Ladder data fetched successfully!")
            });
        }
    }, [userData]);

    if (!userData) {
        return <div>Loading...</div>;
    }
    
    return (
        <div className='profile-page'>
            <div className="ladder-page-container2">
                <div className="ladder-page-description2">
                    <div className="profile-user-info">
                        <img src={`${userData.user_image}`} alt={userData.fullName} className="profile-img"/>
                    </div>
                    <div className="profile-user-info">
                        <h4>{`${userData.first_name} ${userData.last_name}`}</h4>
                        {myUserData  && (
                            <Link to={`/message/user/${userData?.id}/${myUserData?.id}/${encodeURIComponent(`${userData.first_name} ${userData.last_name}`)}`}
                                  className="ladder-page-update-ladder profile-page-go-to-chat">
                                Message
                            </Link>
                        )}
                    </div>
                    <div className="profile-ladder-page-description profile-pill-container">
                        <div className="profile-about-me-header">
                            <h4>About Me</h4>
                        </div>
                        <p className="profile-about-me-text">{userData.about_me}</p>
                    </div>
                    <div className="profile-ladder-page-description profile-pill-container">
                        <div className="profile-about-me-header">
                            <h4>{`${userData.first_name}'s Ladders`}</h4>
                        </div>
                        <div className="profile-ladders-section">
                            {ladderData.map((ladder) => (
                                <div key={ladder.ladder_id} className="profile-ladder-pill">
                                    <Link to={`/ladder-page/${ladder.ladder_id}`} className={"profile-custom-link"}>
                                        <img
                                            src={ladder.ladder_image}
                                            alt={ladder.ladder_name}
                                            className="profile-ladder-image"

                                        />
                                        <div className="profile-ladder-details">
                                            <span className="profile-ladder-name">{ladder.ladder_name}</span>
                                            <span className="profile-ladder-rank">Rank: {ladder.rank}</span>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="ladder-page-description2">
                    <div className="profile-ladder-page-description profile-pill-container">
                        <div className="profile-about-me-header">
                            <h4>Match History</h4>
                        </div>
                        <MatchHistory user={userData} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileOtherUser;
