import {configureStore} from "@reduxjs/toolkit";
import authReducer from './authSlice';

// Load the saved auth state from localStorage
const persistedAuthState = JSON.parse(localStorage.getItem('auth') ?? 'null') ?? { value: false };

export const store = configureStore({
    reducer: {
        auth: authReducer
    },
    preloadedState: {
        auth: persistedAuthState
    }
});

// Subscribe to store changes and persist auth state to localStorage
store.subscribe(() => {
  const state = store.getState();
  localStorage.setItem('auth', JSON.stringify(state.auth));
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
