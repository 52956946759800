import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import { UserProvider } from './components/UserContext';
import { Provider } from 'react-redux';
import { store } from './redux/store';

import './App.css';

import {Login} from "./components/Login";
import {Register} from "./components/Register";
import {Nav} from "./components/Nav";
import {Forgot} from "./components/Forgot";
import {Reset} from "./components/Reset";
import {Messages} from "./components/Messages";
import {Profile} from "./components/Profile";
import {RegisterLadder} from "./components/RegisterLadder";
import {LadderPage} from "./components/LadderPage";
import {Account} from "./components/Account";
import {EventPage} from "./components/EventPage"
import { UpdateLadder } from './components/UpdateLadder';
import { RegisterEvent } from './components/RegisterEvent';
import ContactUs from './components/ContactUs';
import DiscoverEvents from './components/DiscoverEvents';
import { Home } from './components/Home';
import { UpdateEvent } from './components/UpdateEvent';
import { Footer } from './components/Footer';
import ProfileOtherUser from './components/ProfileOtherUser';
import { Message } from './components/Message';
import { UnreadMessagesProvider } from './components/UnreadMessageProvider';
import { EventHostOptions } from './components/EventHostOptions';
import FAQs from './components/FAQs';
import EmailSubscription from './components/EmailSubscription';
import { SelfRate } from './components/SelfRate';

function App() {
    return (
        <Provider store={store}>
            <UserProvider>
                <UnreadMessagesProvider>
                    <BrowserRouter>
                        <div className="app-container">
                            <Nav/>
                            <div className="app-main-content">
                                <Routes>
                                    <Route path="/" element={<Home/>}/>
                                    <Route path="/register" element={<Register/>}/>
                                    <Route path="/login" element={<Login/>}/>
                                    <Route path="/forgot" element={<Forgot/>}/>
                                    <Route path="/reset/:token" element={<Reset/>}/>
                                    <Route path="/messages" element={<Messages />}/>
                                    <Route path="/events" element={<DiscoverEvents/>}/>
                                    <Route path="/profile" element={<Profile/>}/>
                                    <Route path="/user-profile/:userId" element={<ProfileOtherUser/>}/>
                                    <Route path="/register-ladder" element={<RegisterLadder/>}/>
                                    <Route path="/ladder-page/:ladderId" element={<LadderPage/>}/>
                                    <Route path="/account-page" element={<Account/>}/>
                                    <Route path="/event-page/:eventId" element={<EventPage/>}/>
                                    <Route path="/update-ladder/:ladderId" element={<UpdateLadder/>}/>
                                    <Route path="/create-event/:ladderId" element={<RegisterEvent/>}/>
                                    <Route path="/contact-us" element={<ContactUs/>}/>
                                    <Route path="/update-event/:eventId" element={<UpdateEvent/>}/>
                                    <Route path="/event-host-options/:eventId/:ladderId" element={<EventHostOptions/>}/>
                                    <Route path="/message/ladder/:ladderId/:chatName" element={<Message />} />
                                    <Route path="/message/user/:user1Id/:user2Id/:chatName" element={<Message />} />
                                    <Route path="/faqs" element={<FAQs/>}/>
                                    <Route path="/email-subscription" element={<EmailSubscription/>}/>
                                    <Route path="/self-rate/:activity/:ladderId" element={<SelfRate />}/>
                                </Routes>
                            </div>
                            <Footer/>
                        </div>
                    </BrowserRouter>
                </UnreadMessagesProvider>
            </UserProvider>
        </Provider>
    );
}

export default App;

