import React from 'react';

const FAQs = () => {
    return (
        <div className="user-guide">
            <h3 className="user-guide-header user-guide-section-spacing">FAQ's</h3>

            <div className="user-guide-table-of-contents user-guide-section-spacing">
                <ul>
                    <li><a href="#what-are-ladders">What are ladders?</a></li>
                    <li><a href="#what-are-events">What are events?</a></li>
                    <li><a href="#how-to-join-ladder">How do I join a ladder?</a></li>
                    <li><a href="#how-to-find-events">How do I join an event?</a></li>
                    <li><a href="#event-participation">What happens after signing up for an event?</a></li>
                    <li><a href="#score-submission">How to submit scores?</a></li>
                    <li><a href="#post-event-ranking">How is my ranking updated after an event?</a></li>
                </ul>
            </div>

            <section id="what-are-ladders" className="user-guide-section-spacing">
                <h5 className="user-guide-subheader">What are Ladders?</h5>
                <p>Ladders are competitive groups, similar to Facebook groups or Meetup groups, where players compete
                    against each other for the top spot on the ladder.</p>
            </section>

            <section id="what-are-events" className="user-guide-section-spacing">
                <h5 className="user-guide-subheader">What are Events?</h5>
                <p>Events are organized within each Ladder and offer a time and place for players to compete against
                    eachother.</p>
                <p>Once a player joins an event, they can:</p>
                <ol>
                    <li>See who else is playing in the event</li>
                    <li>See player matchups</li>
                    <li>See your match - who you are playing against</li>
                    <li>Once the event starts, players will be able to submit their scores upon completion</li>
                </ol>
            </section>

            <section id="how-to-join-ladder" className="user-guide-section-spacing">
                <h5 className="user-guide-subheader">How do I join a ladder?</h5>
                <ol>
                    <li>
                        Go to the 'Ladder' tab on our site.
                        <div className={'faq-images faq-navigate-to-ladder'}/>
                    </li>
                    <li>
                        Search for a ladder that interests you.
                        <div className={'faq-images faq-ladder-to-join'}/>
                    </li>
                    <li>
                        Click the 'join' button to become part of that ladder.
                        <div className={'faq-images faq-join-ladder'}/>
                    </li>
                </ol>
            </section>

            <section id="how-to-find-events" className="user-guide-section-spacing">
                <h5 className="user-guide-subheader">How do I join an event?</h5>
                <p>There are two ways to join an event:</p>
                <ol>
                    <li>
                        Each Ladder will have it's own events - Go to a ladder page to find events within that ladder
                        <div className={'faq-images faq-find-event-in-ladder'}/>
                    </li>
                    <li>
                        Browse the 'Events' tab to discover more events from all ladders
                        <div className={'faq-images faq-find-event-in-events-tab'}/>
                    </li>
                </ol>
            </section>

            <section id="event-participation" className="user-guide-section-spacing">
                <h5 className="user-guide-subheader">What happens after signing up for an event?</h5>
                <p>There are 3 sections within an event:</p>
                <ol>
                    <li>
                        <strong>Going:</strong> Check who's attending, along with their profile pictures and ranks. A green arrow indicates
                        winners of their last matches.
                        <div className={'faq-images faq-event-going'}/>
                    </li>
                    <li>
                        <strong>Matches:</strong> View the list of matchups and their assigned courts.
                        <div className={'faq-images faq-event-matchups'}/>
                    </li>
                    <li>
                        <strong>Your Match:</strong> Find your specific matchup and the assigned court. Events often use a round-robin format.
                        <div className={'faq-images faq-event-your-match'}/>
                    </li>
                </ol>
            </section>

            <section id="score-submission" className="user-guide-section-spacing">
                <h5 className="user-guide-subheader">How do I submit scores?</h5>
                <p>After completing a match, any player in that matchup can submit their scores, typically the
                    winner.</p>
                <div className={'faq-images faq-score-submission1'}/>
                <div className={'faq-images faq-score-submission2'}/>
                <p>This submission helps update the event results, showing first and second place with a crown and medal
                    respectively, as seen in image where Demo User4 won first place.</p>
                <div className={'faq-images faq-medals'}/>
            </section>

            <section id="post-event-ranking" className="user-guide-section-spacing">
                <h5 className="user-guide-subheader">How is my ranking updated after an event?</h5>
                <p>Once scores are submitted, your rank is automatically recalculated. Check the 'Going' section on
                    either the Ladder or Event Page to see your new position, like how Demo User4 reached the #1
                    spot.</p>
                <div className={'faq-images faq-new-rank'}/>
            </section>
        </div>
    );
};

export default FAQs;
