import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Room from './MessageRoom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const WS_URL = `${process.env.REACT_APP_API_BASE_WS}/ws/`;

const formatLadderName = (id: number): string => `ladder_${id}`;

const formatUserName = (userId1: number, userId2: number): string => {
    const minId = Math.min(userId1, userId2);
    const maxId = Math.max(userId1, userId2);
    return `user_${minId}_${maxId}`;
};

interface MessageParams {
    [key: string]: string | undefined;
    ladderId?: string;
    user1Id?: string;
    user2Id?: string;
    chatName?: string;
}

export const Message: React.FC = () => {
    const { ladderId, user1Id, user2Id, chatName } = useParams<MessageParams>();
    const [chatSocket, setChatSocket] = useState<WebSocket | null>(null);
    const [roomName, setRoomName] = useState<string | null>(null); // Initialize roomName to null
    const [decodedChatName, setDecodedChatName] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (!chatName || !WS_URL) {
            console.error("Chat name is required");
            return;
        }

        const decodedChatName = decodeURIComponent(chatName);
        setDecodedChatName(decodedChatName);
        let room = null;

        if (ladderId) {
            room = formatLadderName(parseInt(ladderId));
        } else if (user1Id && user2Id) {
            room = formatUserName(parseInt(user1Id), parseInt(user2Id));
        }

        if (!room) {
            console.error("Insufficient parameters to determine room name");
            return;
        }
        setRoomName(room);

        const socketUrl = `${WS_URL}messages/${room}/`;
        const newSocket = new WebSocket(socketUrl);

        newSocket.onopen = () => {
            console.log("WebSocket connection established.");
            const token = localStorage.getItem("token");
            if (token) {
                newSocket.send(JSON.stringify({ type: 'authenticate', token }));
            }
        };

        newSocket.onclose = () => console.log("WebSocket connection closed unexpectedly.");

        setChatSocket(newSocket);

        // Cleanup old connection on component unmount or when dependencies change
        return () => {
            newSocket.close();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatName]); // Re-run this effect only if chatName changes

    const handleBackClick = () => {
        navigate(-1);
    };

    const toggleSidebar = () => {
        console.log("Sidebar toggle function called.");
    };

    return (
        <div className="message-container">
            <button onClick={handleBackClick} className="back-to-ladder-button back-button-absolute">
                <FontAwesomeIcon icon={faArrowLeft}/>
                <span>Back</span>
            </button>
            {chatSocket && roomName && decodedChatName && (
                <Room chatSocket={chatSocket}
                      roomName={roomName}
                      chatName={decodedChatName}
                      toggleSidebar={toggleSidebar}/>
            )}
        </div>
    );
};

export default Message;
