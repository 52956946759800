import React, {useEffect, useState, SyntheticEvent} from 'react';
import axios from "axios";
import DiscoverSlides from './DiscoverSlides';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export const DiscoverLaddersLanding = () => {
    const [selectedRadius, setSelectedRadius] = useState<number | null>(60);
    const [hasInteractedWithRadius, setHasInteractedWithRadius] = useState<boolean>(false);
    const [laddersData, setLaddersData] = useState([]);
    const [activities, setActivities] = useState<{ id: number; activity: string }[]>([]);
    const [selectedActivity, setSelectedActivity] = useState<number | null>(null);
    const [newAddress, setNewAddress] = useState<string>("");

    // Get ladders within selected radius
    useEffect(() => {
        const fetchLaddersWithinRadius = async () => {
            try {
                const requestData = {
                    radius: selectedRadius,
                    activity_id: selectedActivity,
                    new_address: newAddress
                };
                const response = await axios.post('ladders-within-radius-landing', requestData);
                setLaddersData(response.data);
            } catch (error) {
                console.error("Error fetching ladders:", error);
            }
        };

        fetchLaddersWithinRadius();
        // eslint-disable-next-line
    }, [selectedRadius, selectedActivity]);


    // Handle radius change
    const handleRadiusChange = (ladder: React.ChangeEvent<HTMLSelectElement>) => {
        setHasInteractedWithRadius(true);
        const value = ladder.target.value;
        setSelectedRadius(value ? parseInt(value) : null);
    };

    // Handle activity change
    const handleActivityChange = (e: any) => {
        const selectedValue = e.target.value;
        setSelectedActivity(selectedValue === "all" ? null : parseInt(selectedValue));
    };

    // Handle address change by updating typed address in search box
    const handleAddressChange = (e: any) => {
        const selectedValue = e.target.value;
        setNewAddress(selectedValue)
    };

    // Keyboard event handler
    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
        submit(e);
        }
    };   

    // Get activity data
    useEffect(() => {
        // Fetch activities from the API
        axios.get('activity')
            .then((response) => {
                setActivities(response.data);

            })
            .catch((error) => {
                console.error("Error fetching activities:", error);
            });
    }, []); // Run this effect once when the component mounts

    // Submit button
    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        const ladderPayload = {
            radius: selectedRadius,
            activity_id: selectedActivity,
            new_address: newAddress
        };

        try {
            const response = await axios.post('ladders-within-radius-landing', ladderPayload);
            setLaddersData(response.data);
        } catch (error) {
            console.error("Error fetching ladders:", error);
        }
    }

    return (
        <div>
            {/* Content will be added here in the future if needed */}
            <h3 className={'discover-header'}>Ladders</h3>
            <div className={'discover-header-container discover-ladder-margin discover-header-container-landing'}>
                <main className="form-signin container-signin-discover">
                    <form className={"form-signin-discover"} onSubmit={submit} onKeyDown={handleKeyDown}>
                        {/*Radius*/}
                        <div className="form-floating dropdown-discover filters-container">
                            <select
                                className="form-select"
                                id="floatingRadius"
                                value={hasInteractedWithRadius && selectedRadius !== null ? selectedRadius : ""}
                                onChange={handleRadiusChange}
                            >
                                <option value="" disabled>Select Radius</option>
                                {[
                                    1, 2, 5, 10, 15, 20, 25, 30, 40, 50, 60
                                ].map((radius) => (
                                    <option key={radius} value={radius}>
                                        {radius} mile{radius > 1 ? 's' : ''}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/*Activity*/}
                        <div className="form-floating dropdown-discover filters-container">
                            <select
                                className="form-select"
                                id="floatingActivity"
                                value={selectedActivity === null ? "all" : selectedActivity}
                                onChange={handleActivityChange}
                            >
                                <option value="" disabled>Select Activity</option>
                                <option value="all">All Activities</option>
                                {activities.map((activity) => (
                                    <option key={activity.id} value={activity.id}>
                                        {activity.activity}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* New Address Input */}
                        <div className="form-floating dropdown-discover filters-container">
                            <input
                                type="text"
                                className="form-control filters-container"
                                id="newAddress"
                                placeholder="Enter new address"
                                value={newAddress}
                                onChange={handleAddressChange}
                            />
                            <label htmlFor="newAddress">New Address</label>
                        </div>
                        <button className="registerButton btn btn-primary w-100 py-2 form-floating
                        landing-page-search-button filter-button"
                                type="submit"><FontAwesomeIcon icon={faSearch} /></button>
                    </form>
                </main>
            </div>
            <div className= 'ladder-page-container2'>
                <div className='discover-left-square'>
                    <DiscoverSlides data={laddersData} />
                </div>
            </div>
        </div>
    );
};

export default DiscoverLaddersLanding;