import React, {useEffect, useState } from 'react';
import Slider from 'react-slick';
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap-icons/font/bootstrap-icons.css';

type Event = {
    id: number;
    event_name: string;
    creation_date: string;
    weekday: string;
    time: string;
    address_name: string;
    address_line_1: string;
    address_line_2: string | null;
    city: string;
    state: string;
    zip_code: number;
    latitude: number;
    longitude: number;
    reoccurring: boolean;
    user_count: number;
    nextDate?: string;
    nextDay?: string;
};

// Define a type for the days of the week
type Weekdays = "Sunday" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday";

// Define a mapping from weekdays to their corresponding numbers
const daysOfWeek: Record<Weekdays, number> = {
    "Sunday": 0,
    "Monday": 1,
    "Tuesday": 2,
    "Wednesday": 3,
    "Thursday": 4,
    "Friday": 5,
    "Saturday": 6
};

type EventSlidesProps = {
    eventsData: Event[] | null;
};

const EventSlides: React.FC<EventSlidesProps> = ({ eventsData }) => {
    const [dragging, setDragging] = useState(false);
    const [firstDrag, setFirstDrag] = useState(false);
    const [visibleEvents, setVisibleEvents] = useState<Event[]>([]);

    useEffect(() => {
        if (eventsData) {
            loadMoreEvents(); // Initial load
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventsData]);

    const loadMoreEvents = () => {
        if (eventsData) {
            let processedEvents = eventsData.map(event => ({
                ...event,
                nextDate: getDisplayDate(event.weekday, event.creation_date, event.reoccurring),
                nextDay: getDisplayDay(event.weekday, event.creation_date, event.reoccurring)
            }));

            // Function to parse a date and time string into a Date object
            const parseDateTime = (dateStr: string, timeStr: string): Date => {
                const [hours, minutes] = timeStr.split(':').map(Number);
                const [month, day, year] = dateStr.split('/').map(Number);
                return new Date(year, month - 1, day, hours, minutes);
            };

            // Sort processedEvents by nextDate and time
            processedEvents = processedEvents.sort((a, b) => {
                const dateTimeA = parseDateTime(a.nextDate!, a.time);
                const dateTimeB = parseDateTime(b.nextDate!, b.time);
                return dateTimeA.getTime() - dateTimeB.getTime();
            });

            setVisibleEvents(processedEvents);
        }
    };

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        beforeChange: () => setDragging(true),
        afterChange: () => {
            setFirstDrag(true);
            setDragging(false)
        },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    function convertToUserFriendlyTime(timeStr: string): string {
        const [hours, minutes] = timeStr.split(':').map(Number);
        const isPM = hours >= 12;
        const convertedHours = hours % 12 || 12;
        return `${convertedHours}:${minutes.toString().padStart(2, '0')} ${isPM ? 'pm' : 'am'}`;
    }

    function getDisplayDay(weekday: string, creationDateStr: string, reoccurring: boolean): string {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);

        creationDateStr += 'T00:00:00';
        const creationDate = new Date(creationDateStr);
        creationDate.setHours(0, 0, 0, 0);

        if (!reoccurring) {
            if (creationDate.getTime() === today.getTime()) {
                return "Today";
            } else if (creationDate.getTime() === tomorrow.getTime()) {
                return "Tomorrow";
            }
            return weekday;
        }

        const currentDay = daysOfWeek[today.getDay().toString() as Weekdays];
        const eventDay = daysOfWeek[weekday as Weekdays];
        if (eventDay === currentDay) {
            return "Today";
        } else if (eventDay === (currentDay + 1) % 7) {
            return "Tomorrow";
        }
        return weekday;
    }

    function getDisplayDate(weekday: string, creationDateStr: string, reoccurring: boolean): string {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Reset time to start of the day

        const nextWeek = new Date(today);
        nextWeek.setDate(nextWeek.getDate() + 6);

        creationDateStr = creationDateStr + 'T00:00:00'; // Append time component
        const creationDate = new Date(creationDateStr);
        creationDate.setHours(0, 0, 0, 0); // Reset time to start of the day

        // Format a date object into mm/dd/yyyy
        const formatDate = (date: Date): string => {
            const mm = (date.getMonth() + 1).toString();
            const dd = date.getDate().toString();
            const yyyy = date.getFullYear();
            return `${mm}/${dd}/${yyyy}`;
        };

        if (!reoccurring || creationDate.getTime() > nextWeek.getTime()) {
            // If not recurring or the date is more than a week out, return the creation date
            return formatDate(creationDate);
        }

        // If reoccurring and less than 1 week out
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const targetDayIndex = daysOfWeek.indexOf(weekday);
        const currentDayIndex = today.getDay();

        if (targetDayIndex === currentDayIndex) {
            return formatDate(today);
        } else {
            const daysToAdd = (targetDayIndex - currentDayIndex + 7) % 7;
            const targetDate = new Date(today);
            targetDate.setDate(today.getDate() + daysToAdd);
            return formatDate(targetDate);
        }
    }

    if (!eventsData) {
        return <div>No events to display</div>;
    }
    
    return (
        <div className="slider-container">
            <Slider {...settings} key={visibleEvents.length}>
                {visibleEvents && visibleEvents.map((event, index) => (
                    <div className="slide" key={index}>
                        <div className="card shadow-sm">
                            <Link to={`/event-page/${event.id}`}
                                  onClick={(e) => {
                                      if (!firstDrag) setDragging(false);
                                      if (dragging) e.preventDefault();
                                  }}
                                  className="custom-link">
                                <div className="ladder-info">
                                    <h4>{event.event_name}</h4>
                                    <p>{event.nextDay}, {event.nextDate}</p>
                                    <p>{convertToUserFriendlyTime(event.time)}</p>
                                    <p>Going: {event.user_count}</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default EventSlides;
