import React, {SyntheticEvent, useState} from "react";
import axios from "axios";
import {Navigate} from "react-router-dom";

export const Register = () => {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [userImage, setUserImage] = useState<File | null>(null);
    const [redirect, setRedirect] = useState(false);
    const [error, setError] = useState('');

    const US_STATES = [
        "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA",
        "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD",
        "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ",
        "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC",
        "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
    ];

    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {
            if (file.size > 33554432) { // 32 MB in bytes
                setError('Image size too large');
                return;
            }
            setUserImage(file);
            setError(''); // Clear any previous errors
        }
    };

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        // Check email
        if (!email) {
            setError('Please enter your email address.');
            return;
        }

        // Check first name
        if (!firstName) {
            setError('Please enter your first name.');
            return;
        }

        // Check last name
        if (!lastName) {
            setError('Please enter your last name.');
            return;
        }

        // Check address line 1
        if (!addressLine1) {
            setError('Please enter an address in Address Line 1.');
            return;
        }

        // Check city
        if (!city) {
            setError('Please enter a city.');
            return;
        }

        // Check state
        if (!state) {
            setError('Please select a state.');
            return;
        }

        // Check zip code
        if (!zipCode) {
            setError('Please enter a zip code.');
            return;
        }
        if (!/^\d{5}(-\d{4})?$/.test(zipCode)) {
            setError('Please enter a valid zip code. Format: 12345');
            return;
        }

        // Check password
        if (!password) {
            setError('Please enter a password.');
            return;
        }

        // Check password confirmation
        if (!passwordConfirm) {
            setError('Please confirm your password.');
            return;
        }

        // Check if password and passwordConfirm match
        if (password !== passwordConfirm) {
            setError('Password and Confirm Password do not match.');
            return;
        }

        // Send post request
        const formData = new FormData();
        formData.append('first_name', firstName);
        formData.append('last_name', lastName);
        formData.append('email', email);
        formData.append('password', password);
        formData.append('password_confirm', passwordConfirm);

        if (userImage) {
            // Get the file extension
            const fileExtension = userImage.name.split('.').pop();

            // Rename the file with the user name and its original extension
            const newFileName = `${firstName}_${lastName}.${fileExtension}`;
            const renamedFile = new File([userImage], newFileName, {
                type: userImage.type,
            });
            formData.append("user_image", renamedFile);
        }
        formData.append('address_line_1', addressLine1);
        formData.append('address_line_2', addressLine2);
        formData.append('city', city);
        formData.append('state', state);
        formData.append('zip_code', zipCode);

        try {
            const response = await axios.post('register', formData);

            if (response.status === 200) {
                setRedirect(true);
            } else {
                setError('Email already exists');
            }
        } catch (error) {
            setError('There was an error while creating the user.');
        }
    }

    if (redirect) {
        return <Navigate to="/login"/>
    }

    return (
        <div className="form-register-content">
            <main className="form-signin form-signin-width">
                <form onSubmit={submit}>
                    <h1 className="h3 mb-3 fw-normal">Register</h1>

                    <div className="form-floating">
                        <input type="email" className="form-control" id="floatingEmail" placeholder="name@example.com"
                               onChange={e => setEmail(e.target.value)}
                        />
                        <label htmlFor="floatingEmail">Email Address</label>
                    </div>

                    <div className="form-floating">
                        <input className="form-control" id="floatingFirstName" placeholder="First Name"
                               onChange={e => setFirstName(e.target.value)}
                        />
                        <label htmlFor="floatingFirstName">First Name</label>
                    </div>

                    <div className="form-floating">
                        <input className="form-control" id="floatingLastName" placeholder="Last Name"
                               onChange={e => setLastName(e.target.value)}
                        />
                        <label htmlFor="floatingLastName">Last Name</label>
                    </div>

                    {/* Image upload field */}
                    <div className="mb-3">
                        <label htmlFor="imageUpload" className="form-label">
                            Profile Picture
                        </label>
                        <input
                            type="file"
                            accept="image/*"
                            className="form-control form-control-image"
                            id="imageUpload"
                            onChange={handleImageUpload}
                        />
                    </div>

                    <div className="form-floating">
                        <input className="form-control" id="floatingAddressLine1" placeholder="Address Line 1"
                               onChange={e => setAddressLine1(e.target.value)}
                        />
                        <label htmlFor="floatingAddressLine1">Address Line 1</label>
                    </div>

                    <div className="form-floating">
                        <input className="form-control" id="floatingAddressLine2" placeholder="Address Line 2"
                               onChange={e => setAddressLine2(e.target.value)}
                        />
                        <label htmlFor="floatingAddressLine2">Address Line 2</label>
                    </div>

                    <div className="form-floating">
                        <input className="form-control" id="floatingCity" placeholder="City"
                               onChange={e => setCity(e.target.value)}
                        />
                        <label htmlFor="floatingCity">City</label>
                    </div>

                    <div className="form-floating">
                        <select
                            className="form-select"
                            id="floatingState"
                            value={state}
                            onChange={e => setState(e.target.value)}
                        >
                            <option value="" disabled>Select a State</option>
                            {US_STATES.map(s => (
                                <option key={s} value={s}>
                                    {s}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="form-floating">
                        <input className="form-control" id="floatingZipCode" placeholder="Zip Code"
                               onChange={e => setZipCode(e.target.value)}
                        />
                        <label htmlFor="floatingZipCode">Zip Code</label>
                    </div>

                    <div className="form-floating">
                        <input type="password" className="form-control" id="floatingPassword" placeholder="Password"
                               onChange={e => setPassword(e.target.value)}
                        />
                        <label htmlFor="floatingPassword">Password</label>
                    </div>

                    <div className="form-floating">
                        <input type="password" className="form-control" id="floatingPasswordConfirm" placeholder="Confirm Password"
                               onChange={e => setPasswordConfirm(e.target.value)}
                        />
                        <label htmlFor="floatingPasswordConfirm">Confirm Password</label>
                    </div>

                    {error && <div className="alert alert-danger mb-3">{error}</div>}

                    <button className="registerButton btn btn-primary w-100 py-2" type="submit">Register</button>
                </form>
            </main>
        </div>
    )
}