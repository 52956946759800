import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './interceptors/axios';
import { store } from './redux/store';
import { Provider } from "react-redux";

// Create a root.
const container = document.getElementById('root')!;
const root = createRoot(container);

// Render the app.
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);

// For measuring performance in your app
reportWebVitals();