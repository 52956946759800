import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faCrown, faMedal } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

type User = {
    user_id: number;
    rank: number;
    first_name: string;
    last_name: string;
    user_image: string;
    last_match_won: string;
};

type MatchupResponse = {
    match: User[];
    index: number;
};

type MatchHistoryItem = {
    event_id: number;
    match_date: string;
    player1: number;
    player2: number;
    player3: number | null;
    player4: number | null;
    winner: number | null;
    second: number | null;
};

type EventItem = {
    id: number;
    court1: string;
    court2: string;
    court3: string;
    court4: string;
    court5: string;
    court6: string;
    court7: string;
    court8: string;
    court9: string;
    court10: string;
};

const PlayerMatchup: React.FC<{
    eventId: string,
    gametype: string,
    userId: string,
    submitCounter: number,
    eventItem: EventItem,
    matchHistory: MatchHistoryItem[]
}> = ({ eventId, gametype, userId, submitCounter, eventItem, matchHistory }) => {
    const [matchup, setMatchup] = useState<User[]>([]);
    const [matchIndex, setMatchIndex] = useState<number | null>(null);
    const [loading, setLoading] = useState(false);
    const [courts, setCourts] = useState<string[]>([]);

    const MATCHUP_API_ENDPOINT = gametype === 'Singles' ?
        `player-matchup/${eventId}/${userId}` :
        `player-matchup-rr-doubles/${eventId}/${userId}`;

    useEffect(() => {
        const extractedCourts = [
            eventItem.court1, eventItem.court2, eventItem.court3, eventItem.court4, eventItem.court5,
            eventItem.court6, eventItem.court7, eventItem.court8, eventItem.court9, eventItem.court10
        ].filter(court => court !== null && court !== '');
        setCourts(extractedCourts);
    }, [eventItem]);

    useEffect(() => {
        const fetchMatchup = async () => {
            setLoading(true);
            try {
                const response = await axios.get<MatchupResponse>(MATCHUP_API_ENDPOINT);
                setMatchup(response.data.match);
                setMatchIndex(response.data.index);
            } catch (error) {
                console.error("Error fetching matchup:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchMatchup();
    }, [eventId, userId, MATCHUP_API_ENDPOINT, submitCounter]);

    return (
        <div className="ladder_members_container">
            <h4>Your Match</h4>
            <ul className="ladder_members_ul_player_matchup">
                {matchup.map(user => (
                    <li key={user.user_id} className={'ladder_members_li'}>
                        <div className="ladder_members_user-pill">
                            <div className="ladder_members_name">
                                <Link to={`/user-profile/${user.user_id}`} className={"profile-custom-link"}>
                                    <img
                                        src={user.user_image}
                                        alt={`${user.first_name} ${user.last_name}`}
                                        className="ladder_members_user-image"/>
                                    <span>{user.first_name} {user.last_name}</span>
                                </Link>
                            </div>
                            <div className="ladder_members_rank-container">
                                {user.last_match_won && <FontAwesomeIcon icon={faArrowUp}
                                                                         className="ladder_members_rank-arrow"/>}
                                {user.rank}
                            </div>
                        </div>
                        <div className={'ladder-members-scores'}>
                            {matchHistory.map(match => {
                                if (match.winner === user.user_id) {
                                    return <FontAwesomeIcon key={`winner-${match.event_id}-${user.user_id}`}
                                                            icon={faCrown} className="ladder_members_crown"/>;
                                } else if (match.second === user.user_id) {
                                    return <FontAwesomeIcon key={`second-${match.event_id}-${user.user_id}`}
                                                            icon={faMedal} className="ladder_members_medal"/>;
                                }
                                return null;
                            })}
                        </div>
                    </li>
                ))}
            </ul>
            {matchIndex !== null && courts.length > matchIndex && (
                <div className="court_assignment">
                    Court {courts[matchIndex]}
                </div>
            )}
            {loading && <p>Loading matchup...</p>}
        </div>
    );
};

export default PlayerMatchup;
