import {Link, useLocation} from "react-router-dom"
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../redux/store";
import {useEffect, useRef, useState} from "react";
import { setAuth } from "../redux/authSlice";
import { useUser } from "./UserContext";
import { useUnreadMessages } from "./UnreadMessageProvider"; // Import the hook

export const Nav = () => {
    const auth = useSelector((state: RootState) => state.auth.value);
    const dispatch = useDispatch();
    const location = useLocation();
    const [userImage, setUserImage] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const user = useUser();
    const { unreadMessages, fetchUnreadMessages } = useUnreadMessages(); // Destructure to include fetchUnreadMessages

    useEffect(() => {
        if (user && user.id) {
            fetchUnreadMessages(user.id);  // Pass the user ID to fetch messages
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    // User Image
    useEffect(() => {
        const fetchUserImage = async () => {
            // If userId is not null, return early to avoid refetching
            if (user && auth) {
                setUserImage(user.user_image)
            }
        };
        fetchUserImage();
    });

    useEffect(() => {
        // Handle clicks outside the dropdown to close it
        const handleClickOutside = (event: any) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    const logout = async () => {
        try {
            // Attempt to perform logout on the server
            await axios.post('logout', {}, {withCredentials: true});

            // Clear any stored tokens or user data from localStorage
            localStorage.removeItem('token');
            localStorage.removeItem('auth');

            // Clear the Authorization header from future axios requests
            axios.defaults.headers.common['Authorization'] = '';

            // Reset the authentication state
            dispatch(setAuth(false));

            // Redirect the user to the login page or homepage after logout
            window.location.href = '/'; // Use React Router if you're within a component
        } catch (error) {
            console.error('Logout failed', error);
            // Handle logout errors (e.g., network errors, server not responding)
            // Optionally, inform the user that the logout attempt was unsuccessful
        }
    }

    let rightLinks;
    let leftLinks;

    if (auth) {
        // Include a red dot if there are unread messages
        const unreadIndicator = Object.keys(unreadMessages).some(room => unreadMessages[room] > 0) ? <span className="unread-indicator"></span> : null;
        // Left Links
        leftLinks = <div className="d-flex align-items-center">
            <Link to="/" className={`nav-link px-2 nav-items ${location.pathname === "/" ? "active" : ""}`}>
                Ladders
            </Link>
            <Link to="/events" className={`nav-link px-2 nav-items ${location.pathname === "/events" ? "active" : ""}`}>
                Events
            </Link>
            <Link to="/messages" className={`nav-link px-2 nav-items ${location.pathname === "/messages" ? "active" : ""}`}>
                Messages{unreadIndicator}
            </Link>
            <Link to="/profile" className={`nav-link px-2 nav-items ${location.pathname === "/profile" ? "active" : ""}`}>
                Profile
            </Link>
        </div>

        rightLinks = (
            <div className="text-end" ref={dropdownRef}>
                <button
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    className="profile-link btn btn-link nav-no-border-on-active" // Add any necessary classes to make it look like your current link
                    style={{ textDecoration: 'none', color: 'inherit' }} // Inline styles to mimic an anchor tag visually
                >
                    {userImage ? (
                        <img src={userImage} alt="User Profile" className="profile-image" />
                    ) : 'Profile'}
                </button>
                {dropdownOpen && (
                    <ul className="dropdown-menu dropdown-menu-end show">
                        <li>
                            <Link className="dropdown-item rounded-2 nav-dropdown-item" to={`/account-page`}>
                                Account
                            </Link>
                        </li>

                        <li>
                            <Link className="dropdown-item rounded-2 nav-dropdown-item" to={`/faqs`}>
                                FAQ's
                            </Link>
                        </li>

                        <li>
                            <Link className="dropdown-item rounded-2 nav-dropdown-item" to="/contact-us">
                                Contact Us
                            </Link>
                        </li>

                        <div className="nav-divider"></div>
                        <li>
                            <Link to="/" onClick={logout} className="dropdown-item rounded-2 nav-dropdown-item">
                                Logout
                            </Link>
                        </li>
                    </ul>
                )}
            </div>
        );
    } else {
        // Left Links
        leftLinks = <div className="d-flex align-items-center"></div>

        // Right Links
        rightLinks = <div className="text-end">
            <Link to="/login" className="btn btn-outline-light me-2 nav-login-register">Login</Link>
            <Link to="/register" className="btn btn-outline-light me-2 nav-login-register">Register</Link>
        </div>
    }

    return (
        <header className="p-3 bg-dark text-white">
            <div className="nav-content">
                <div className="d-flex flex-wrap align-items-left justify-content-start justify-content-lg-start">
                    <div className={'logo-container'}>
                        <Link to="/" className="logo">
                            {/* Optionally, any text or icons that might need to be displayed over the background */}
                        </Link>
                    </div>
                    <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-start mb-md-0">
                        {leftLinks}
                    </ul>
                </div>
                { rightLinks }
            </div>
        </header>
    )
}