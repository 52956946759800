import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faCrown, faMedal } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

type User = {
    user_id: number;
    first_name: string;
    last_name: string;
    user_image: string;
    rank: number;
    last_match_won: boolean;
};

type MatchHistoryItem = {
    event_id: number;
    match_date: string;
    player1: number;
    player2: number;
    player3: number | null;
    player4: number | null;
    winner: number | null;
    second: number | null;
};

type Matchup = User[];

type EventItem = {
    id: number;
    court1: string;
    court2: string;
    court3: string;
    court4: string;
    court5: string;
    court6: string;
    court7: string;
    court8: string;
    court9: string;
    court10: string;
};

const EventMatchups: React.FC<{
    eventId: string,
    gametype: string,
    submitCounter: number,
    eventItem: EventItem,
    matchHistory: MatchHistoryItem[]
}> = ({ eventId, gametype, submitCounter, eventItem, matchHistory }) => {
    const [matchups, setMatchups] = useState<Matchup[]>([]);
    const [loading, setLoading] = useState(false);
    const [courts, setCourts] = useState<string[]>([]);

    const MATCHUPS_API_ENDPOINT = gametype === 'Singles' ?
        `calculate-matchups/${eventId}` :
        `calculate-matchups-rr-doubles/${eventId}`;

    useEffect(() => {
        const extractedCourts = [
            eventItem.court1, eventItem.court2, eventItem.court3, eventItem.court4, eventItem.court5,
            eventItem.court6, eventItem.court7, eventItem.court8, eventItem.court9, eventItem.court10
        ].filter(court => court !== null && court !== '');
        setCourts(extractedCourts);
    }, [eventItem]);

    useEffect(() => {
        const fetchMatchups = async () => {
            setLoading(true);
            try {
                const response = await axios.get(MATCHUPS_API_ENDPOINT);
                setMatchups(response.data);
            } catch (error) {
                console.error("Error fetching matchups:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchMatchups();
    }, [eventId, MATCHUPS_API_ENDPOINT, submitCounter]);

    return (
        <div className="ladder_members_container">
            <h4>Matches</h4>
            <ul className="ladder_members_ul">
                {matchups.map((matchup, index) => (
                    <div key={index} className="matchup_card">
                        {matchup.map(user => (
                            <li key={user.user_id} className='ladder_members_li'>
                                <div className="ladder_members_user-pill">
                                    <div className="ladder_members_name">
                                        <Link to={`/user-profile/${user.user_id}`} className={"profile-custom-link"}>
                                            <img
                                                src={`${user.user_image}`}
                                                alt={`${user.first_name} ${user.last_name}`}
                                                className="ladder_members_user-image"/>
                                            <span>{user.first_name} {user.last_name}</span>
                                        </Link>
                                    </div>
                                    <div className="ladder_members_rank-container">
                                        {user.last_match_won && <FontAwesomeIcon icon={faArrowUp}
                                                                                 className="ladder_members_rank-arrow"/>}
                                        {user.rank}
                                    </div>
                                </div>
                                <div className={'ladder-members-scores'}>
                                    {matchHistory.some(match => match.winner === user.user_id) &&
                                        <FontAwesomeIcon icon={faCrown} className="ladder_members_crown"/>}
                                    {matchHistory.some(match => match.second === user.user_id) &&
                                        <FontAwesomeIcon icon={faMedal} className="ladder_members_medal"/>}
                                </div>
                            </li>
                        ))}
                        {courts.length > index && (
                            <div className="court_assignment">
                                Court {courts[index]}
                            </div>
                        )}
                    </div>
                ))}
            </ul>
            {loading && <p>Loading matchups...</p>}
        </div>
    );
};

export default EventMatchups;
