import {UpdateLadderInfo} from "./UpdateLadderInfo";
import { LadderImage } from './LadderImage';
import { DeleteLadder } from "./DeleteLadder";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export const UpdateLadder = () => {
    const navigate = useNavigate();

    // Function to navigate back
    const handleBackClick = () => {
        navigate(-1);
    };
    return (
        <div>
            <button onClick={handleBackClick} className="back-to-ladder-button">
                <FontAwesomeIcon icon={faArrowLeft} />
                <span>Back to Ladder</span>
            </button>
            <div className="update-ladder-container1">
                <UpdateLadderInfo />
            </div>
            <div className="update-ladder-container3">
                <LadderImage />
            </div>
            <div className="update-ladder-container2">
                <DeleteLadder />
            </div>
        </div>
    );
}
