import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

interface eventUser {
  user_id: number;
  first_name: string;
  last_name: string;
  user_image: string;
  rank: number;
  last_match_won: boolean;
}

interface ladderUser {
  id: number;
  first_name: string;
  last_name: string;
}

interface UserInfo {
  id: number;
  full_name: string;
  email: string;
}

export const EventHostOptions: React.FC = () => {
    const { eventId, ladderId } = useParams<{ eventId: string; ladderId: string }>();
    const [lockStatus, setLockStatus] = useState<boolean | null>(null);
    const [error, setError] = useState<string>('');
    const auth = useSelector((state: RootState) => state.auth.value);
    const navigate = useNavigate();
    const [eventUsers, setEventUsers] = useState<eventUser[]>([]);
    const [ladderUsers, setLadderUsers] = useState<ladderUser[]>([]);
    const [selectedAddUser, setSelectedAddUser] = useState<number | null>(null);
    const [selectedRemoveUser, setSelectedRemoveUser] = useState<number | null>(null);
    const [userInfos, setUserInfos] = useState<UserInfo[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [showEmails, setShowEmails] = useState<boolean>(false);
    const [snackbar, setSnackbar] = useState<{ show: boolean; message: string }>({ show: false, message: '' });
    const [selectedEmailUsers, setSelectedEmailUsers] = useState<number[]>([]);
    const [emailBody, setEmailBody] = useState<string>('');
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [showEventUsers, setShowEventUsers] = useState<boolean>(true); // New state to toggle between event users and ladder users
    const [selectedChangeRankUser, setSelectedChangeRankUser] = useState<number | null>(null);
    const [newRank, setNewRank] = useState<string>('');

    useEffect(() => {
        const fetchLockStatus = async () => {
            if (eventId) {
                try {
                    const response = await axios.get<{ lock_event: boolean | null }>(`lock-event/${eventId}`);
                    if (response.status === 200) {
                        setLockStatus(response.data.lock_event);
                    }
                } catch (error) {
                    console.error("Error fetching lock status:", error);
                    setError('Unable to fetch lock status. Please try again.');
                }
            }
        };

        fetchLockStatus();
    }, [eventId]);

    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            try {
                const response = await axios.get<eventUser[]>(`event-users/${eventId}`);
                setEventUsers(response.data);
            } catch (error) {
                console.error("Error fetching users:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, [eventId]);

    useEffect(() => {
        const fetchLadderUsers = async () => {
            setLoading(true);
            try {
                const response = await axios.get<ladderUser[]>(`ladder-users/${ladderId}`);
                setLadderUsers(response.data);
            } catch (error) {
                console.error("Error fetching ladder users:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchLadderUsers();
    }, [ladderId]);

    useEffect(() => {
        if (ladderId) {
            const fetchUserInfos = async () => {
                try {
                    const response = await axios.get<UserInfo[]>(`host-options-user-info/${ladderId}`);
                    if (response.status === 200) {
                        setUserInfos(response.data);
                        setShowEmails(true);
                    }
                } catch (error) {
                    console.error('Error fetching user information:', error);
                }
            };

            fetchUserInfos();
        }
    }, [ladderId]);

    const toggleLockStatus = async (newLockStatus: boolean | null) => {
        if (eventId) {
            try {

                const response = await axios.patch<{ lock_event: boolean | null }>(`lock-event/${eventId}`, {
                    lock_event: newLockStatus
                });
                if (response.status === 200) {
                    setLockStatus(response.data.lock_event);
                    showSnackbar(`Lock status updated successfully to ${response.data.lock_event ? 'locked' : response.data.lock_event === false ? 'unlocked' : 'auto'}.`);
                }
            } catch (error) {
                console.error('Failed to update lock status:', error);
                setError('Failed to update lock status. Please try again.');
            }
        }
    };

    const addUser = async () => {
        if (selectedAddUser && eventId) {
            try {
                const response = await axios.post('add-user-to-event', {
                    user_id: selectedAddUser,
                    event_id: eventId
                });
                if (response.status === 200) {
                    showSnackbar('User added successfully.');
                }
            } catch (error) {
                setError('Failed to add user. Please try again.');
            }
        }
    };

    const removeUser = async () => {
        if (selectedRemoveUser && eventId) {
            try {
                const response = await axios.post('remove-user-from-event', {
                    user_id: selectedRemoveUser,
                    event_id: eventId
                });
                if (response.status === 200) {
                    showSnackbar('User removed successfully.');
                    setEventUsers(eventUsers.filter(user => user.user_id !== selectedRemoveUser)); // Update local list
                }
            } catch (error) {
                console.error('Failed to remove user:', error);
                setError('Failed to remove user. Please try again.');
            }
        }
    };

    // Function to handle rank change
    const changeUserRank = async () => {
        if (selectedChangeRankUser && newRank && ladderId) {
            try {
                const rankValue = parseFloat(newRank); // Ensure the rank is a float
                const response = await axios.patch(`ladder/${ladderId}/user/${selectedChangeRankUser}/change-rank`, {
                    new_rank: rankValue
                });
                if (response.status === 200) {
                    showSnackbar('Rank changed successfully.');
                    // Optionally update local state or refetch data to reflect the change
                }
            } catch (error) {
                console.error('Failed to change rank:', error);
                setError('Failed to change rank. Please try again.');
            }
        } else {
            setError('Please select a user and enter a valid rank.');
        }
    };

    const handleEmailCheckboxChange = (userId: number) => {
        setSelectedEmailUsers(prevState =>
            prevState.includes(userId)
                ? prevState.filter(id => id !== userId)
                : [...prevState, userId]
        );
    };

    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedEmailUsers([]);
        } else {
            const usersToSelect = showEventUsers ? eventUsers.map(user => user.user_id) : ladderUsers.map(user => user.id);
            setSelectedEmailUsers(usersToSelect);
        }
        setSelectAll(!selectAll);
    };

    const sendEmail = async () => {
        if (selectedEmailUsers.length === 0 || emailBody.trim() === '') {
            setError('Please select users and enter an email body.');
            return;
        }
        try {
            const response = await axios.post('event-email', {
                user_ids: selectedEmailUsers,
                email_body: emailBody,
                event_id: eventId
            });
            if (response.status === 200) {
                showSnackbar('Email sent successfully.');
                setEmailBody('');
                setSelectedEmailUsers([]);
                setSelectAll(false);
            }
        } catch (error) {
            console.error('Failed to send email:', error);
            setError('Failed to send email. Please try again.');
        }
    };

    const showSnackbar = (message: string) => {
        setSnackbar({ show: true, message });
        setTimeout(() => setSnackbar({ show: false, message: '' }), 3000);
    };

    const handleBackClick = () => {
        navigate(-1);
    };

    const getLockStatusDescription = () => {
        switch (lockStatus) {
            case true:
                return 'Locked - Score submissions are locked';
            case false:
                return 'Unlocked - Score submissions are unlocked';
            case null:
                return 'Auto - Score submissions are unlocked only when event is ongoing';
            default:
                return '';
        }
    };

    // Additional API call functions
    const addGuestUser = async () => {
        try {
            const response = await axios.post('add-guest-to-event', {
                event_id: eventId
            });
            if (response.status === 200) {
                showSnackbar('Guest user added successfully.');
            }
        } catch (error) {
            console.error('Failed to add guest user:', error);
            setError('Failed to add guest user. Please try again.');
        }
    };

    const removeAllGuests = async () => {
        try {
            const response = await axios.post('remove-guests-from-event', {
                event_id: eventId
            });
            if (response.status === 200) {
                showSnackbar('All guest users removed successfully.');
            }
        } catch (error) {
            console.error('Failed to remove all guest users:', error);
            setError('Failed to remove all guest users. Please try again.');
        }
    };

    if (loading) return <div>Loading...</div>; // Use the loading state

    return (
        <div>
            <button onClick={handleBackClick} className="back-to-ladder-button">
                <FontAwesomeIcon icon={faArrowLeft}/>
                <span>Back to Event</span>
            </button>
            {auth && (
                <div className="container">
                    <h1 className="h3 mb-3 fw-normal">Host Options</h1>

                    {/*Lock Event*/}
                    <h5>Score Submission</h5>
                    <div className="btn-group mt-3" role="group"
                         aria-label="Lock Options">
                        <button
                            type="button"
                            className={`btn ${lockStatus === false ? 'btn-primary' : 'btn-outline-primary'}`}
                            onClick={() => toggleLockStatus(false)}
                        >
                            Unlock
                        </button>
                        <button
                            type="button"
                            className={`btn ${lockStatus === true ? 'btn-primary' : 'btn-outline-primary'}`}
                            onClick={() => toggleLockStatus(true)}
                        >
                            Lock
                        </button>
                        <button
                            type="button"
                            className={`btn ${lockStatus === null ? 'btn-primary' : 'btn-outline-primary'}`}
                            onClick={() => toggleLockStatus(null)}
                        >
                            Auto
                        </button>
                    </div>
                    <p className="lock-status-description">
                        {getLockStatusDescription()}
                    </p>

                    {/*Add user*/}
                    <h5>Add User to Event</h5>
                    <select className="form-select mt-3 event-host-options-select-remove-user"
                            value={selectedAddUser || ''}
                            onChange={(e) => setSelectedAddUser(parseInt(e.target.value))}>
                        <option value="">Select User</option>
                        {ladderUsers.map((user) => (
                            <option key={user.id} value={user.id}>
                                {user.first_name} {user.last_name}
                            </option>
                        ))}
                    </select>
                    <button className="btn btn-success mt-2 event-host-options-botton event-host-options-lock-button"
                            onClick={addUser} disabled={!selectedAddUser}>
                        Add User
                    </button>

                    {/*Remove User*/}
                    <h5>Remove User from Event</h5>
                    <select className="form-select mt-3 event-host-options-select-remove-user"
                            value={selectedRemoveUser || ''}
                            onChange={(e) => setSelectedRemoveUser(parseInt(e.target.value))}>
                        <option value="">Select User</option>
                        {eventUsers.map((user) => (
                            <option key={user.user_id} value={user.user_id}>
                                {user.first_name} {user.last_name}
                            </option>
                        ))}
                    </select>
                    <button className="btn btn-danger mt-2 event-host-options-botton event-host-options-lock-button"
                            onClick={removeUser} disabled={!selectedRemoveUser}>
                        Remove User
                    </button>

                    {/* New Buttons for Guest User Management */}
                    <h5>Add or Remove Guest User</h5>
                    <div className="host-options-rankChangeContainer">
                        <button className="btn btn-success mt-2 event-host-options-botton event-host-options-lock-button"
                                onClick={addGuestUser}>
                            Add Guest User
                        </button>
                        <button className="btn btn-danger mt-2 event-host-options-botton event-host-options-lock-button"
                                onClick={removeAllGuests}>
                            Remove Guest Users
                        </button>
                    </div>

                    <h5>Change User Rank</h5>
                    <p className="text-warning mt-2">
                        Note: Changing rank while games are in progress will affect matchmaking.
                    </p>
                    <select className="form-select event-host-options-select-remove-user"
                            value={selectedChangeRankUser || ''}
                            onChange={(e) => setSelectedChangeRankUser(parseInt(e.target.value))}>
                        <option value="">Select User</option>
                        {ladderUsers.map((user) => (
                            <option key={user.id} value={user.id}>
                                {user.first_name} {user.last_name}
                            </option>
                        ))}
                    </select>
                    <div className={'host-options-rankChangeContainer'}>
                        <input type="number" className="form-control event-host-options-change-rank"
                               value={newRank}
                               onChange={(e) => setNewRank(e.target.value)}
                               placeholder="New rank"
                               step="0.1"/>
                        <button className="btn btn-info event-host-options-lock-button event-host-options-change-rank"
                                onClick={changeUserRank}
                                disabled={!selectedChangeRankUser || newRank === ''}>
                            Change Rank
                        </button>
                    </div>

                    {/*Email Users*/}
                    <h5>Email Event Participants</h5>
                    <div className="btn-group mt-3 event-host-options-lock-button" role="group"
                         aria-label="Toggle User Type">
                        <button
                            type="button"
                            className={`btn ${showEventUsers ? 'btn-primary' : 'btn-outline-primary'}`}
                            onClick={() => setShowEventUsers(true)}
                        >
                            Event Users
                        </button>
                        <button
                            type="button"
                            className={`btn ${!showEventUsers ? 'btn-primary' : 'btn-outline-primary'}`}
                            onClick={() => setShowEventUsers(false)}
                        >
                            Ladder Users
                        </button>
                    </div>
                    <div className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="selectAll"
                            checked={selectAll}
                            onChange={handleSelectAllChange}
                        />
                        <label className="form-check-label" htmlFor="selectAll">
                            Select/Unselect All Users
                        </label>
                    </div>
                    <div className="form-group mt-3">
                        {showEventUsers ? (
                            eventUsers.map((user) => (
                                <div className="form-check" key={user.user_id}>
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={`user-${user.user_id}`}
                                        checked={selectedEmailUsers.includes(user.user_id)}
                                        onChange={() => handleEmailCheckboxChange(user.user_id)}
                                    />
                                    <label className="form-check-label" htmlFor={`user-${user.user_id}`}>
                                        {user.first_name} {user.last_name}
                                    </label>
                                </div>
                            ))
                        ) : (
                            ladderUsers.map((user) => (
                                <div className="form-check" key={user.id}>
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={`user-${user.id}`}
                                        checked={selectedEmailUsers.includes(user.id)}
                                        onChange={() => handleEmailCheckboxChange(user.id)}
                                    />
                                    <label className="form-check-label" htmlFor={`user-${user.id}`}>
                                        {user.first_name} {user.last_name}
                                    </label>
                                </div>
                            ))
                        )}
                    </div>
                    <textarea
                        className="form-control mt-3"
                        rows={5}
                        value={emailBody}
                        onChange={(e) => setEmailBody(e.target.value)}
                        placeholder="Write your email here..."
                    />
                    <button className="btn btn-primary mt-2 event-host-options-lock-button"
                            onClick={sendEmail} disabled={selectedEmailUsers.length === 0 || emailBody.trim() === ''}>
                        Send Email
                    </button>

                    {/*User Emails*/}
                    {showEmails &&
                        <div>
                            <h5>User Emails</h5>
                            <table className="table event-host-options-userTable">
                                <thead>
                                <tr>
                                    <th>Full Name</th>
                                    <th>Email</th>
                                </tr>
                                </thead>
                                <tbody>
                                {userInfos.map((user) => (
                                    <tr key={user.id}>
                                        <td>{user.full_name}</td>
                                        <td>{user.email}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    }

                    {/*Snackbar*/}
                    {error && <div className="alert alert-danger mt-2">{error}</div>}
                    {snackbar.show && (
                        <div className="snackbar">
                            {snackbar.message}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
