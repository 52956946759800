import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown, faMedal } from '@fortawesome/free-solid-svg-icons';

type Player = {
    user_id: number;
    first_name: string;
    last_name: string;
    user_image: string;
    rank: number;
    last_match_won: boolean;
};

type Event = {
    id: number;
    event_name: string;
    activity: string;
    gametype: string;
    ladder_id: number;
    ladder_image: string;
    address_name: string;
    address_line_1: string;
    address_line_2: string | null;
    city: string;
    state: string;
    zip_code: number;
    latitude: number;
    longitude: number;
    distance: number;
    owner: number;
    user_count: number;
    description: string;
    weekday: string;
    time: string;
    creation_date: string;
    reoccurring: boolean;
};

type Scores = {
    [set: string]: [string, string];
};

type TennisScoreboardDoublesProps = {
    eventId: string;
    userId: string;
    eventItem: Event;
    onScoreSubmit: () => void;
    submitCounter: number;
};

const TennisScoreboardDoubles: React.FC<TennisScoreboardDoublesProps> = ({
    eventId,
    userId,
    onScoreSubmit,
    eventItem,
    submitCounter
}) => {
    const [players, setPlayers] = useState<Player[]>([]);
    const [scores, setScores] = useState<Scores>({
        set1: ['', ''],
        set2: ['', ''],
        set3: ['', '']
    });
    const [winners, setWinners] = useState<{[set: string]: number | null}>({
        set1: null,
        set2: null,
        set3: null
    });
    const [matchInProgress, setMatchInProgress] = useState<boolean>(false);
    const [lockStatus, setLockStatus] = useState<boolean | null>(null);
    const [snackbar, setSnackbar] = useState<{ show: boolean; message: string }>({
        show: false,
        message: ''
    });

    // Display Today or Tomorrow if the weekday is Today or Tomorrow
    function getDisplayDay(weekday: string, creationDateStr: string, reoccurring: boolean): string {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Reset time to start of the day

        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);

        creationDateStr = creationDateStr + 'T00:00:00'; // Append time component
        const creationDate = new Date(creationDateStr);
        creationDate.setHours(0, 0, 0, 0); // Reset time to start of the day

        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        const nextWeek = new Date(today);
        nextWeek.setDate(nextWeek.getDate() + 6);

        if (creationDate.getTime() > nextWeek.getTime()) {
            reoccurring = false;
        }

        if (!reoccurring) {
            if (creationDate.getTime() === today.getTime()) {
                return "today";
            } else if (creationDate.getTime() === tomorrow.getTime()) {
                return "tomorrow";
            }
            // Return the weekday if the event is not today or tomorrow
            return weekday;
        }

        // For reoccurring events, return today or tomorrow if applicable, otherwise return the weekday
        const currentDay = daysOfWeek[today.getDay()];
        if (weekday === currentDay) {
            return "today";
        } else if (daysOfWeek[(today.getDay() + 1) % 7] === weekday) {
            return "tomorrow";
        }

        return weekday;
    }

    // Check if for saved scores in existing sessions
    useEffect(() => {
        const filter = window.sessionStorage.getItem(eventId+"set10");

        if (filter) {
            const set1_team1 = window.sessionStorage.getItem(eventId+"set10") || '';
            const set1_team2 = window.sessionStorage.getItem(eventId+"set11") || '';
            const set2_team1 = window.sessionStorage.getItem(eventId+"set20") || '';
            const set2_team2 = window.sessionStorage.getItem(eventId+"set21") || '';
            const set3_team1 = window.sessionStorage.getItem(eventId+"set30") || '';
            const set3_team2 = window.sessionStorage.getItem(eventId+"set31") || '';
            const set1_winner = window.sessionStorage.getItem(eventId + "set1");
            const set2_winner = window.sessionStorage.getItem(eventId + "set2");
            const set3_winner = window.sessionStorage.getItem(eventId + "set3");

            setScores({
                set1: [set1_team1, set1_team2],
                set2: [set2_team1, set2_team2],
                set3: [set3_team1, set3_team2]
            });

            setWinners({
                set1: set1_winner !== null ? parseInt(set1_winner) : null,
                set2: set2_winner !== null ? parseInt(set2_winner) : null,
                set3: set3_winner !== null ? parseInt(set3_winner) : null,
            })
        }
    },[eventId]);

    // Check if match is in progress
    useEffect(() => {
        const checkMatchInProgress = (): void => {
            if (userId && eventItem) {
                const today = new Date();
                const currentHours = today.getHours();
                const currentMinutes = today.getMinutes();

                const eventDay = getDisplayDay(eventItem.weekday, eventItem.creation_date, eventItem.reoccurring);
                if (eventDay !== 'today') {
                    setMatchInProgress(false);
                    return;
                }

                const [eventHours, eventMinutes] = eventItem.time.split(':').map(Number);

                const isInProgress = currentHours > eventHours || (currentHours === eventHours && currentMinutes >= eventMinutes);
                setMatchInProgress(isInProgress);
            }
        };

        checkMatchInProgress();
    }, [userId, eventItem, matchInProgress]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`player-matchup-rr-doubles/${eventId}/${userId}`);
                setPlayers(response.data.match);
            } catch (error) {
                console.error('Error fetching player data:', error);
                alert('Failed to load player data.');
            }
        };
        fetchData();
    }, [eventId, userId, submitCounter]);

    // Fetch the current lock status
    useEffect(() => {
        const fetchLockStatus = async () => {
            if (eventId) {
                try {
                    const response = await axios.get(`lock-event/${eventId}`);
                    if (response.status === 200) {
                        setLockStatus(response.data.lock_event !== null ? response.data.lock_event : false);
                    }
                } catch (error) {
                    console.error("Error fetching lock status:", error);
                }
            }
        };

        fetchLockStatus();
    }, [eventId]);

    const handleWinnerChange = (set: string, teamIndex: number) => {
        setWinners(prevWinners => {
            const newWinner = prevWinners[set] === teamIndex ? null : teamIndex;
            // Update session storage based on the new winner
            window.sessionStorage.setItem(eventId + set, newWinner !== null ? newWinner.toString() : '');
            return {
                ...prevWinners,
                [set]: newWinner
            };
        });
    };
    

    const handleScoreChange = (set: string, teamIndex: number, score: string) => {
        setScores(prevScores => ({
            ...prevScores,
            [set]: teamIndex === 0 ? [score, prevScores[set][1]] : [prevScores[set][0], score]
        }));
        window.sessionStorage.setItem(eventId+set+teamIndex.toString(), score);
    };

    // Validate that every set has a winner selected
    const allWinnersSelected = (): boolean => {
        return Object.values(winners).every(winner => winner !== null);
    };

    // Handle submit scores click
    const handleSubmitScore = async () => {
        if (!allWinnersSelected()) {
            showSnackbar('Please select a winner for each set');
            return;
        }

        // Create a sorted copy of playersWithScores array based on user_id
        const playersWithScoresSorted = [...playersWithScores].sort((a, b) => a.user_id - b.user_id);

        const payload = {
            event: parseInt(eventId),
            match_date: new Date().toLocaleDateString('en-CA'),
            player1: playersWithScoresSorted[0]?.user_id,
            player2: playersWithScoresSorted[1]?.user_id,
            player3: playersWithScoresSorted[2]?.user_id,
            player4: playersWithScoresSorted[3]?.user_id,
            set1_player1: playersWithScoresSorted[0]?.setsWon,
            set1_player2: playersWithScoresSorted[1]?.setsWon,
            set1_player3: playersWithScoresSorted[2]?.setsWon,
            set1_player4: playersWithScoresSorted[3]?.setsWon,
            set2_player1: playersWithScoresSorted[0]?.gamesWon,
            set2_player2: playersWithScoresSorted[1]?.gamesWon,
            set2_player3: playersWithScoresSorted[2]?.gamesWon,
            set2_player4: playersWithScoresSorted[3]?.gamesWon,
            winner: playersWithScores[0]?.user_id,
            second: playersWithScores[1]?.user_id
        };

        window.sessionStorage.removeItem(eventId + "set10");
        window.sessionStorage.removeItem(eventId + "set11");
        window.sessionStorage.removeItem(eventId + "set20");
        window.sessionStorage.removeItem(eventId + "set21");
        window.sessionStorage.removeItem(eventId + "set30");
        window.sessionStorage.removeItem(eventId + "set31");
        window.sessionStorage.removeItem(eventId + "set1");
        window.sessionStorage.removeItem(eventId + "set2");
        window.sessionStorage.removeItem(eventId + "set3");

        try {
            console.log(payload)
            await axios.post('post-match-history', payload);
            showSnackbar('Score submitted successfully');
            // Call the callback function passed as a prop with the payload or any data you want to send
            onScoreSubmit();
        } catch (error) {
            console.error('Error submitting score:', error);
            showSnackbar('Error submitting score');
        }
    };

    const pairNames = (index1: number, index2: number) => `${players[index1].first_name} ${players[index1].last_name} / ${players[index2].first_name} ${players[index2].last_name}`;

    if (players.length !== 4) {
        return (
            <div className={"tennis-scoreboard-no-match"}>
                <h6>Four players are required to create a matchup</h6>
            </div>
        )
    }

    const matchups = [
        [0, 1, 2, 3], // P1 P2 vs P3 P4
        [0, 2, 1, 3], // P1 P3 vs P2 P4
        [0, 3, 1, 2]  // P1 P4 vs P2 P3
    ];

    const calculateOverallScores = () => {
        const setsWon = Array(players.length).fill(0);
        const gamesWon = Array(players.length).fill(0);

        Object.entries(scores).forEach(([setName, teamScores]) => {
            const setWinnerTeam = winners[setName];

            if (setWinnerTeam !== null) {
                const winningPair = matchups[parseInt(setName.replace('set', '')) - 1].slice(setWinnerTeam * 2, setWinnerTeam * 2 + 2);
                winningPair.forEach(playerIndex => setsWon[playerIndex]++);
            }

            teamScores.forEach((score, teamIndex) => {
                const teamPlayerIndices = matchups[parseInt(setName.replace('set', '')) - 1].slice(teamIndex * 2, teamIndex * 2 + 2);
                teamPlayerIndices.forEach(playerIndex => gamesWon[playerIndex] += parseInt(score) || 0);
            });
        });

        return { setsWon, gamesWon };
    };

    const { setsWon, gamesWon } = calculateOverallScores();

    // Combine players with their scores and sort them
    const playersWithScores = players.map((player, index) => ({
        ...player,
        setsWon: setsWon[index],
        gamesWon: gamesWon[index]
    }));

    playersWithScores.sort((a, b) =>
        b.setsWon - a.setsWon || b.gamesWon - a.gamesWon || a.rank - b.rank);

    const placements = ['1st', '2nd', '3rd', '4th'];

    // Function to handle the dynamic scoring label based on the event activity
    const getScoringLabel = () => {
        switch (eventItem.activity) {
            case "Volleyball":
                return ["Sets Won", "Points Won"];
            case "Pickleball":
                return ["Games Won", "Points Won"];
            default: // Default to tennis
                return ["Sets Won", "Games Won"];
        }
    };
    const scoringLabels = getScoringLabel(); // Get labels depending on the activity

    // Function to handle the dynamic scoring instructions based on the event activity
    const getScoringInstructions = () => {
        switch (eventItem.activity) {
            case "Volleyball":
                return [
                    <li key="1">Count sets</li>,
                    <li key="2">If tie, count points</li>,
                    <li key="3">If tie, highest ranked player is the winner</li>
                ];
            case "Pickleball":
                return [
                    <li key="1">Count games</li>,
                    <li key="2">If tie, count points</li>,
                    <li key="3">If tie, highest ranked player is the winner</li>
                ];
            default: // Default to Tennis
                return [
                    <li key="1">Count sets</li>,
                    <li key="2">If tie, count games</li>,
                    <li key="3">If tie, highest ranked player is the winner</li>
                ];
        }
    };
    const scoringInstructions = getScoringInstructions(); // Get instructions depending on the activity

    // Snackbar
    const showSnackbar = (message: string) => {
        setSnackbar({ show: true, message });
        setTimeout(() => setSnackbar({ show: false, message: '' }), 3000);
    };

    return (
        <div>
            {matchups.map((matchup, index) => (
                <div key={index}>
                    <table className="tennis-scoreboard-table">
                        <thead>
                            <tr>
                                <th className="player-name">Team</th>
                                <th className="set-score">Score</th>
                                <th className="set-score">Winner</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="player-name">{pairNames(matchup[0], matchup[1])}</td>
                                <td className="set-score">
                                    <input
                                        type="number"
                                        value={scores[`set${index + 1}`][0]}
                                        onChange={(e) => handleScoreChange(`set${index + 1}`, 0, e.target.value)}
                                        min="0"
                                    />
                                </td>
                                <td className="winner-checkbox">
                                    <input
                                        type="checkbox"
                                        checked={winners[`set${index + 1}`] === 0}
                                        onChange={() => handleWinnerChange(`set${index + 1}`, 0)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="player-name">{pairNames(matchup[2], matchup[3])}</td>
                                <td className="set-score">
                                    <input
                                        type="number"
                                        value={scores[`set${index + 1}`][1]}
                                        onChange={(e) => handleScoreChange(`set${index + 1}`, 1, e.target.value)}
                                        min="0"
                                    />
                                </td>
                                <td className="winner-checkbox">
                                    <input
                                        type="checkbox"
                                        checked={winners[`set${index + 1}`] === 1}
                                        onChange={() => handleWinnerChange(`set${index + 1}`, 1)}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ))}
            <table className="tennis-scoreboard-table">
                <thead>
                    <tr>
                        <th >Placement</th>
                        <th>Player</th>
                        {scoringLabels.map((label, index) => (
                            <th key={index}>{label}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {playersWithScores.map((player, index) => (
                        <tr key={player.user_id}>
                            <td>{placements[index]}</td>
                            <td>{player.first_name} {player.last_name}</td>
                            <td>{player.setsWon}</td>
                            <td>{player.gamesWon}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="score-submission-container">
                {
                    lockStatus === true ? (
                        <p className="event_page-note-to-players2">
                            Host has locked score submissions.
                        </p>
                    ) : (
                        matchInProgress || lockStatus === false ?
                            <p className="event_page-note-to-players2">
                                Players have until midnight to submit scores
                            </p>
                            :
                            <p className="event_page-note-to-players2">
                                Score submission will be enabled once matches are locked
                            </p>
                    )
                }
                {
                    lockStatus === true ? (
                        <div className="joined-button locked-button score-submit-button">
                            Locked
                        </div>
                    ) : (
                        (matchInProgress || lockStatus === false) && players.length > 0 ?
                            <div>
                                <button className={"join-button score-submit-button"}
                                        onClick={handleSubmitScore}>
                                    {"Submit"}
                                </button>
                                <h5>Scoring</h5>
                                <ol>{scoringInstructions}</ol>
                                <h5>Key</h5>
                                <div>
                                    <div><FontAwesomeIcon icon={faCrown}/> - Winner</div>
                                    <div><FontAwesomeIcon icon={faMedal} /> - Second Place</div>
                                </div>
                            </div>
                            :
                            <div className="joined-button locked-button score-submit-button">
                            Locked
                            </div>
                    )
                }
            </div>
            {/* Snackbar */}
            {snackbar.show && (
                <div className="snackbar">
                    {snackbar.message}
                </div>
            )}
        </div>
    );
};

export default TennisScoreboardDoubles;
