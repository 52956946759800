import { useEffect, useState, SyntheticEvent } from 'react';
import axios from 'axios';
import { useSelector } from "react-redux";
import { RootState } from '../redux/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import MatchHistory from './MatchHistory';
import { useUser } from './UserContext';

interface LadderData {
    id: number;
    rank: number;
    last_match_won: boolean;
    ladder_id: number;
    ladder_name: string;
    ladder_image: string;
    first_name: string;
    last_name: string;
    user_image: string;
}

export const Profile = () => {
    const auth = useSelector((state: RootState) => state.auth.value);
    const [userData, setUserData] = useState<any | null>(null);
    const [aboutMeTemp, setAboutMeTemp] = useState(''); // Temporary state for editing about me
    const [isEditing, setIsEditing] = useState(false);
    const [ladderData, setLadderData] = useState<LadderData[]>([]);
    const user = useUser();

    useEffect(() => {
        (async () => {
            if (user && auth) {
                setUserData(user);
            }
        })();
    }, [user, auth]);

    // User Ladder Data
    useEffect(() => {
        if (userData && userData.id) {
            const fetchEventData = async () => {
                try {
                    const response = await axios.get(`ladder-users/user/${userData.id}`);
                    if (response.status === 200) {
                        setLadderData(response.data)
                    }
                }
                catch (e) {
                    console.log(e)
                }
            };
            // Now invoke the async function
            fetchEventData().then(() => {
                // Whatever you'd like to execute after fetchEventData completes
                console.log("Ladder data fetched successfully!")
            });
        }
    }, [userData]);

    if (!userData) {
        return <div>Loading...</div>;
    }

    const handleEditClick = () => {
        setIsEditing(true);
        setAboutMeTemp(userData.about_me); // Initialize temporary state with current about me
    };

    const handleSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();
        const updatedUserData = { ...userData, about_me: aboutMeTemp }; // Update userData with the new about me from temp state

        try {
            const response = await axios.put('update-user', updatedUserData);
            if (response.status === 200) {
                setUserData(updatedUserData); // Update local userData state with the new about me
                setIsEditing(false);
            }
        } catch (e) {
            console.log(e);
            setIsEditing(false);
        }
    };
    
    return (
        <div className='profile-page'>
            <div className="ladder-page-container2">
                <div className="ladder-page-description2">
                    <div className="profile-user-info">
                        <img src={`${userData.user_image}`} alt={userData.fullName} className="profile-img"/>
                    </div>
                    <h4>{`${userData.first_name} ${userData.last_name}`}</h4>
                    <div className="profile-ladder-page-description profile-pill-container">
                        <div className="profile-about-me-header">
                            <h4>About Me</h4>
                            {!isEditing && (
                                <button className="profile-edit-btn" onClick={handleEditClick}>
                                    <FontAwesomeIcon icon={faPencilAlt} size="lg"/>
                                </button>
                            )}
                        </div>
                        {isEditing ? (
                            <form onSubmit={handleSubmit} className="profile-about-me-form">
                                <textarea
                                    className="profile-form-control"
                                    defaultValue={userData.about_me} // Use defaultValue for initial load
                                    onChange={(e) => setAboutMeTemp(e.target.value)} // Update local state on change
                                />
                                <button className="profile-submit-btn" type="submit">Submit</button>
                            </form>
                        ) : (
                            <p className="profile-about-me-text">{userData.about_me}</p>
                        )}
                    </div>
                    <div className="profile-ladder-page-description profile-pill-container">
                        <div className="profile-about-me-header">
                            <h4>{`${userData.first_name}'s Ladders`}</h4>
                        </div>
                        <div className="profile-ladders-section">
                            {ladderData.map((ladder) => (
                                <div key={ladder.ladder_name} className="profile-ladder-pill">
                                    <Link to={`/ladder-page/${ladder.ladder_id}`} className={"profile-custom-link"}>
                                        <img
                                            src={ladder.ladder_image}
                                            alt={ladder.ladder_name}
                                            className="profile-ladder-image"

                                        />
                                        <div className="profile-ladder-details">
                                            <span className="profile-ladder-name">{ladder.ladder_name}</span>
                                            <span className="profile-ladder-rank">Rank: {ladder.rank}</span>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="ladder-page-description2">
                    <div className="profile-ladder-page-description profile-pill-container">
                        <div className="profile-about-me-header">
                            <h4>Match History</h4>
                        </div>
                        <MatchHistory user={userData} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
