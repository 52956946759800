import { isSameDay } from 'date-fns';
import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar'
import { Link } from 'react-router-dom';

type TileContentArgs = {
    date: Date;
    view: string;
};

type MyCalendarProps = {
    events: events[];
    onDayClick: (clickedDate: Date) => void;
};

type events = {
    event_id: number;
    event_name: string;
    event_activity: string;
    gametype: string;
    description: string;
    weekday: Weekdays;
    time: string;
    reoccuring: boolean;
    creation_date: string;
};

// Define a type for teh days of the week
type Weekdays = "Sunday" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday";

// Define a map of weekdays to numbers
const daysOfWeek: Record<Weekdays, number> = {
    "Sunday": 0,
    "Monday": 1,
    "Tuesday": 2,
    "Wednesday": 3,
    "Thursday": 4,
    "Friday": 5,
    "Saturday": 6
};

export const MyCalendar: React.FC<MyCalendarProps> = ({ events, onDayClick }) => {
    const value = new Date();
    const [eventNames, setEventNames] = useState<Map<string, string[]>>(new Map());
    const [selectedDayEvents, setSelectedDayEvents] = useState<events[]>([]);
    const maxDate = new Date(value.getFullYear() + 1, value.getMonth(), value.getDate());
    const maxDateReoccuring = new Date();
    maxDateReoccuring.setHours(0, 0, 0, 0);
    maxDateReoccuring.setDate(maxDateReoccuring.getDate() + 6);


    function calculateEventDates(events: events[]): Date[] {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Make 'today' granular to the day
        let eventDates: Date[] = [];

        events.forEach(event => {
            const creation_date = new Date(event.creation_date.replace(/-/g, '/')); // Need to convert "-" to "/" so that timezones don't shift dates
            let eventDate = new Date(creation_date);

            if (event.reoccuring && today >= creation_date) {
                // Calculate future dates for the recurring event
                while (eventDate <= maxDateReoccuring) {
                    if (eventDate >= today) {
                        eventDates.push(new Date(eventDate)); // Add a copy of eventDate
                    }
                    // Increment to the next occurrence
                    eventDate.setDate(eventDate.getDate() + (7 - (eventDate.getDay() - daysOfWeek[event.weekday] + 7) % 7));
                }
            } else if (!event.reoccuring && creation_date > today) {
                // For non-recurring events that are in the future
                if (eventDate >= today && eventDate <= maxDate) {
                    eventDates.push(creation_date);
                }
            }
        });
        return eventDates;
    }

    function handleDayClick(clickedDate: Date) {
        const eventsOnSelectedDay = events.filter(event => {
            const eventDate = calculateEventDates([event]);
            return eventDate.some(ed => isSameDay(ed, clickedDate));
        });
        setSelectedDayEvents(eventsOnSelectedDay);
        onDayClick(clickedDate);
    }

    function convertToUserFriendlyTime(timeStr: string): string {
        // Parse hours, minutes, and seconds from the input string
        const [hours, minutes] = timeStr.split(':').map(Number);

        // Convert to 12-hour format and determine AM/PM
        const isPM = hours >= 12;
        const convertedHours = hours % 12 || 12; // To handle 00:xx:xx and 12:xx:xx

        // Construct the user-friendly time string
        return `${convertedHours}:${minutes.toString().padStart(2, '0')} ${isPM ? 'pm' : 'am'}`;
    }

    function tileContent({ date, view }: TileContentArgs): JSX.Element | null {
        if (view === 'month') {
            const eventNamesForDate = eventNames.get(date.toDateString());
            if (eventNamesForDate) {
                return (
                    <div className="custom-content-spacing">
                        <p className="Calendar-event-dot"></p>
                    </div>
                );
            } else {
                return (
                    <div className="custom-content-spacing">
                        <p className="calendar-event-dot-blank"></p>
                    </div>
                );
            }
        }
        return null;
    }

    useEffect(() => {
        const eventDates = calculateEventDates(events);
        const namesMap = new Map();

        eventDates.forEach((date, index) => {
            const dateString = date.toDateString();
            const existingEvents = namesMap.get(dateString) || [];
            namesMap.set(dateString, [...existingEvents, eventDates[index]]);
        });
        setEventNames(namesMap);
        setSelectedDayEvents(events.filter(event => {
            const eventDate = calculateEventDates([event]);
            return eventDate.some(ed => isSameDay(ed, new Date()));
        }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events]);


    return (
        <>
            <div className="calendar-calendar-container">
                <Calendar
                    onClickDay={handleDayClick}
                    value={value}
                    calendarType="gregory"
                    // minDetail='month'
                    minDate={new Date()}
                    maxDate={maxDate}
                    tileContent={tileContent}
                />
            </div>
            <div className="calendar-day-container">
                <ul>
                    {selectedDayEvents.map((event, index) => (
                        <li key={index}>
                            <Link to={`/event-page/${event.event_id}`} className={"custom-link"}>
                                <strong>{event.event_name}</strong>
                                <div>{event.event_activity}{' '}{convertToUserFriendlyTime(event.time)}</div>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </>

    );
}

export default MyCalendar;
